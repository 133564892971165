<div class="layout-wrapper" [ngClass]="containerClass" [class]="sidebarClass">
    <div class="layout-content-wrapper">
        <app-topbar></app-topbar>
        <div class="layout-content" style="height: calc(100vh - 110px);">      
            <router-outlet></router-outlet>
        </div>
        <!-- <app-footer></app-footer> -->
    </div>
    <app-config></app-config>
    <app-search></app-search>
    <app-rightmenu></app-rightmenu>
    <p-confirmDialog key="globalErrorDialog" styleClass="red-header"></p-confirmDialog>
    <div class="layout-mask"></div>
</div>