import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  lastPrompt = new Date(2000, 0, 1);
  constructor(
    private router: Router,
    private dialog: ConfirmationService,
    private zone: NgZone
  ) {
    super();
  }

  override handleError(error: any) {
    super.handleError(error);
    if (error.rejection) {
      // extract error from promise rejection
      error = error.rejection;
    }
    if (error.name == 'TypeError') {
      this.handleTypeError(error);
      return;
    }
    error = error.error || error;
    const errmsg = error.message || error.Message || error;
    this.zone.run(() => {
      const msg = `An error has occurred, which may cause problems on the current page:<br/><br/>` + errmsg + 
      `<br/><br/>Click OK to return to the home page.`;
      this.dialog.confirm({
        key: 'globalErrorDialog',
        header: "Error",
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'OK',
        rejectLabel: 'Cancel',
        message: msg,
        accept: () => {
          this.router.navigateByUrl('/');
        },
      });
    });
  }

  /** Special handling for TypeError, which can disable Angular change detection and/or cause infinite loops */
  private handleTypeError(error: any) {
    const now = new Date();
    if ((now.getTime() - this.lastPrompt.getTime()) > 10000) {
      this.lastPrompt = now;
      const nav = window.confirm("An error has occurred, which may cause problems on the current page:\n\n" + 
        error.message + "\n\nClick OK to return to the home page.");
      this.lastPrompt = new Date();
      if (nav) {
        window.location.hash = '';
        window.location.reload();
      }
    }
  }
}