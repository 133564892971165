/* eslint-disable @angular-eslint/component-selector */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ImpersonationService, Roles } from '@core';
import { AppSidebarComponent } from './app.sidebar.component';
import { LayoutService } from './service/app.layout.service';
import { HasChangesService } from '@data';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit {

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

    constructor(public layoutService: LayoutService, private authService: AuthService, private impersonationService: ImpersonationService,
        private hasChangesService: HasChangesService, private router: Router, public el: ElementRef) { }

    async ngOnInit(): Promise<void> {
        const user = await this.authService.getLoggedInUser();
    }

    get userDisplayName(): string {
        return this.authService.userDisplayName;
    }

    get companyName() {
        return this.authService.getUser()?.companyName;
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showRightMenu();
    }

    onSearchClick() {
        this.layoutService.toggleSearchBar();
    }

    onRightMenuClick() {
        this.layoutService.showRightMenu();
    }

    get logo() {
        const logo = this.layoutService.config.menuTheme === 'white' || this.layoutService.config.menuTheme === 'orange' ? 'dark' : 'white';
        return logo;
    }

    get hasChanges() {
        return this.hasChangesService.hasChanges;
    }

    get impersonateTooltip() {
        return this.hasChanges ? 'Save changes first' : null;
    }

    changePassword() {
        this.router.navigate(['/change-password']);
    }

    goToLogin() {
        this.authService.redirectIfNotLoggedIn();
    }

    logout() {
        this.authService.logout();
    }

    canImpersonate() {
        return this.impersonationService.canImpersonateAccountUser();
    }

    impersonate() {
        if (!this.hasChanges) {
            this.impersonationService.openDialog();
        }
    }

    canStopImpersonation() {
        return !!this.authService.getUser()?.actingAsUser;
    }
    stopImpersonation() {
        if (!this.hasChanges) {
            return this.authService.stopImpersonation();
        }
    }
}
