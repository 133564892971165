import { Injectable } from "@angular/core";

/** Simple console logger, to be eventually replaced by Sentry or similar */
@Injectable({providedIn: 'root'})
export class ErrorLogger {
  log(e: any) {
    console.log(e);
  }
  error(msg: string, e?: any) {
    console.error(msg, e);
  }
}