import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { StepComponent } from './step.component';
import { StepsComponent } from './steps.component';
import { CoreModule } from '../core.module';



@NgModule({
  declarations: [StepComponent, StepsComponent],
  imports: [
    CommonModule,
    CoreModule,
    ButtonModule
  ],
  exports: [StepComponent, StepsComponent]
})
export class ProximityWizardModule { }
