// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { BudgetProductTypeTag } from './budget-product-type-tag';
import { ProgramUserGroup } from './program-user-group';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramUserGroupBudget extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** Decimal */
  budgetAmt!: number;
  /** Guid FK budgetProductTypeTag */
  budgetProductTypeTagId?: string;
  /** DateTime */
  fiscalStartDate!: Date;
  /** Guid FK programUserGroup */
  programUserGroupId!: string;
  /** Binary */
  rowVersion!: any;
  /** Decimal */
  warnIfWithinRate?: number;
  /** FK accountId */
  account!: Account;
  /** FK budgetProductTypeTagId */
  budgetProductTypeTag!: BudgetProductTypeTag;
  /** FK programUserGroupId */
  programUserGroup!: ProgramUserGroup;
}

