/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, IServerSideDatasource } from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EntityFns, UnitOfWork } from '@data';
import { ChangeLog, LogTypeEnum } from '@models';
import { AgFns, ISortModel } from '../ag-grid';
import { Entity } from 'breeze-client';

@Component({
  selector: 'prox-changelog-grid',
  template: `<div class="h-full">
  <prox-ag-grid [gridOptions]="gridOptions"></prox-ag-grid>
</div>`,
})
export class ChangeLogGridComponent implements OnInit, OnChanges  {
  @Input() uow!: UnitOfWork;
  @Input() filterModel?: object;
  @Input() entity?: Entity;
  @Input() entityId?: string;

  gridOptions: GridOptions = {};
  gridApi?: GridApi;
  colApi?: ColumnApi;
  datasource?: IServerSideDatasource;

  ngOnInit() {

    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      rowModelType: 'serverSide',
      tooltipShowDelay: 300,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gridApi) {
      this.gridApi.setFilterModel(this.filterModel);
    }
  }

  onGridReady(event: GridReadyEvent) {
    [this.gridApi, this.colApi] = AgFns.extractApis(event);
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);

    this.datasource = AgFns.buildDatasource(() => {
      let q = this.uow.createQuery(ChangeLog).expand('user');
      if (this.entity) {
        // query on id and on the entity's foreign keys
        const ors: object[] = [];
        const entityId = this.entity.entityAspect.getKey().values[0];
        ors.push({ entityId: entityId });
        ors.push({ entityId2: entityId });

        // remove fk values that are small integers, because they relate to enums
        const keys = EntityFns.getForeignKeyValues(this.entity).filter(k => k.length > 5);

        for (const k of keys) {
          ors.push({ entityId: k, entityId2: null });
        }
        q = q.where({ or: ors});
      } else if (this.entityId) {
        // query on either entityId or entityId2, to include related entities with multipart keys
        q = q.where({ or: [{ entityId: this.entityId}, { entityId2: this.entityId }]});        
      }

      return q;
    });

    if (this.gridApi) {
      this.gridApi.setServerSideDatasource(this.datasource);
    }

    this.gridApi.setFilterModel(this.filterModel);
  }
  
  getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { headerName: 'Timestamp', field: 'ts', maxWidth: 200, filter: 'agDateColumnFilter',
        valueFormatter: (p) => p.value.toISOString().replace(/[TZ]/g, ' ')
      },
      { field: 'userId', hide: true, filter: 'agTextColumnFilter',   },
      { headerName: 'User', field: 'user.username', maxWidth: 300, filter: 'agTextColumnFilter', },
      { headerName: 'Entity', field: 'entityName', maxWidth: 300, filter: 'agTextColumnFilter',   },
      { headerName: 'Type', field: 'logType', maxWidth: 200, filter: 'agSetColumnFilter', 
        valueGetter: (p) => LogTypeEnum[p.data.logType]
      },
      { headerName: 'Id', field: 'entityId', maxWidth: 200, filter: 'agTextColumnFilter',   },
      { headerName: 'Id2', field: 'entityId2', maxWidth: 200, filter: 'agTextColumnFilter',   },
      { headerName: 'Data', field: 'data', filter: 'agTextColumnFilter', tooltipValueGetter: (params) => params.data.data },
    ];
    const sortModel: ISortModel = [
      { colId: 'ts', sort: 'desc' },
    ]
    return [colDefs, sortModel] as const;
  }

  clearFilters() {
    this.gridApi?.setFilterModel(null);
  }
  
  // private formatData(params: any) {
  //   if (!params.data?.data) {
  //     return '';
  //   }
  //   const data = JSON.stringify(JSON.parse(params.data.data), null, 2);
  //   console.log(data)
  //   return data;
  // }
}
