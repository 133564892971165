import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BaseComponent } from './base.component';

@Component({
  selector: 'prox-main',
  templateUrl: './prox-main.component.html'
})
export class ProxMainComponent implements OnInit {
  @Input() parent?: BaseComponent;
  // @Input() pageIcon: string;
  // @Input() title: string;
  @Input() bodyTemplate?: TemplateRef<any>;
  @Input() footerTemplate?: TemplateRef<any>;
  @Input() showBack?: boolean;
  @Input() isDisabled?: boolean;

  ngOnInit(): void {
    // nothing yet
  }

  get hasHelp(): boolean {
    return !!this.parent?.hasMethod('showHelp');
  }

  showHelp() {
    if (this.hasHelp) {
      (this.parent as any).showHelp();
    }
  }
}
