// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';


import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
import { BudgetProductTypeTag } from './budget-product-type-tag';
import { ProgramBudget } from './program-budget';
import { ProgramUserGroupBudget } from './program-user-group-budget';
import { AccountBudget } from './account-budget';


export type IBudget = ProgramBudget | ProgramUserGroupBudget | AccountBudget

export class BudgetFns  {

  /// <code> Place custom code between <code> tags
  public static getBudgetEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      // new EntityPropertyAnnotation('budgetAmt', {
      //   displayName: 'Budget Amount',
      //   validators: [ BaseEntity.greaterThanZeroValidator() ]
      // }),
    ];

    return new EntityTypeAnnotation({
      validators: [  ],
      propertyAnnotations: propAnnotations
    });
  }
  
  
}
