// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountBlanketPurchaseOrder } from './account-blanket-purchase-order';
import { JobOrder } from './job-order';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountBlanketPurchaseOrderLog extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK accountBlanketPurchaseOrder */
  accountBlanketPurchaseOrderId!: string;
  /** Guid key FK jobOrder */
  jobOrderId!: string;
  /** Decimal */
  amt!: number;
  /** DateTimeOffset */
  logTs!: Date;
  /** FK accountBlanketPurchaseOrderId */
  accountBlanketPurchaseOrder!: AccountBlanketPurchaseOrder;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
}

