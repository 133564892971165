import { Injectable } from "@angular/core";
import { AccountUnitOfWork } from "@data";
import { ProximityPref } from "@models";
import { AuthService } from "./auth.service";

export interface UserAppConfig {
  inputStyle: string;
  colorScheme: string;
  theme: string;
  ripple: boolean;
  menuMode: string;
  scale: number;
  menuTheme: string;
}

@Injectable({ providedIn: 'root' })
export class UserPrefsService {
  private configString?: string;
  private config?: UserAppConfig;
  private pref?: ProximityPref;
  gridTheme = "ag-theme-balham";

  constructor(private uow: AccountUnitOfWork, private authService: AuthService) {
  }

  setConfig(config: UserAppConfig) {
    this.setGridTheme(config);
    const json = JSON.stringify(config);
    if (json !== this.configString) {
      this.config = config;
      this.configString = json;

      if (this.pref && this.authService.getUser()) {
        this.pref.prefsJson = this.configString;
      } else {
        this.pref = this.uow.createEntity(ProximityPref, {
          proximityUserId: this.authService.getUser()?.id,
          prefsJson: this.configString
        });
      }
      return this.uow.commit();
    }
    return undefined;
  }

  async getConfig() {
    if (!this.config && this.authService.getUser()) {
      const proximityUserId = this.authService.getUser()?.id;
      const prefs = await this.uow.createQuery(ProximityPref).where({ proximityUserId }).execute();
      if (prefs.length) {
        this.pref = prefs[0];
        const s = prefs[0].prefsJson;
        if (s) {
          this.configString = s;
          this.config = JSON.parse(s) as UserAppConfig;
          this.setGridTheme(this.config);
        }
      }
    }
    return this.config;
  }

  setGridTheme(config: UserAppConfig) {
    let theme = (config.scale > 14) ? "ag-theme-alpine" : "ag-theme-balham";
    if (config.colorScheme == "dim" || config.colorScheme == "dark") {
      theme += "-dark";
    }
    this.gridTheme = theme;
  }

}
