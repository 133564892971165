// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountAdmin } from './account-admin';
import { AccountAdminGroup } from './account-admin-group';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountAdminGroupMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK accountAdminGroup */
  accountAdminGroupId!: string;
  /** Guid key FK accountAdmin */
  accountAdminId!: string;
  /** FK accountAdminId */
  accountAdmin!: AccountAdmin;
  /** FK accountAdminGroupId */
  accountAdminGroup!: AccountAdminGroup;
}

