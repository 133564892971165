import { RRule } from "rrule";

export class RRuleFns {
  static jsWeekDays = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];
  static jsWeekDayAbr = [ 'SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
  static weekpos = ['Last', 'First', 'Second', 'Third' , 'Fourth'];

  static weekInMonthList = [
    { name: 'First', value: 1 },
    { name: 'Second', value: 2 },
    { name: 'Third', value: 3 },
    { name: 'Fourth', value: 4 },
    { name: 'Last', value: -1 },
  ] 

  static fromString(rruleString: string) {
    if (rruleString == null || rruleString.length == 0) {
      return null;
    }
    return RRule.fromString(rruleString);
  }

  static toText(rrule: RRule | null) {
    if (rrule == null) {
      return '';
    }
    const options = rrule.origOptions;
    if (options.bysetpos == null) {
      return rrule.toText();
    } else {
      const weekpos = this.weekpos[options.bysetpos as number || 0];
      const weekday = options?.byweekday?.[0] != null ? this.jsWeekDays[options.byweekday[0].weekday + 1] : 'Unknown';
      return `every ${options.interval} months on the ${ weekpos} ${ weekday } of the month`;
    }
  }
}