// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ActiveStatus } from './active-status';
import { Product } from './product';
import { ProductTypeConfig } from './product-type-config';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { PricedProductTypeFeatureChoice } from './priced-product-type-feature-choice';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProductTypeConfigProduct extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  pricedProductTypeFeatureChoices!: PricedProductTypeFeatureChoice[];

  getStatusParents() {
    return [this.productTypeConfig];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** Guid FK product */
  productId!: string;
  /** Guid FK productTypeConfig */
  productTypeConfigId!: string;
  /** Binary */
  rowVersion!: any;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK productId */
  product!: Product;
  /** FK productTypeConfigId */
  productTypeConfig!: ProductTypeConfig;
}

