// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { BudgetProductTypeTag } from './budget-product-type-tag';
import { PricedProductType } from './priced-product-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class BudgetProductTypeTagMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK pricedProductType */
  pricedProductTypeId!: string;
  /** Guid key FK budgetProductTypeTag */
  budgetProductTypeTagId!: string;
  /** FK budgetProductTypeTagId */
  budgetProductTypeTag!: BudgetProductTypeTag;
  /** FK pricedProductTypeId */
  pricedProductType!: PricedProductType;
}

