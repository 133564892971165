<div class="card-body" *ngIf="isPageReady">

  <div class="flex">
    <div class="w-2 pb-3 pr-3">
      <prox-field label="Salutation">
        <input type="text" pInputText autocomplete="off" pAutoFocus [autofocus]="true"
          [(ngModel)]="proximityUser!.salutation" [bzModel]="proximityUser!" [disabled]="isReadOnly"
          name="salutation" />
      </prox-field>
    </div>
    <div class="w-4 pb-3 pr-3">
      <prox-field label="First Name">
        <input type="text" pInputText autocomplete="off" [autofocus]="true" [(ngModel)]="proximityUser!.firstName"
          [bzModel]="proximityUser!" [disabled]="isReadOnly" name="firstName" />
      </prox-field>
    </div>
    <div class="w-2 pb-3 pr-3">
      <prox-field label="Middle Init/Name">
        <input type="text" pInputText autocomplete="off" [autofocus]="true" [(ngModel)]="proximityUser!.middleName"
          [bzModel]="proximityUser!" [disabled]="isReadOnly" name="middleName" />
      </prox-field>
    </div>
    <div class="w-4 pb-3 pr-3">
      <prox-field label="Last Name">
        <input type="text" pInputText autocomplete="off" [autofocus]="true" [(ngModel)]="proximityUser!.lastName"
          [bzModel]="proximityUser!" [disabled]="isReadOnly" name="lastName" />
      </prox-field>
    </div>
  </div>
  <div class="flex">
    <div class="w-4 pb-3 pr-3">
      <prox-field label="Title">
        <input type="text" pInputText [autofocus]="true" [(ngModel)]="proximityUser!.title" [bzModel]="proximityUser!"
        [disabled]="isReadOnly" name="title" />
      </prox-field>
    </div>
    <div *ngIf="genders && genderModel" class="w-2 pb-3 pr-3">
      <prox-field label="Gender">
        <p-dropdown [options]="genders" [(ngModel)]="genderModel.genderId" optionLabel="name" optionValue="id"
        [disabled]="isReadOnly" name="genderId"></p-dropdown>
      </prox-field>
    </div>
  </div>
  <!--     <p-divider type="solid" align="left">
      <div>
        <i class="fa-regular fa-comment"></i><b> Communication </b>
      </div>
    </p-divider> -->
  <div class="flex">
    <div class="w-5 pb-3 pr-3">
      <prox-field label="E-Mail">
        <input type="text" pInputText [(ngModel)]="proximityUser!.email" [bzModel]="proximityUser!" [disabled]="isReadOnly" name="email" />
      </prox-field>
    </div>
    <div class="w-5 pb-3 pr-3">
      <prox-field label="Login Name">
        <input type="text" pInputText [(ngModel)]="proximityUser!.username" [bzModel]="proximityUser!" [disabled]="isReadOnly" name="username"
          [disabled]="!isBeingAdded" />
      </prox-field>
    </div>
  </div>
  <div class="flex">
    <div class="w-3 pb-3 pr-3">
      <prox-field label="Telephone">
        <input type="text" pInputText autocomplete="off" autocomplete="off" [(ngModel)]="proximityUser!.telephoneNumber"
          [bzModel]="proximityUser!" [disabled]="isReadOnly" name="telephoneNumber" />
      </prox-field>
    </div>
    <div class="w-2 pb-3 pr-3">
      <prox-field label="Ext.">
        <input type="text" pInputText autocomplete="off" [(ngModel)]="proximityUser!.extension"
          [bzModel]="proximityUser!" [disabled]="isReadOnly" name="extension" />
      </prox-field>
    </div>
    <div class="w-3 pb-3 pr-3">
      <prox-field label="Mobile">
        <input type="text" pInputText autocomplete="off" [(ngModel)]="proximityUser!.cellNumber"
          [bzModel]="proximityUser!" [disabled]="isReadOnly" name="cellNumber" />
      </prox-field>
    </div>
    <div class="flex align-items-center gap-1">
      <prox-field>
        <p-checkbox [(ngModel)]="proximityUser!.acceptSms" [binary]="true" [disabled]="isReadOnly" name="acceptSms"
          label="Accept text messages?"></p-checkbox>
      </prox-field>
    </div>
  </div>
</div>