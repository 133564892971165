const BaseListEditFrmHtml = `
<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>
<ng-template #bodyTemplate>

  <div *ngIf="isPageReady && entities" class="h-full">

    <div class="flex flex-column h-full">
      <div class="flex ml-2 mt-2 mb-3" >
        <button  (click)="onAdd()" pButton type="button" label="Add {{ entityTypeDisplayName}}" icon="pi pi-plus"
          class="p-button-sm" [disabled]="isAddDisabled()"></button>
        <span style="margin-left:10px; padding-top: 12px; font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>     
        <div class="flex-grow-1"></div>
        <button *ngIf="hasHelp" pButton (click)="showHelp()" class="p-button-rounded p-button-text" icon="pi pi-question-circle"></button>
      </div>

      <div class="flex-grow-1 h-full">
          <prox-ag-grid [gridOptions]="gridOptions"  [rowData]="entities">
          </prox-ag-grid>
      </div>
      <div *ngIf="showSaveBar">
        <prox-save-cancel class="mt-2" [uow]="this" (undo)="onUndo()" (save)="onSave()" (cancel)="onCancel()" [isReadOnly]="isAddDisabled()"></prox-save-cancel>
      </div>  
    </div>

  </div>
</ng-template>  
` ;
export default BaseListEditFrmHtml;