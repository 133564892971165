import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";


@Injectable({
    providedIn: 'root'
})
    export class NavigationService {

    private backClicked = false;

    setBackClicked(clicked: boolean) {
        this.backClicked = clicked;
    }

    getBackClicked() {
        return this.backClicked;
    }

    getAllRouteParams(router: Router): {[key: string]: string}  {
        let params : { [key: string]: string} = {};
        const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
        while (stack.length > 0) {
          const route = stack.pop()!;
          params = {...params, ...route.params};
          stack.push(...route.children);
        }
        return params;
    }
}