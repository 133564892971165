import { Injectable } from '@angular/core';
import { environment } from '@env';
import {
  DataProperty,
  DataService,
  DataType,
  Entity,
  EntityManager,
  EntityType,
  MetadataStore,
  NamingConvention,
  StructuralType,
  Validator,
  config,
} from 'breeze-client';
import { camelCase, startCase } from 'lodash-es';
import { ProximityMetadata } from '../models/entities/metadata';
import { ProximityRegistrationHelper } from '../models/entities/registration-helper';
import { EntityTypeAnnotation } from '../models/entity-type-annotations';
import { HelpItem } from '@models';

// export interface Domain {
//   apiUrl: string;
// }
// export class AdaptDomain implements Domain {
//   apiUrl: string = environment.adaptUrl;
// }
// export class AdminDomain implements Domain {
//   apiUrl: string = environment.adminUrl;
// }

@Injectable({ providedIn: 'root' })
export class MetadataStoreProvider {
  metadataStore: MetadataStore;
  metadata = ProximityMetadata;
  registrationHelper = ProximityRegistrationHelper;

  static EmptyGuid = "00000000-0000-0000-0000-000000000000";

  constructor() {
    NamingConvention.camelCase.setAsDefault();
    config.initializeAdapterInstance('uriBuilder', 'json');
    // See this link: https://stackoverflow.com/questions/17657459/breezejs-date-is-not-set-to-the-right-time
    const zeroTimeRegex = /.{10}T00:00:00/;
    DataType.parseDateFromServer = function (source: string) {
      // zero-time dates should be considered local time, even if they have a timezone specified
      if (zeroTimeRegex.test(source)) {
        source = source.substring(0, 19);
      }
      // remove time zone from all dates so they display locally.
      // source = (source && source.length > 23) ? source.substring(0, 23) : source;
      const date = new Date(Date.parse(source));
      return date;
    };

    // Validator.required = MetadataStoreProvider.strictRequiredValidator;

    this.metadataStore = new MetadataStore();
    this.metadataStore.importMetadata(<any>this.metadata.value);
    this.metadataStore.setProperties({
      serializerFn: function (dataProperty, value) {
        return dataProperty.isUnmapped ? undefined : value;
      },
      // serializerFn: function (dataProperty: { isUnmapped: any; }, value: string | Date) {
      //   value = dataProperty.isUnmapped ? undefined : value;
      //   if (value instanceof Date) {
      //     const date = value as Date;
      //     // make ISO string for current time zone (not UTC)
      //     value = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
      //     // for use if dates are DateTimeOffsets, but should not be given the browser timezone nor UTC
      //     value = value.substring(0, 23); // chop off the timezone
      //   }
      //   return value;
      // } 
    });
    this.registrationHelper.register(this.metadataStore);
    this.patchMetadata(this.metadataStore);
    this.registerAnnotations(this.metadataStore);
  }

  // Add validators based on annotations
  private registerAnnotations(metadataStore: MetadataStore) {
    metadataStore.getEntityTypes().forEach((t: StructuralType) => {
      const et = <EntityType>t;
      const ctor = <any>et.getCtor();
      // default handling of property display names.
      const props = et.getProperties();
      props.forEach((p) => {
        p.displayName = startCase(camelCase(p.name));
        p.validators.slice().forEach((v,ix) => {
          if (v.name == 'required') {
            p.validators[ix] = MetadataStoreProvider.strictRequiredValidator;
          }
        })
      });
      if (ctor && ctor.getEntityTypeAnnotation) {
        const etAnnotation = <EntityTypeAnnotation>(
          ctor.getEntityTypeAnnotation()
        );
        et.validators.push(...etAnnotation.validators);
        etAnnotation.propertyAnnotations.forEach((pa) => {
          const prop = et.getProperty(pa.propertyName);
          prop.validators.push(...pa.validators);
          if (pa.displayName != null) {
            prop.displayName = pa.displayName;
          }
        });
      }

    });
  }

  static strictRequiredValidator = new Validator(
    'required',
    (value: any, context: any) => {
      if (value == null) {
        return false;
      }
      if (typeof value === 'string') {
        if (value.trim().length == 0) {
          return false;
        }
        if (value === MetadataStoreProvider.EmptyGuid ) {
          return false;
        }
      }
      return true;
    },
    { messageTemplate: '%displayName% must not be empty.' }
  );

  protected patchMetadata(metadataStore: MetadataStore) {
    // make modifications to metadata
    this.addDataProperty(metadataStore, 'HelpItem', 'mergedHtml', DataType.String);
  }

  private addDataProperty(metadataStore: MetadataStore, source: string, propName: string, dataType: DataType) {
    const entityType = metadataStore.getAsEntityType(source);
    const dp = new DataProperty({
      dataType: dataType,
      isNullable: true,
      name: propName,
    });
    entityType.addProperty(dp);
  }


}
