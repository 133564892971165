// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProgramIssuance } from './program-issuance';
import { ProgramUserGroup } from './program-user-group';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramIssuanceUserGroupMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK programIssuance */
  programIssuanceId!: string;
  /** Guid key FK programUserGroup */
  programUserGroupId!: string;
  /** FK programIssuanceId */
  programIssuance!: ProgramIssuance;
  /** FK programUserGroupId */
  programUserGroup!: ProgramUserGroup;
}

