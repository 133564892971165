import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';


export interface IconProps {
  label: string;
  icon?: string;
  getIcon?: (item: any) => string;
  canDisplay?: (item: any) => boolean;
  rowDrag?: boolean;
}

@Component({
  selector: 'prox-icon-renderer',
  template: `
    <a *ngIf="canDisplayCore(params.node.data)" [class]="params.getIcon(params.node.data)" (click)="onClick($event)" #elref>{{params.label}}</a>
    `
})
export class IconRendererComponent implements ICellRendererAngularComp, AfterViewInit {
  @ViewChild('elref') elref!: ElementRef;
  params;
  styleClass!: string;

  defaultParams = {
    label: '',
    icon: '',
    getIcon: () => this.params.icon,
    canDisplay: () => true,
  }

  agInit(params: object): void {
    this.params = { ...this.defaultParams, ...params };
  }

  ngAfterViewInit() {
    if (this.params.rowDrag && this.elref?.nativeElement) {
      this.params.registerRowDragger(this.elref.nativeElement);
    }
  }

  refresh(params?: object): boolean {
    return true;
  }

  canDisplayCore(item: any) {
    if (this.params.canDisplay) {
      return this.params.canDisplay(item);
    } else {
      return true;
    }
  }

  onClick($event) {
    
    if (this.params.onClick instanceof Function) {
          const data = this.params.node.data;
      
      // pass the actual row being clicked, all the params and the original event ( so that it 'stopPropogation' can be called on it)
      this.params.onClick(data, this.params, $event);

    }
  }
}