import { ProximityUser } from '@models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ColDef } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { UtilFns } from '@utils';
import { Entity } from 'breeze-client';
import { ISortModel } from '../ag-grid';
import { EntityFinderDialog, EntityFinderParams } from '../base/entity-finder.dialog';

interface CoreUser extends Entity {
  proximityUser: ProximityUser;
  proximityUserId: string;
  id: string;
}
export interface CoreUserFinderParams extends EntityFinderParams {
  users: CoreUser[];
  title?: string;
}

@Component({
  selector: 'prox-user-finder',
  template: EntityFinderDialog.HTML,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CoreUserFinderDialog extends EntityFinderDialog<CoreUser, CoreUserFinderParams> {

  params: CoreUserFinderParams;
  users: CoreUser[] = [];

  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig<CoreUserFinderParams>,
  ) {
    super(ref, config);
    UtilFns.assert(config.data);
    this.params = config.data;
    UtilFns.assertNonNull(this.params.users, 'users');
  }

  override async initialize() {
    
    return {
      header: this.params.title || 'Select an Account User',
      width: '700px',
      rowModelType: 'clientSide'
    } as const;
  }

  override async initDatasource() {
    return this.params.users;
  }

  override getColDefsAndSortModel() {
    const colDefs = [
      { headerName: 'Last Name', field: 'proximityUser.lastName', filter: 'agTextColumnFilter', checkboxSelection: this.initParams.rowSelection == 'multiple' },
      { headerName: 'First Name', field: 'proximityUser.firstName', filter: 'agTextColumnFilter',  },
      { headerName: 'Middle Name', field: 'proximityUser.middleName', filter: 'agTextColumnFilter',  },
    ] as ColDef[]
    const sortModel = [
      { colId: 'proximityUser.lastName', sort: 'asc' },
      { colId: 'proximityUser.firstName', sort: 'asc' },
    ] as ISortModel
    return [ colDefs, sortModel] as const;
  }
}
