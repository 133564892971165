export class ImportUser {
  Id = '';
  Error = '';
  Status = '';
  LastName = '';
  FirstName = '';
  MiddleName = '';
  Email = '';
  Salutation = '';
  Gender = '';
  ApiLink? = '';
  NewHireEndDate? = 0;
  UserName? = "";
  ShippingUserGroupCode? = "";
  ShippingUserGroupId? = "";
  ShippingUserGroupName? = "";
  ProgramUserGroupCode? = "";
  ProgramUserGroupId? = "";
  ProgramUserGroupName? = "";
  Result = "";
}