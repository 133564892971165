export class StringFns {
  static isNullEmptyOrWhiteSpace(s: string | null) {
    return s === null || s === undefined || s.match(/^ *$/) !== null;
  }

  static tagsrx = /<.*?>/g;

  /** Remove HTML tags from the string */
  static stripHtmlTags(s: string) {
    if (!s) { return s; }
    // TODO replace <br> with \n, &lt; with <, etc.
    return s.replace(this.tagsrx, '');
  }
}