<div class="h-full flex flex-column">

  <p-galleria [value]="images" [numVisible]="5" [(activeIndex)]="activeIndex" *ngIf="showGallery">
    <ng-template pTemplate="item" let-item>
      <img [src]="item.safeUrl" style="height: 600px; width: 858px; object-fit: scale-down" />
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <div class="relative">
        <img [src]="item.safeUrl" style="height: 75px; width: 100px; object-fit: scale-down" />
        <div class="absolute top-0 flex">
          <div *ngIf="item.isPrimary" class="prx-mini-icon bg-primary" title="Primary">
            <i class="pi pi-check"></i>
          </div>
          <div *ngIf="item.isThumbnail" class="prx-mini-icon bg-green-500" title="Thumbnail">
            <i class="pi pi-thumbs-up"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="caption" let-item>
      <div class="flex">
        <div class="flex-grow-1">{{ item.fileName }}</div>
        <div class="-mt-2">
          <span class="text-500 mr-3 mb-2 inline-block">{{item.width}} x {{item.height}}</span>
          <p-checkbox [(ngModel)]="item.isPrimary" [binary]="true" label="Primary" (onChange)="primaryChange(item)" class="mr-2 mb-2"></p-checkbox>
          <button pButton [disabled]="!canMakeThumbnail(item)" (click)="makeThumbnail(item)" type="button" icon="pi pi-thumbs-up" class="p-button-rounded mr-2" label="Make Thumbnail"></button>
          <button [disabled]="item.isThumbnail" pButton (click)="edit(item)" type="button" icon="pi pi-pencil" class="p-button-rounded mr-2" title="Edit"></button>
          <button *ngIf="data.onDelete" pButton (click)="delete(item)" type="button" icon="pi pi-trash" class="p-button-rounded mr-2" title="Delete"></button>
          <a pButton [href]="item.safeUrl" target="_self" [download]="item.fileName" icon="pi pi-download" class="p-button-rounded" title="Download"></a>
        </div>
      </div>
    </ng-template>
  </p-galleria>

  <prox-image-edit *ngIf="showEdit" [cropInput]="cropInput" (cancel)="cropCancel()" (ok)="cropOk($event)"></prox-image-edit>
  
</div>