// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { JobOrderDetail } from './job-order-detail';
import { PricedAddon } from './priced-addon';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrderDetailAddon extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String */
  additionalInfo?: string;
  /** Guid FK jobOrderDetail */
  jobOrderDetailId!: string;
  /** Guid FK pricedAddon */
  pricedAddonId!: string;
  /** Binary */
  rowVersion!: any;
  /** Boolean */
  wasOptional!: boolean;
  /** FK jobOrderDetailId */
  jobOrderDetail!: JobOrderDetail;
  /** FK pricedAddonId */
  pricedAddon!: PricedAddon;
}

