// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { JobOrder } from './job-order';
import { JobOrderStatus } from './job-order-status';
import { ProximityUser } from './proximity-user';
import { JobOrderStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrderNote extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** Enum FK jobOrderStatus */
  jobOrderStatusId!: JobOrderStatusEnum;
  /** String */
  note!: string;
  /** DateTimeOffset */
  noteTs!: Date;
  /** Guid FK proximityUser */
  proximityUserId?: string;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
  /** FK jobOrderStatusId */
  jobOrderStatus!: JobOrderStatus;
  /** FK proximityUserId */
  proximityUser!: ProximityUser;
}

