// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountIssuance } from './account-issuance';
import { Program } from './program';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramAccountIssuanceMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK program */
  programId!: string;
  /** Guid key FK accountIssuance */
  accountIssuanceId!: string;
  /** FK accountIssuanceId */
  accountIssuance!: AccountIssuance;
  /** FK programId */
  program!: Program;
}

