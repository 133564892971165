// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { NotificationEvent } from './notification-event';
import { NotificationType } from './notification-type';
import { NotificationEventEnum } from './enums';
import { NotificationTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class NotificationTemplate extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId?: string;
  /** Enum FK notificationEvent */
  notificationEventId!: NotificationEventEnum;
  /** Enum FK notificationType */
  notificationTypeId!: NotificationTypeEnum;
  /** Binary */
  rowVersion!: any;
  /** String */
  template!: string;
  /** FK accountId */
  account!: Account;
  /** FK notificationEventId */
  notificationEvent!: NotificationEvent;
  /** FK notificationTypeId */
  notificationType!: NotificationType;
}

