import { UnitOfWork } from "@data";
import { ProductTypeConfig } from "@models";


export class SharedQueries {

  static createProductTypeConfigsQuery(uow: UnitOfWork, accountId: string, supplierId: string | null, shouldIncludePricing: boolean = false) {
    const whereClause: any = {
      'pricedProductType.accountId': accountId,
    }
    const expandClause = [
      'productTypeConfigProducts.product',
      'pricedProductType.productType.manufacturer',
      'pricedProductType.productType.productTypeFeatures.feature.featureChoices'
    ];
    if (supplierId) {
      whereClause['pricedProductType.productType.supplierId'] = supplierId;
    } else {
      expandClause.push('pricedProductType.productType.supplier')
    }
    
    if (shouldIncludePricing) {
      expandClause.push( ... [
        'pricedProductType.pricedProductTypeFeatureChoices',
        'productTypeConfigAddons.pricedAddon.addon',
      ])
    }
    const q = uow.createQuery(ProductTypeConfig)
      .where(whereClause)
      .expand(expandClause)
    return q;
  }

  
}