// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountBlanketPurchaseOrderLog } from './account-blanket-purchase-order-log';
import { ActiveStatus } from './active-status';
import { Program } from './program';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
import { Validator } from 'breeze-client';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountBlanketPurchaseOrder extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static dateAfterValidator = new Validator(
    'endDateAfterStartDate',
    (value: any, context) => {
      if (value == null) {
        return true;
      }
      const startDate = context?.entity?.['startDate'];
      if (startDate == null) {
        return true;
      }
      if (startDate >= value) {
        return false;
      }
      return true;
    },
    { messageTemplate: '%displayName% must be greater than Start Date' }
  );

  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    
    
    const propAnnotations = [

      new EntityPropertyAnnotation('startDate', {
        validators: [ BaseEntity.dateRangeValidator(new Date(2020, 0, 1), null) ]
      }),
      // TODO: add validation that this is after the start date.
      new EntityPropertyAnnotation('endDate', {
        validators: [ BaseEntity.dateRangeValidator(new Date(2020, 0, 1), null), this.dateAfterValidator ]
      }),
      new EntityPropertyAnnotation('amt', {
        displayName: 'Amount',
        validators: [ BaseEntity.greaterThanZeroValidator(true) ]
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [  ],
      propertyAnnotations: propAnnotations
    });
    
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** Decimal */
  amt!: number;
  /** DateTime */
  endDate!: Date;
  /** String */
  purchaseOrder!: string;
  /** Binary */
  rowVersion!: any;
  /** DateTime */
  startDate!: Date;
  /** FK accountId */
  account!: Account;
  /** Inv FK accountBlanketPurchaseOrderId */
  accountBlanketPurchaseOrderLogs!: AccountBlanketPurchaseOrderLog[];
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK accountBlanketPurchaseOrderId */
  programs!: Program[];
}

