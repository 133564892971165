// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { InvoiceDetail } from './invoice-detail';
import { JobOrder } from './job-order';
import { JobOrderHistDetailAddon } from './job-order-hist-detail-addon';
import { JobOrderHistDetailFeature } from './job-order-hist-detail-feature';
import { JobOrderHistDetailReceipt } from './job-order-hist-detail-receipt';
import { Product } from './product';
import { ReturnPolicy } from './return-policy';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrderHistDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getShippedQty() {
    var _shipped = 0;
    this.invoiceDetails.forEach((c) => {
      _shipped += c.shipQty + c.redistributeQty;
    });
    return _shipped;
  }

  getCanceledQty() {
    return 0;
  }

  getReturnedQty() {
    var _returned = 0;
    this.invoiceDetails.forEach((p) => {
      p.returnRequestDetails.forEach((c) => {
        _returned += c.qty;
      });
    });
    return _returned;
  }

  // JM 9/12/24 - Open does not include returned into calculation
  getOpenQty() {
    return (
      this.qty -
      this.getShippedQty() -
      this.getCanceledQty()
    );
  }

  getUnitPrice() {
    return this.price + this.getFeaturesPrice() + this.getAddonsPrice();
  }

  getAddonsPrice() {
    return _.sumBy(this.jobOrderHistDetailAddons, (x) => x.price);
  }

  getFeaturesPrice() {
    return _.sumBy(this.jobOrderHistDetailFeatures, (x) => x.price);
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** Decimal */
  price!: number;
  /** Guid FK product */
  productId!: string;
  /** Int32 */
  qty!: number;
  /** Guid FK returnPolicy */
  returnPolicyId?: string;
  /** Binary */
  rowVersion!: any;
  /** Inv FK jobOrderHistDetailId */
  invoiceDetails!: InvoiceDetail[];
  /** FK jobOrderId */
  jobOrder!: JobOrder;
  /** Inv FK jobOrderHistDetailId */
  jobOrderHistDetailAddons!: JobOrderHistDetailAddon[];
  /** Inv FK jobOrderHistDetailId */
  jobOrderHistDetailFeatures!: JobOrderHistDetailFeature[];
  /** Inv FK jobOrderHistDetailId */
  jobOrderHistDetailReceipts!: JobOrderHistDetailReceipt[];
  /** FK productId */
  product!: Product;
  /** FK returnPolicyId */
  returnPolicy!: ReturnPolicy;
}

