import { Injectable } from "@angular/core";
import { MessageBusService, UserPrefsService } from "@core";
import { MenuService } from "../app.menu.service";
import { AppConfig, LayoutService } from "../service/app.layout.service";

@Injectable({ providedIn: 'root' })
export class AppConfigService {

  constructor(private layoutService: LayoutService, private menuService: MenuService, private userPrefsService: UserPrefsService,
    messageBus: MessageBusService) {
    messageBus.messages.subscribe(m => {
      if (m.message == 'login') {
        this.loadConfig();
      }
    });
  }

  async loadConfig() {
    const config = await this.userPrefsService.getConfig();
    if (config) {
      this.applyConfig(config);
    }
  }

  applyConfig(config: AppConfig) {
    this.changeColorScheme(config.colorScheme, config.theme);
    this.inputStyle = config.inputStyle;
    this.menuMode = config.menuMode;
    this.menuTheme = config.menuTheme;
    this.ripple = config.ripple;
    this.scale = config.scale;
    this.applyScale();
    this.layoutService.onConfigUpdate();
  }

  saveConfig() {
    this.userPrefsService.setConfig(this.layoutService.config);
  }

  get scale(): number {
    return this.layoutService.config.scale;
  }

  set scale(_val: number) {
    this.layoutService.config.scale = _val;
  }

  get menuMode(): string {
    return this.layoutService.config.menuMode;
  }

  set menuMode(_val: string) {
    this.layoutService.config.menuMode = _val;
    if (this.layoutService.isSlim() || this.layoutService.isHorizontal() || this.layoutService.isCompact()) {
      this.menuService.reset();
    }
  }

  get colorScheme(): string {
    return this.layoutService.config.colorScheme;
  }

  set colorScheme(_val: string) {
    this.changeColorScheme(_val);
  }

  get inputStyle(): string {
    return this.layoutService.config.inputStyle;
  }

  set inputStyle(_val: string) {
    this.layoutService.config.inputStyle = _val;
  }

  get ripple(): boolean {
    return this.layoutService.config.ripple;
  }

  set ripple(_val: boolean) {
    this.layoutService.config.ripple = _val;
  }

  get menuTheme(): string {
    return this.layoutService.config.menuTheme;
  }

  set menuTheme(_val: string) {
    this.layoutService.config.menuTheme = _val;
  }

  decrementScale() {
    this.scale--;
    this.applyScale();
  }

  incrementScale() {
    this.scale++;
    this.applyScale();
  }

  private applyScale() {
    document.documentElement.style.fontSize = this.scale + 'px';
    this.userPrefsService.setGridTheme(this.layoutService.config);
  }

  changeMenuTheme(theme: any) {
    this.layoutService.config.menuTheme = theme.name;
    this.layoutService.onConfigUpdate();
  }

  changeComponentTheme(theme: string) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const newHref = themeLink.getAttribute('href')!.replace(this.layoutService.config.theme, theme);

    this.replaceThemeLink(newHref, 'theme-link', () => {
      this.layoutService.config.theme = theme;
      this.layoutService.onConfigUpdate();
    });
  }

  private changeColorScheme(colorScheme: string, componentTheme?: string) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const themeLinkHref = themeLink.getAttribute('href');
    const currentColorScheme = 'theme-' + this.layoutService.config.colorScheme;
    const newColorScheme = 'theme-' + colorScheme;
    let newHref = themeLinkHref!.replace(currentColorScheme, newColorScheme);

    if (componentTheme) {
      newHref = newHref.replace(this.layoutService.config.theme, componentTheme);
    }

    this.replaceThemeLink(newHref, 'theme-link', () => {
      this.layoutService.config.colorScheme = colorScheme;
      if (componentTheme) {
        this.layoutService.config.theme = componentTheme;
      }
      this.userPrefsService.setGridTheme(this.layoutService.config);
      this.layoutService.onConfigUpdate();
    });
  }

  private replaceThemeLink(href: string, targetId: string, onComplete?: Function) {
    const id = targetId;
    const targetLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>targetLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    targetLink.parentNode!.insertBefore(cloneLinkElement, targetLink.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      targetLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete && onComplete();
    });
  }

}
