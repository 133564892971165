/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';


export interface AgButtonProps {
  calcLabel?: (item: any) => string;
  label?: string;
  icon?: string;
  style?: 'text' | 'button';
  canDisplay?: (item: any) => boolean;
  calcDisabled?: (item: any) => boolean;
  tooltip?: string;
}

@Component({
  selector: 'prox-button-renderer',
  template: ` 
    <p-button [link]='true' *ngIf="canDisplayCore(params.node.data)" size="small" [pTooltip]="params.tooltip" [disabled]="params.calcDisabled(params.node.data)" [styleClass]="styleClass" (click)="onClick($event)" [icon]="params.icon" >{{ getLabel(params.node.data)}}</p-button>
    `
})
export class ButtonRendererComponent implements ICellRendererAngularComp {

  params;
  styleClass!: string;

  defaultParams = {
    label: '',
    icon: '',
    style: 'text',
    canDisplay: () => true ,
    calcDisabled: () => false,
    tooltip: ''
  }

  styleClassButton="p-button-sm  p-button-rounded";
  styleClassText="p-button-sm  p-button-rounded p-button-text";

  agInit(params: object): void {
    this.params = { ...this.defaultParams, ...params };
    
    this.styleClass = this.params.style == 'text' ? this.styleClassText : this.styleClassButton;
  }

  refresh(params?: object): boolean {
    return true;
  }

  canDisplayCore(item: any) {
    if (this.params.canDisplay) {
      return this.params.canDisplay(item);
    } else {
      return true;
    }
  }

  getLabel(item: any) {
    if (this.params.calcLabel) {
      return this.params.calcLabel(item);
    } else {
      return this.params.label;
    }
  }

  onClick($event) {
    
    if (this.params.onClick instanceof Function) {
          const data = this.params.node.data;
      
      // pass the actual row being clicked, all the params and the original event ( so that it 'stopPropogation' can be called on it)
      this.params.onClick(data, this.params, $event);

    }
  }
}