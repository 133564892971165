<div class="flex h-screen">
  <div class="w-full lg:w-4 h-full text-center px-6 py-6 flex flex-column justify-content-between">
      <a routerLink="/"><img [src]="'assets/layout/images/logo-dark.svg'" class="h-4rem mt-4" alt="diamond-layout" /></a>

      <form name="loginForm" #form="ngForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-column align-items-center gap-4">
            <div class="mb-3">
                <h2>Change your password</h2>
                <p>Forgot password? <a class="text-primary hover:underline cursor-pointer font-medium">Click here</a> to reset.</p>
            </div>
            <p-password [(ngModel)]="oldpassword" [toggleMask]="true" required inputStyleClass="w-20rem" name="oldpassword" placeholder="Old password" [feedback]="false"></p-password>
            <p-password [(ngModel)]="newpassword" [toggleMask]="true" required inputStyleClass="w-20rem" name="newpassword" placeholder="New password"></p-password>
            <p-password [(ngModel)]="conpassword" [toggleMask]="true" required inputStyleClass="w-20rem" name="conpassword" placeholder="Confirm password" [feedback]="false"></p-password>

            <div class="flex flex-wrap gap-2 w-20rem justify-content-between">
                <button pButton pRipple label="CANCEL" class="flex-auto p-button-outlined" [routerLink]="['/']"></button>
                <button pButton pRipple label="SUBMIT" type="submit" class="flex-auto" [disabled]="form.invalid || (conpassword !== newpassword)" [loading]="working"></button>
            </div>

            <p class="text-red-500">{{error}}</p>
            <p class="text-green-500">{{success}}</p>
        </div>
      </form>

      <p class="text-color-secondary font-semibold">A problem? <a class="text-primary hover:underline cursor-pointer font-medium">Click here</a> and let us help you.</p>
  </div>
  <div class="w-8 hidden lg:flex flex-column justify-content-between align-items-center px-6 py-6 bg-cover bg-norepeat" style="background-image: url('assets/layout/images/bg-login.jpg')">
      <div class="mt-auto mb-auto">
          <span class="block text-white text-7xl font-semibold">Change your password</span>
          <span class="block text-white text-3xl mt-4">Lorem ipsum dolor sit amet, consectetur<br/> adipiscing elit. Donec posuere velit nec enim<br/> sodales, nec placerat erat tincidunt. </span>
      </div>
      <div class="flex align-items-center gap-5">
          <span class="text-white font-semibold">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
          <i class="pi pi-github text-3xl p-1 surface-overlay border-circle cursor-pointer"></i>
          <i class="pi pi-twitter text-3xl p-1 surface-overlay border-circle cursor-pointer"></i>
      </div>
  </div>
</div>
