import { Component, Input, OnInit } from '@angular/core';
import { MenuService } from '../app.menu.service';
import { LayoutService } from '../service/app.layout.service';
import { AppConfigService } from './app.config.service';

@Component({
    selector: 'app-config',
    templateUrl: './app.config.component.html'
})
export class AppConfigComponent implements OnInit {

    @Input() minimal: boolean = false;

    componentThemes: any[] = [];
    
    menuThemes: any[] = [];

    scales: number[] = [12,13,14,15,16];

    constructor(public layoutService: LayoutService, public menuService: MenuService, public configService: AppConfigService) { }

    get visible(): boolean {
        return this.layoutService.state.configSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.configSidebarVisible = _val;
        if (!_val) {
            this.configService.saveConfig();
        }
    }

    get scale(): number {
        return this.configService.scale;
    }

    set scale(_val: number) {
        this.configService.scale = _val;
    }

    get menuMode(): string {
        return this.configService.menuMode;
    }

    set menuMode(_val: string) {
        this.configService.menuMode = _val;
    }

    get colorScheme(): string {
        return this.configService.colorScheme;
    }

    set colorScheme(_val: string) {
        this.configService.colorScheme = _val;
    }

    get inputStyle(): string {
        return this.configService.inputStyle;
    }

    set inputStyle(_val: string) {
        this.configService.inputStyle = _val;
    }

    get ripple(): boolean {
        return this.configService.ripple;
    }

    set ripple(_val: boolean) {
        this.configService.ripple = _val;
    }

    get menuTheme(): string {
        return this.configService.menuTheme;
    }

    set menuTheme(_val: string) {
        this.configService.menuTheme = _val;
    }

    ngOnInit() {

        this.componentThemes = [
            {name: 'blue', color: '#2196F3'},
            {name: 'green', color: '#4CAF50'},
            {name: 'lightgreen', color: '#8BC34A'},
            {name: 'purple', color: '#9C27B0'},
            {name: 'deeppurple', color: '#673AB7'},
            {name: 'indigo', color: '#3F51B5'},
            {name: 'orange', color: '#FF9800'},
            {name: 'cyan', color: '#00BCD4'},
            {name: 'pink', color: '#E91E63'},
            {name: 'teal', color: '#009688'}
        ];

        this.menuThemes = [
            {name: 'white', color: '#ffffff', logoColor: 'dark', componentTheme: null},
            {name: 'darkgray', color: '#343a40', logoColor: 'white', componentTheme: null},
            {name: 'blue', color: '#2196F3', logoColor: 'white', componentTheme: 'blue'},
            {name: 'bluegray', color: '#455a64', logoColor: 'white', componentTheme: 'lightgreen'},
            {name: 'brown', color: '#5d4037', logoColor: 'white', componentTheme: 'cyan'},
            {name: 'cyan', color: '#00BCD4', logoColor: 'white', componentTheme: 'cyan'},
            {name: 'green', color: '#4CAF50', logoColor: 'white', componentTheme: 'green'},
            {name: 'indigo', color: '#3F51B5', logoColor: 'white', componentTheme: 'indigo'},
            {name: 'deeppurple', color: '#673AB7', logoColor: 'white', componentTheme: 'deeppurple'},
            {name: 'orange', color: '#FF9800', logoColor: 'dark', componentTheme: 'orange'},
            {name: 'pink', color: '#E91E63', logoColor: 'white', componentTheme: 'pink'},
            {name: 'purple', color: '#9C27B0', logoColor: 'white', componentTheme: 'purple'},
            {name: 'teal', color: '#009688', logoColor: 'white', componentTheme: 'teal'},
        ];
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    changeComponentTheme(theme: string) {
        this.configService.changeComponentTheme(theme);
    }

    changeMenuTheme(theme: any) {
        console.log("changeMenuTheme: " + JSON.stringify(theme));
        this.configService.changeMenuTheme(theme);
    }    

    decrementScale() {
        this.configService.decrementScale();
    }

    incrementScale() {
        this.configService.incrementScale();
    }
}
