import { Component } from '@angular/core';

@Component({
    selector: 'prox-home',
    template: `<div>
        <h1>Welcome to Proximity</h1>

        <div>
            <div><button pButton class="font-bold" routerLink="/login">Log in to Proximity</button>.</div>

            <div class="mt-3">Don't have an account?  Contact your company administrator, 
            or <a href="mailto:admin@proximity.com">admin@proximity.com</a>.</div>
        </div>
    </div>`
})
export class ProxHomeComponent {
}
