// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ApprovalTree } from './approval-tree';
import { ApprovalTreeAdminGroup } from './approval-tree-admin-group';
import { ProgramUserGroup } from './program-user-group';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ApprovalTreeUserGroup extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK approvalTreeAdminGroup */
  approvalTreeAdminGroupId!: string;
  /** Guid key FK programUserGroup */
  programUserGroupId!: string;
  /** Guid FK approvalTree */
  approvalTreeId!: string;
  /** FK approvalTreeId */
  approvalTree!: ApprovalTree;
  /** FK approvalTreeAdminGroupId */
  approvalTreeAdminGroup!: ApprovalTreeAdminGroup;
  /** FK programUserGroupId */
  programUserGroup!: ProgramUserGroup;
}

