export class ValidateProximityUserDto {
    Email!: string;
    ApiLink?: string;
    EmailExists: boolean = false;
    ApiLinkExists: boolean = false;

    constructor(Email: string, ApiLink?: string) {
        this.Email = Email;
        this.ApiLink = ApiLink;
    }
}