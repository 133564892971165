import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ImpersonationService } from '../impersonation/impersonation.service';
import { AuthService } from '../../services/auth.service';

// CUSTOM 3/31/2024

@Component({
    selector: 'prox-sub-menu',
    templateUrl: './sub-menu.component.html'
})
export class SubMenuComponent implements OnInit {
  @Input() model: MenuItem[] = [];

    constructor(private impersonationService: ImpersonationService, private authService: AuthService) {}
    selectedIndex = 2;

    clicked(index: number) {
        this.clearAll();
        this.model[index].icon = this.model[index].icon + ' fa-fade';
        this.selectedIndex = index;
    }

    clearAll() {
        this.model.forEach(i => {
            i.icon = i.icon?.replace('fa-fade', '');
        });
    }

    ngOnInit() {
        const user = this.authService.getUser();
    }
}
