// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Program } from './program';
import { ProgramIssuanceUserGroupMap } from './program-issuance-user-group-map';
import { ProgramIssuanceUserLog } from './program-issuance-user-log';
import { ProgramProductTypeTag } from './program-product-type-tag';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
import { Validator } from 'breeze-client';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramIssuance extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static maxGreaterThanMinValidator = new Validator(
    'greaterThanMin',
    (entity: any, context) => {
      const pi = entity as ProgramIssuance;
      const minQty = pi['minimumQty'];
      const maxQty = pi['maximumQty'];
      if (minQty == null && maxQty == null)  {
        return false; 
      }
      if (minQty && !Number.isFinite(minQty)) {
        return false;
      }
      if (maxQty && !Number.isFinite(maxQty)) {
        return false;
      }
      if (minQty && maxQty && maxQty < minQty) {
        return false;
      }

      return true;
    },
    { messageTemplate: 'Minimum and Maximum Qty may not both be empty and Maximum Qty must be greater than or equal to Minimum Qty.' }
  );

  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    
    const propAnnotations = [
      new EntityPropertyAnnotation('minimumQty', {
        validators: [ BaseEntity.greaterThanZeroValidator(true) ]
      }),
      new EntityPropertyAnnotation('maximumQty', {
        validators: [ BaseEntity.greaterThanZeroValidator(true) ]
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [ this.maxGreaterThanMinValidator ],
      propertyAnnotations: propAnnotations
    });
    
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Int32 */
  maximumQty?: number;
  /** Int32 */
  minimumQty?: number;
  /** String(60) */
  name!: string;
  /** Guid FK program */
  programId!: string;
  /** Guid FK programProductTypeTag */
  programProductTypeTagId!: string;
  /** Binary */
  rowVersion!: any;
  /** FK programId */
  program!: Program;
  /** Inv FK programIssuanceId */
  programIssuanceUserGroupMaps!: ProgramIssuanceUserGroupMap[];
  /** Inv FK programIssuanceId */
  programIssuanceUserLogs!: ProgramIssuanceUserLog[];
  /** FK programProductTypeTagId */
  programProductTypeTag!: ProgramProductTypeTag;
}

