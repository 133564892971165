
    <form [formGroup]="form">
        <div class="p-d-flex">
            <div class="p-col-12 mb-3">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                    <input 
                        type="text" 
                        pInputText placeholder="Filter..." 
                        (keyup)="onFilterInputChanged($event)"
                        formControlName="filter">
                    <button type="button" pButton pRipple icon="pi pi-times" (click)="onClearSearch($event)" styleClass="p-button-danger"></button>    
                </div>
            </div>
        </div>
    </form>


