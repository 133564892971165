const EntityFinderDialogHtml = `<div>
  <div *ngIf="isPageReady" class="flex flex-column" style="width: {{ initParams.width }}; height: {{ initParams.height }}">
    <div class="flex-grow-1">
      <div class="w-12" style="height: 100%">
        <prox-ag-grid [gridOptions]="gridOptions" [hasColSizeButtons]="true"></prox-ag-grid>
      </div>
    </div>
    <div class="flex mt-2">
      <div class="mr-2">
        <button (click)="onOk()" [disabled]="!selectedEntity" pButton type="button" label="Ok" icon="pi pi-check" class="p-button-raised"></button>
      </div>

      <div class="pr-2">
        <button (click)="onCancel()" pButton type="button" icon="pi pi-times" label="Cancel"
          class="p-button-raised"></button>
      </div>

      <div *ngIf=" initParams.showClear" class="pl-4">
        <button (click)="onClear()" pButton type="button" icon="fa-regular fa-eraser" label="Clear"
          class="p-button-raised"></button>
      </div>

    </div>
  </div>
  <p-confirmDialog [closable]="false" [baseZIndex]="1000"></p-confirmDialog>
</div>
`
export default EntityFinderDialogHtml;