import { Location } from '@angular/common';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { environment } from '@env';
import { ProximityRightEnum, ProximityUser } from '@models';
import { mapObject } from '@utils';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { MessageBusService } from './message-bus.service';

/** Key from acting-as id in session storage */
const ACTING_KEY = 'ProxActingId';

export class AuthUser extends ProximityUser {
  accountId?: string;
  supplierId?: string;
  accountUserId?: string;
  accountAdminId?: string;
  supplierAdminId?: string;
  companyName?: string;
  actingAsUser?: ProximityUser;

  sessionMinutes?: number;
  roles?: Roles[];
  rights?: ProximityRightEnum[];
  origRoles?: Roles[];

  /** constructor to make class instance from data received in response */
  constructor(source: AuthUser) {
    super();
    for (const p in source) {
      this[p] = source[p];
    }
  }

  /** Return true if user has any of the given roles */
  hasRole(...role: string[]): boolean {
    return !!(this.roles && this.roles.some(r => role.includes(r)));
  }

  /** Return true if user has any of the given roles, or did before impersonating */
  hasOrigRole(...role: string[]): boolean {
    return !!(this.roles && this.roles.some(r => role.includes(r)) || this.origRoles && this.origRoles.some(r => role.includes(r)));
  }

  /** Return true if user has any of the given rights */
  hasRight(...rights: ProximityRightEnum[]): boolean {
    if (this.hasRole(Roles.SuperUser)) {
      return true;
    }
    return !!(this.rights && this.rights.some(r => rights.includes(r)));
  }
}

export enum Roles {
  SuperUser = "GlobalAdministrator",
  AccountAdmin = "AccountAdministrator",
  SupplierAdmin = "SupplierAdministrator",
  AccountUser = "AccountUser",
}

/**
 * Authenticate user and get user profile data from server.
 * Note that cookies must be supported - @see ./auth-request-options.ts
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private _user?: AuthUser;
  private _displayName = '';

  constructor(private _http: HttpClient, private _router: Router, private _location: Location,
    private _toastr: ToastrService, private _idle: Idle, private _keepalive: Keepalive, private _messageBus: MessageBusService) {
  }

  getUser(): AuthUser | undefined {
    return this._user;
  }

  get userDisplayName(): string {
    if (!this._user) {
      this._displayName = '';
    } else if (!this._displayName) {
      this._displayName = this._user.firstName + ' ' + this._user.lastName;
    }
    return this._displayName;
  }

  async getLoggedInUser(): Promise<AuthUser | undefined> {
    const isLoggedIn = await this.isLoggedIn();
    return isLoggedIn ? this._user : undefined;
  }

  /** Login and populate _user by building AuthUser from server data */
  async login(userName: string, password: string, persist: boolean): Promise<boolean> {
    const url = environment.apiUrl + 'Login/login';
    const creds = { userName: userName, password: password, persist: persist };
    const res = await firstValueFrom(this._http.post(url, creds));

    this.setUser(res);
    if (this._user?.id) {
      this._messageBus.notify({ message: 'login' });
      this.setKeepAlive();
      this.navigateAfterLogin();
    }
    return true;
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    const url = environment.apiUrl + 'Login/changePassword';
    const creds = { oldPassword, newPassword };
    const res = await firstValueFrom(this._http.post(url, creds));

    return (res !== null);
  }

  private setUser(res: any) {
    const user = new AuthUser(mapObject<AuthUser>(<any>res));
    this._user = user;
    // Sentry.setUser({ displayName: user.displayName, email: user.email, username: user.loginName });
  }

  navigateToLogin(url?: string) {
    const dest = this.getDestUrl(url);
    this._router.navigate(['/login'], { queryParams: { redirectUrl: dest } });
    setTimeout(() => location.reload(), 100);
  }

  private getDestUrl(url?: string) {
    let dest = url || this._router.routerState.snapshot.root.queryParams['redirectUrl'] || this._router.url;
    if (dest === '/login') { dest = '/'; }
    return dest;
  }

  private navigateAfterLogin() {
    const dest = this.getDestUrl();
    if (this._user) {
      this._router.navigateByUrl(dest, { replaceUrl: true });
    }
  }

  async logout() {
    this.clearImpersonation();
    this._router.navigateByUrl('/login');
    this._user = undefined;
    // this._idle.stop();
    // this._messageBus.notify({ message: 'logout' });
    const url = environment.apiUrl + 'Login/Logout';
    try {
      await firstValueFrom(this._http.get(url));
      this.navigateToLogin();
    } catch (e) {
      console.log('Unable to logout.');
      this.navigateToLogin();
    }
  }

  async isLoggedIn(): Promise<boolean> {
    if (this._user) {
      return true;
    }

    // first see if the user is still logged in on the server.
    try {
      const res = await this.getUserFromBackend();
      return true;
    } catch (e: any) {
      // log error if not a 401 (Unauthorized) or 405 (Method not allowed) error.
      if (e.status !== 401 && e.status !== 405) {
        console.log('login check received unknown error: ' + e.status);
      }
      return false;
    }
  }

  /** If not logged in, redirect to the login page.  Save the intended url for navigation. */
  async redirectIfNotLoggedIn(url?: string): Promise<boolean> {
    const isLoggedIn = await this.isLoggedIn();
    if (!isLoggedIn) {
      this.navigateToLogin(url);
    }
    return isLoggedIn;
  }

  /** Act as the given AccountUser */
  async impersonateUser(proximityUserId: string) {
    const user = this.getUser();
    if (!user || !user.hasOrigRole(Roles.SuperUser, Roles.AccountAdmin)) { return; }
    const newUser = await this.getUserById(proximityUserId);
    if (newUser) {
      this.clearImpersonation();
      user.accountAdminId = newUser.accountAdminId;
      user.accountId = newUser.accountId;
      user.accountUserId = newUser.accountUserId;
      user.actingAsUser = newUser;
      user.companyName = newUser.companyName;
      user.supplierAdminId = newUser.supplierAdminId;
      user.supplierId = newUser.supplierId;
      user.origRoles = user.roles;
      user.roles = newUser.roles;
      user.rights = newUser.rights;
      const rolename = _.startCase(newUser.roles?.length ? newUser.roles[0] : 'Unknown');
      this.setActingAsDisplayName(user.actingAsUser, rolename);
      sessionStorage.setItem(ACTING_KEY, proximityUserId);
    }
  }

  /** Undo impersonation.  Reload current user so data is correct. */
  stopImpersonation() {
    this.clearImpersonation();
    location.reload();
  }

  /** Remove impersonation data, so admin is only him/herself */
  private clearImpersonation() {
    sessionStorage.removeItem(ACTING_KEY);
    this._displayName = '';
    const user = this.getUser();
    if (!user) { return; }
    user.accountAdminId = undefined;
    user.accountId = undefined;
    user.accountUserId = undefined;
    user.actingAsUser = undefined;
    user.companyName = undefined;
    user.supplierAdminId = undefined;
    user.supplierId = undefined;
    user.origRoles = undefined;
  }

  private setActingAsDisplayName(asUser: ProximityUser, role: string) {
    const user = this._user as AuthUser;
    this._displayName = `${user.firstName} ${user.lastName} as ${asUser.firstName} ${asUser.lastName} (${role})`;
  }

  /** Populate _user by building AuthUser from server data */
  private async getUserFromBackend(): Promise<AuthUser | undefined> {
    const url = environment.apiUrl + 'Login/GetLoggedInUser';
    const res = await firstValueFrom(this._http.get(url));

    this.setUser(res);
    this.setKeepAlive();
    const actingId = sessionStorage.getItem(ACTING_KEY);
    if (actingId) {
      await this.impersonateUser(actingId);
    }
    return this._user;
  }

  /** Get a user from the server by ProximityUser Id */
  private async getUserById(userId: string): Promise<AuthUser | undefined> {
    const url = environment.apiUrl + 'Login/GetUserById';
    const res = await firstValueFrom(this._http.get(url, { params: { userId }}));

    const user = new AuthUser(mapObject<AuthUser>(<any>res));
    return user;
  }

  /** Monitor the app for user activity and send session keepalive requests to the server */
  private setKeepAlive() {
    // See https://moribvndvs.github.io/ng2-idle/quickstart

    const idle = this._idle;
    const keepalive = this._keepalive;

    if (idle.isRunning() || !this._user) { return; }

    if (idle.getInterrupts() && idle.getInterrupts().length) {
      // already configured, just start
      idle.watch();
      return;
    }

    // sets an idle timeout equal to the server session timeout.
    idle.setIdle((this._user?.sessionMinutes || 60) * 60);
    // sets a timeout grace period.  After idle + timeout, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.logout();
    });
    idle.onTimeoutWarning.subscribe((countdown) => {
      if (countdown % 10 === 0) {
        this._toastr.warning('You will be logged out in ' + countdown + ' seconds.  Click to keep your session alive.', 'Timeout');
      }
    });
    idle.onIdleEnd.subscribe(() => {
      keepalive.ping();
    });    

    // sets the ping interval - how often to ping the server to keep the server session alive
    keepalive.interval(300);

    // set the ping request
    const url = environment.apiUrl + 'Login/KeepAlive';
    const req = new HttpRequest('GET', url, { withCredentials: true });
    keepalive.request(req);

    // start idle watching
    idle.watch();
  }

}
