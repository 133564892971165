// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProgramProductCategoryTag } from './program-product-category-tag';
import { ProgramProductTypeTag } from './program-product-type-tag';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramProductCategoryTagMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK programProductCategoryTag */
  programProductCategoryTagId!: string;
  /** Guid key FK programProductTypeTag */
  programProductTypeTagId!: string;
  /** FK programProductCategoryTagId */
  programProductCategoryTag!: ProgramProductCategoryTag;
  /** FK programProductTypeTagId */
  programProductTypeTag!: ProgramProductTypeTag;
}

