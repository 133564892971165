import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private titleSource = new Subject<string>();
  titleHandler = this.titleSource.asObservable();

  setTitle(title: string) {
    this.titleSource.next(title);
  }
}
