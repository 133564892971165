import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '@env';
import { NotificationQueryEnum } from '@models';
import { sanitize } from 'dompurify';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';


export interface TemplatePreviewDialogParams {
    /** Html with placeholder tokens */
    template: string;
    /** Whether to convert html to plain text */
    isText: boolean;
    queryId: NotificationQueryEnum;
    /** json e.g. "{ accountId: 123, jobOrderId: 456 }" */
    context: string;
}

@Component({
  selector: 'prox-template-preview-dialog',
  template: `
  <div class="h-full flex flex-column" >
    <div class="flex-grow-1" [innerHTML]="content">
    </div>
    <div class="text-right">
      <button (click)="close()" pButton type="button" icon="pi pi-times" label="Close" class="p-button-raised p-button-sm"></button>
    </div>
  </div>`
})
export class TemplatePreviewDialogComponent implements OnInit {

  /** html with values merged */
  content?: SafeHtml;
  data: TemplatePreviewDialogParams;

  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef, private domSanitizer: DomSanitizer, private httpClient: HttpClient) {
    this.data = dialogConfig.data;
  }

  public static async openDialog(dialogService: DialogService, data: TemplatePreviewDialogParams, config?: DynamicDialogConfig<TemplatePreviewDialogParams>) {
    const ref = dialogService.open(TemplatePreviewDialogComponent, {
      closable: false, 
      draggable: true,
      width: '780px',
      height: '70vh',
      ...config,
      data: data,
    });
    return firstValueFrom(ref.onClose);
  }

  async ngOnInit(): Promise<void> {
    this.showContent();
  }

  async showContent(): Promise<void> {
    const url = environment.accountUrl + (this.data.isText ? '/MergeTemplateText': '/MergeTemplateHtml');
    const body = { 
      template: this.data.template,
      notificationQueryId: this.data.queryId,
      contextJson: this.data.context,
    };
    try {
      const merged = await firstValueFrom(this.httpClient.post<{Value: string}>(url, body));
      const content = merged ? sanitize(merged.Value) : 'no content';
      this.content = this.domSanitizer.bypassSecurityTrustHtml(content);
    } catch {
      this.content = 'Unable to preview';
    }
  }

  close() {
    this.ref.close();
  }

}
