<div *ngIf="isPageReady" class="flex flex-column">
  <div>
    <h5>Change status to:</h5>
  </div>
  <div class="flex-grow-1">
    <div *ngFor="let status of statuses" class="field-checkbox">
      <p-radioButton [disabled]="!isAvailable(status)" name="statusname" [value]="status"
        [(ngModel)]="currentStatus"> </p-radioButton>
      <label class="ml-2">{{ status.description + ( isAvailable(status) ? '' : ' (not available)') }}</label>
    </div>
  </div>
  <div class="flex">
    <div class="mr-2">
      <button #ok [disabled]="isDisabled()" (click)="onOk()" pButton type="button" label="Ok" icon="pi pi-check"
        class="p-button-raised"></button>
    </div>

    <div class="pr-2">
      <button (click)="onCancel()" pButton type="button" icon="pi pi-times" label="Cancel"
        class="p-button-raised"></button>
    </div>
  </div>
</div>
<p-confirmDialog [closable]="false" [baseZIndex]="1000"></p-confirmDialog>