// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { ActiveStatus } from './active-status';
import { AddonImage } from './addon-image';
import { Supplier } from './supplier';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Addon extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getStatusParents() {
    return [this.account];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId?: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String */
  additionalInfoDescription?: string;
  /** String(200) */
  apiLink?: string;
  /** String(100) */
  nameAndLocation!: string;
  /** Boolean */
  needsAdditionalInfo!: boolean;
  /** Binary */
  rowVersion!: any;
  /** Guid FK supplier */
  supplierId!: string;
  /** FK accountId */
  account!: Account;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK addonId */
  addonImages!: AddonImage[];
  /** FK supplierId */
  supplier!: Supplier;
}

