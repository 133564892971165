// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Manifest } from './manifest';
import { Supplier } from './supplier';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class SupplierManifestMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK supplier */
  supplierId!: string;
  /** Guid key FK manifest */
  manifestId!: string;
  /** FK manifestId */
  manifest!: Manifest;
  /** FK supplierId */
  supplier!: Supplier;
}

