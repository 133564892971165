// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Addon } from './addon';
import { SupplierImage } from './supplier-image';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AddonImage extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK addon */
  addonId!: string;
  /** String */
  apiLink?: string;
  /** Boolean */
  isPrimary!: boolean;
  /** Boolean */
  isThumbnail!: boolean;
  /** Binary */
  rowVersion!: any;
  /** Guid FK supplierImage */
  supplierImageId!: string;
  /** FK addonId */
  addon!: Addon;
  /** FK supplierImageId */
  supplierImage!: SupplierImage;
}

