// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ActiveStatus } from './active-status';
import { PricedProductType } from './priced-product-type';
import { ProductTypeConfigAddon } from './product-type-config-addon';
import { ProductTypeConfigProduct } from './product-type-config-product';
import { ProgramProductTypeConfig } from './program-product-type-config';
import { ReturnPolicy } from './return-policy';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProductTypeConfig extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getStatusParents() {
    return [this.pricedProductType];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String(60) */
  name!: string;
  /** Guid FK pricedProductType */
  pricedProductTypeId!: string;
  /** Guid FK returnPolicy */
  returnPolicyId?: string;
  /** Binary */
  rowVersion!: any;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK pricedProductTypeId */
  pricedProductType!: PricedProductType;
  /** Inv FK productTypeConfigId */
  productTypeConfigAddons!: ProductTypeConfigAddon[];
  /** Inv FK productTypeConfigId */
  productTypeConfigProducts!: ProductTypeConfigProduct[];
  /** Inv FK productTypeConfigId */
  programProductTypeConfigs!: ProgramProductTypeConfig[];
  /** FK returnPolicyId */
  returnPolicy!: ReturnPolicy;
}

