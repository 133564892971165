import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface TextboxDialogData {
  pretext?: string;
  posttext?: string;
  text: string;
  rows?: number;
  cols?: number;
}

@Component({
  selector: 'prox-textbox-dialog',
  template: `
  <div>
    <div class="text-l mb-3">{{ data.pretext }}</div>
    <textarea readonly [rows]="rows" [cols]="cols" id="textbox-dialog-text">{{ data.text }}</textarea>
    <div class="text-l mb-3">{{ data.posttext}}</div>
    <div class="text-right mt-3">
      <button (click)="close()" pButton type="button" icon="pi pi-times" label="Close"
            class="p-button-raised p-button-primary"></button>
    </div>

  </div>
  `,
})
export class TextboxDialogComponent {

  data: TextboxDialogData;
  rows: number;
  cols: number;

  constructor(dialogConfig: DynamicDialogConfig<TextboxDialogData>, private ref: DynamicDialogRef) {
    this.data = dialogConfig.data || { text: 'no data' };
    this.rows = this.data.rows || 7;
    this.cols = this.data.cols || 80;
  }

  close() {
    this.ref.close();
  }

}
