/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridOptions, GridReadyEvent, IServerSideDatasource, RowDoubleClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Entity } from 'breeze-client';
import EntityFinderDialogHtml from './entity-finder.dialog.html';
import { AgFns, ISortModel } from '../ag-grid';

export interface EntityFinderParams {
  header?: string;
  idProp?: string;
  nameProp?: string;
  width?: string;
  height?: string;
  rowSelection?: 'multiple' | 'single';
  rowModelType?: 'serverSide' | 'clientSide';
  showClear?: boolean;
}

export type OptionalEntityFinderParams = Partial<EntityFinderParams>
@Component({
  selector: 'prox-entity-finder',
  template: '',

})
// export abstract class EntityFinderDialog<T extends Entity, TArgs extends EntityFinderParams> implements OnInit {
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class EntityFinderDialog<T extends Entity, TParams extends EntityFinderParams> implements OnInit {
  public static HTML = EntityFinderDialogHtml;
  firstRender = true;
  initParams!: any;

  defaultParams: EntityFinderParams = {
    header: 'Select something...',
    idProp: 'id',
    nameProp: 'name',
    width: 'calc(60vw)',
    height: 'calc(60vh)',
    rowSelection: 'single',
    rowModelType: 'serverSide',
    showClear: false
  }

  datasource!: IServerSideDatasource | T[];
  gridOptions!: GridOptions;
  selectedEntity?: T = undefined;
  isPageReady = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<TParams>,
  ) {
  }

  async ngOnInit() {
    const initParams = await this.initialize();
    this.initParams = { ...this.defaultParams, ...initParams, ...this.config.data }
    this.config.header = this.initParams.header;

    this.datasource = await this.initDatasource();
    const rowModelType = AgFns.implementsIServerSideDatasouce(this.datasource) ? 'serverSide' : 'clientSide';

    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onRowSelected,
      onRowDoubleClicked: this.onRowDoubleClicked,
      rowSelection: this.initParams.rowSelection,
      suppressRowClickSelection: false,
      rowModelType: rowModelType
    });

    if (this.gridOptions.rowSelection == 'multiple') {
      this.gridOptions.suppressRowClickSelection = true;
    };

    this.isPageReady = true;
  }

  async initialize(): Promise<Partial<TParams>> {
    return  {} as const;
  }

  async onGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    const gridApi = this.gridOptions.api;
    if (AgFns.implementsIServerSideDatasouce(this.datasource)) {
      gridApi?.setServerSideDatasource(this.datasource);
    } else {
      gridApi?.setRowData(this.datasource);
    }
  }

  abstract initDatasource(): Promise<IServerSideDatasource | T[]>;

  getColDefsAndSortModel() {
    const colDefs = [
      { field: this.initParams.nameProp },
    ] as ColDef[];
    const sortModel = [
      { colId: this.initParams.nameProp }
    ] as ISortModel;
    return [colDefs, sortModel] as const;
  }

  onRowSelected(event: RowSelectedEvent) {
    this.selectedEntity = <T>event.data;
    if (this.firstRender) {
      this.gridOptions.api?.getDisplayedRowAtIndex(0)?.setSelected(false);
      this.firstRender = false;
    }
  }

  onRowDoubleClicked(event: RowDoubleClickedEvent) {
    if (!event.node.isSelected()) {
      return;
    }
    this.selectedEntity = event.data as T;
    this.ref.close([this.selectedEntity]);
  }

  onOk() {
    if (this.initParams.rowSelection === 'multiple') {
      const rows = this.gridOptions.api?.getSelectedRows();
      this.ref.close(rows);
    } else {
      this.ref.close([this.selectedEntity]);
    }
  }

  onClear(){}

  onCancel() {
    this.ref.close([]);
  }
}