import { Component } from '@angular/core';
import { EntityError } from 'breeze-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-error-dialog',
  template: `
  <div>
    <div class="text-l font-bold mb-3">{{ message}}</div>
    <ng-container *ngIf="!!entityErrors">
      <div class="flex" *ngFor="let e of entityErrors">
          <div class="w-3">{{ e.errorName }}</div>
          <div class="w-9">{{ e.errorMessage }}</div>
      </div>
    </ng-container>
    <div class="text-right mt-3">
      <button (click)="reload()" pButton type="button" icon="pi pi-refresh" label="Reload"
            class="p-button-raised p-button-primary mr-3"></button>
      <button (click)="close()" pButton type="button" icon="pi pi-times" label="Close"
            class="p-button-raised p-button-danger"></button>
    </div>
    
  </div>
  `,
})
export class ErrorDialogComponent {

  message?: string;
  entityErrors?: EntityError[];
  data: any;
  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef) {
    this.data = dialogConfig.data;
    this.formatError(this.data.error);
  }

  private formatError(err: Error) {
    this.message = err.message;

    const occurred = this.message.indexOf('--An error occurred while saving', 20);
    if (occurred > 0) {
      this.message = this.message.substring(0, occurred);
    }

    const ees: EntityError[] = (err as any).entityErrors;
    if (ees && ees.length) {
      for (const e of ees) {
        const entityName = e.entity ? e.entity.toString() : e.errorName;
        e.errorName = e.propertyName ? entityName + ' [' + e.propertyName + ']' : entityName;
      };
      this.entityErrors = ees;
    }
  }

  close() {
    this.ref.close();
  }

  reload() {
    location.reload();
  }

}
