import { CanDeactivateFn } from '@angular/router';
// import { inject } from '@angular/core';
// import { DialogService } from '../services/dialog.service';

export interface CanComponentDeactivate {
  canDeactivate?: () => Promise<boolean> | boolean;
  deactivate?: () => void;
}

export const canDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (component: CanComponentDeactivate) => {
  // const dialogService = inject(DialogService);

  if (component.canDeactivate) {
    return Promise.resolve(component.canDeactivate()).then((ok) => {
      if (ok) {
        component.deactivate && component.deactivate();
      }
      return ok;
    });
  } 
    
  component.deactivate && component.deactivate();
  return true;
  
}