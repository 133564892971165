export enum RecordStatusEnum { EDIT, ADD };

export enum RecordTypeEnum {
    MANUFACTURER,
    PRICELIST,
    MENUTAG,
    APPLIEDSUPPLIER,
    PLAN,
    USER,
    BLOB,
    PRODUCT,
    SILO
  }

export enum ImportTypeEnum {
  USERS = "USERS",
  PRODUCTS = "PRODUCTS"
}  