// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProximityRight } from './proximity-right';
import { ProximityUser } from './proximity-user';
import { ProximityRightEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProximityUserRight extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK proximityRight */
  proximityRightId!: ProximityRightEnum;
  /** Guid FK proximityUser */
  proximityUserId!: string;
  /** Binary */
  rowVersion!: any;
  /** FK proximityRightId */
  proximityRight!: ProximityRight;
  /** FK proximityUserId */
  proximityUser!: ProximityUser;
}

