// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { FeatureChoice } from './feature-choice';
import { JobOrderHistDetail } from './job-order-hist-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrderHistDetailFeature extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK featureChoice */
  featureChoiceId!: string;
  /** Guid FK jobOrderHistDetail */
  jobOrderHistDetailId!: string;
  /** Decimal */
  price!: number;
  /** FK featureChoiceId */
  featureChoice!: FeatureChoice;
  /** FK jobOrderHistDetailId */
  jobOrderHistDetail!: JobOrderHistDetail;
}

