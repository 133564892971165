// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProximityUser } from './proximity-user';
import { ReturnRequest } from './return-request';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ReturnRequestNote extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String */
  note!: string;
  /** DateTimeOffset */
  noteTs!: Date;
  /** Guid FK proximityUser */
  proximityUserId?: string;
  /** Guid FK returnRequest */
  returnRequestId!: string;
  /** FK proximityUserId */
  proximityUser!: ProximityUser;
  /** FK returnRequestId */
  returnRequest!: ReturnRequest;
}

