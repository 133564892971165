<div class="pb-2">
    <div
        class="layout-breadcrumb-container flex justify-content-between align-items-center shadow-1">
        <p-breadcrumb
            [model]="items"
            [home]="home"
            styleClass="layout-breadcrumb pl-4 py-2"
        ></p-breadcrumb>
    </div>
</div>
