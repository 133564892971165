/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UtilDialogService } from "./dialog.service";
import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { AuthService } from "./auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import { TitleService } from "@services";

/** Collection of services, for convenience */

type IParams = { [key: string]: any };

@Injectable({ providedIn: 'root' })
export class BaseService  {
  private returnParams?: IParams

  constructor(
    public authService: AuthService,
    public toastr: ToastrService,
    public dialogService: UtilDialogService,
    public router: Router,
    public location: Location,
    public titleService: TitleService,
    public readonly domSanitizer: DomSanitizer,
  ) {}

  getLocationParams() {
    return this.returnParams;
  }

  storeLocationParams(route: ActivatedRoute) {
    const qp = route.snapshot.queryParams;
    const keys = Object.keys(qp);
    const returnParams = {};
    keys.forEach(k => {
      returnParams[k] = qp[k];
    });
    this.returnParams = returnParams;
  }

  updateLocation( params?: IParams) {
    if (!params) return;
    const urlNoQp= this.router.url.split('?')[0] 
    const keys = Object.keys(params);
    const urlSuffix = keys.map(k => k + '=' + params[k]).join('&');
    const url = urlNoQp + '?' + urlSuffix;
    this.location.replaceState(url);
  }
}
