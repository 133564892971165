// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountUser } from './account-user';
import { ShippingUserGroup } from './shipping-user-group';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ShippingUserGroupMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK shippingUserGroup */
  shippingUserGroupId!: string;
  /** Guid key FK accountUser */
  accountUserId!: string;
  /** FK accountUserId */
  accountUser!: AccountUser;
  /** FK shippingUserGroupId */
  shippingUserGroup!: ShippingUserGroup;
}

