export class ImportAccountUserDto {
    accountId!: string;
    firstName!: string;
    lastName!: string;
    middleName?: string;
    salutation!: string;
    genderId!: number;
    apiLink?: string;
    email!: string;
    cellNumber?: string;
    acceptSms?: boolean;
    telephoneNumber?: string;
    extension?: string;
  }