// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProductTypeVersion extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  productTypeId!: string;
  /** Guid key */
  nextProductTypeId!: string;
  /** Binary */
  rowVersion!: any;
  /** String(100) */
  versionName!: string;
  /** String */
  versionType!: string;
}

