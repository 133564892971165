/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { UtilFns } from '@utils';
import { ColDef } from '@ag-grid-community/core';
import { TypedQuery } from '@data';
import { AgFns, EntityFinderDialog, EntityFinderParams, ISortModel } from '../components';
import { PricedProductType } from '@models';


export interface PricedProductTypeFinderParams extends EntityFinderParams {
  pricedProductTypes?: PricedProductType[];
  queryFn?: () => TypedQuery<PricedProductType>
}

@Component({
  selector: 'prox-priced-product-type-finder',
  template: EntityFinderDialog.HTML,

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PricedProductTypeFinderDialog extends EntityFinderDialog<PricedProductType, PricedProductTypeFinderParams> {

  params!: PricedProductTypeFinderParams;
  
  constructor(
    ref: DynamicDialogRef,
    override config: DynamicDialogConfig<PricedProductTypeFinderParams>,
    // public dbQueryService: SupplierDbQueryService,

  ) {
    super(ref, config);
    UtilFns.assertNonNull(config.data);
    this.params = config.data;
  }

  override async initialize() {
    return {
      header: 'Select a Product Type',
      width: 'calc(80vw)',
    }
  }

  override async initDatasource() {
      if (this.params.pricedProductTypes) {
        return this.params.pricedProductTypes;
      } else if (this.params.queryFn != null) {
            return AgFns.buildDatasource(this.params.queryFn);
      } else {
        throw new Error('This finder needs either an list of pricedProductTypes or a query function');
      }
  }

  override getColDefsAndSortModel() {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'productType.manufacturer.name', filter: 'agTextColumnFilter', checkboxSelection: this.initParams.rowSelection == 'multiple' },
      { headerName: 'SKU', field: 'productType.productKey', filter: 'agTextColumnFilter' },
      { headerName: 'Name', field: 'productType.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'productType.activeStatus.name' },
      { headerName: 'Base Price', field: 'basePrice' },
    ] as ColDef[]
    const sortModel = [
      { colId: 'productType.manufacturer.name', sort: 'asc' },
      { colId: 'productType.productKey', sort: 'asc' }
    ] as ISortModel
    return [ colDefs, sortModel] as const;
  }
}