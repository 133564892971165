import { Component } from '@angular/core';
import { DbQueryService, UnitOfWork } from '@data';
import { Entity } from 'breeze-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface ChangeLogDialogData {
  title: string;
  dbQueryService: DbQueryService;
  entityName: string;
  entityId: string;
  filterModel: object;
}

@Component({
  selector: 'prox-changelog-dialog',
  template: `
  <div class="h-full">
    <prox-changelog-grid [uow]="uow" [entity]="entity" [entityId]="entityId" [filterModel]="filterModel"></prox-changelog-grid>
  </div>
  `,
})
export class ChangeLogDialogComponent {

  uow: UnitOfWork;
  filterModel: object;
  entity: Entity;
  entityId: string;

  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef) {
    this.uow = dialogConfig.data.uow;
    this.entity = dialogConfig.data.entity;
    this.entityId = dialogConfig.data.entityId;
    this.filterModel = dialogConfig.data.filterModel;
  }

  close() {
    this.ref.close();
  }

}
