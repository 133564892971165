import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageFns, ProxImage } from '@utils';
import * as _ from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface FileGalleryDialogData {
  newImages: ProxImage[];
  oldImages: ProxImage[];
  /** callback when an image is deleted */
  onDelete?: (image: ProxImage) => void;
  /** callback when an image is added or changed */
  onChange?: (image: ProxImage) => void;
}

@Component({
  selector: 'prox-file-gallery-dialog',
  templateUrl: './file-gallery-dialog.component.html',
})
export class FileGalleryDialogComponent implements OnInit, OnDestroy {

  static config: DynamicDialogConfig = { header: 'View Images', width: '900px', height: '800px', closable: true };
  activeIndex = 0;
  showGallery = true;
  showEdit = false;
  images: ProxImage[] = [];
  cropInput?: File;
  data: FileGalleryDialogData;

  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef, private readonly sanitizer: DomSanitizer) {
    this.data = dialogConfig.data;
  }

  ngOnInit(): void {
    this.activeIndex = 0;
    const images = ImageFns.convertFileData(this.data.oldImages)
    this.images = images.concat(this.data.newImages);
    this.images.sort((a, b) => {
      if (a.isPrimary && !b.isPrimary) {
        return -1;
      } else if (b.isPrimary && !a.isPrimary) {
        return 1;
      }
      return a.fileName.localeCompare(b.fileName);
    });
    ImageFns.generateUrls(this.images, this.sanitizer);
  }

  ngOnDestroy(): void {
    ImageFns.clearUrls(this.images);
  }

  delete(image: ProxImage): void {
    if (image && this.data.onDelete) {
      this.data.onDelete(image);
      if (image.blobUrl) {
        image.safeUrl = undefined;
        URL.revokeObjectURL(image.blobUrl);
      }
      _.remove(this.images, im => im === image);
      if (this.activeIndex >= this.images.length && this.activeIndex > 0) {
        this.activeIndex--;
      }
      this.redraw();
    }
  }

  /** return false if image is a thumbnail, or has a related thumbnail already */
  canMakeThumbnail(image: ProxImage): boolean {
    const thumbname = ImageFns.addSuffix(image.fileName, '-thumb');
    return !image.isThumbnail && !this.images.some(i => i.fileName == thumbname);
  }

  /** Create a new image as a thumbnail of the given image */
  async makeThumbnail(image: ProxImage) {
    if (!image.file) {
      return;
    }
    const thumb = await ImageFns.resizeFileToThumbnail(image.file);
    this.addImage(thumb);
  }

  /** Ensure that at most 1 image is set primary */
  primaryChange(item: ProxImage) {
    if (this.data.onChange) {
      this.data.onChange(item);
    }
    if (item.isPrimary) {
      // make all other images non-primary, but treat thumbnails separately
      for (const i of this.images) {
        const primary = (i == item); 
        if (i.isPrimary != primary && i.isThumbnail == item.isThumbnail) {
          i.isPrimary = primary;
          if (this.data.onChange) {
            this.data.onChange(i);
          }
        }
      }
    }
  }

  /** Open imageCropper for active image */
  edit(item: ProxImage) {
    this.cropInput = item.file;
    this.showGallery = false;
    this.showEdit = true;
  }


  /** When Ok clicked on cropper, add the cropped file as a new image */
  async cropOk(blob: Blob) {
    if (blob) {
      const image = this.images[this.activeIndex];
      const file = new File([blob], image.fileName, { lastModified: image.file?.lastModified, type: blob.type });
      const names = this.images.map(i => i.fileName);
      const newName = ImageFns.getNextName(image.fileName, names);
      const image2 = await ImageFns.resizeFileToStandard(file, newName);
      this.addImage(image2);
    }
    this.cropCancel();
  }

  /** When Cancel clicked on cropper */
  cropCancel() {
    this.showEdit = false;
    this.showGallery = true;
  }

  close() {
    ImageFns.clearUrls(this.images);
    this.ref.close();
  }

  /** Add to list as a new image */
  private addImage(thumb: ProxImage) {
    this.data.newImages?.push(thumb);
    ImageFns.generateUrls([thumb], this.sanitizer);
    this.images.splice(this.activeIndex+1, 0, thumb);
    this.activeIndex++;
    if (this.data.onChange) {
      this.data.onChange(thumb);
    }
    this.redraw();
  }

  /** Hide and show the gallery to reset the thumbnail display. */
  private redraw() {
    this.showGallery = false;
    setTimeout(() => {
      this.showGallery = true;
    }, 0);
  }

}
