import { Component, Input, OnInit } from '@angular/core';
import {
  IAddress,
  
} from '@models';
import { UtilFns } from '@utils';
import { Entity } from 'breeze-client';

export const UsStateHash = {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FM: "Federated States Of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming"
  };

@Component({
  selector: 'prox-address-frm',
  templateUrl: './address-frm.component.html',
})
export class AddressFrmComponent implements OnInit {
  @Input() address!: IAddress; 
  @Input() disabled?: boolean;
  @Input() parent?: Entity;
  @Input() addressPath?: string;
  
  parentEntity!: Entity;
  usStates!: { abbr: string, name: string } [];
  isPageReady = false;

  ngOnInit(): void {
    this.usStates = Object.entries(UsStateHash).map( kv => {
      return { abbr: kv[0], name: kv[1] };
    })
    UtilFns.addNullChoice(this.usStates, 'addr', undefined, 'name' );
    this.parentEntity = this.parent ?? this.address as Entity;
    this.disabled = !!this.disabled;
    this.isPageReady = true;  
  }

  pathify(partialPath: string) {
    if (this.addressPath) {
      return this.addressPath + '.' + partialPath;
    } else {
      return partialPath;
    }
  }
}
