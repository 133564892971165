/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { SupplierUnitOfWork } from '@data';
import { Account } from '@models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntityFinderDialog, EntityFinderParams } from '../base/entity-finder.dialog';
import { AgFns, ISortModel } from '../ag-grid';
import { ColDef } from '@ag-grid-community/core';

@Component({
  selector: 'prox-account-finder-2',
  template: EntityFinderDialog.HTML,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CoreAccountFinderDialog extends EntityFinderDialog<Account, EntityFinderParams> implements OnInit {
  
  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig,
    public uow: SupplierUnitOfWork,
    
  ) {
    super(ref, config  );
  }

  override async initialize(): Promise<EntityFinderParams> {
      return {
        header: 'Select an Account Partner'
      }
  }

  async initDatasource() {
    const datasource = AgFns.buildDatasource(() => this.uow.createQuery(Account));
    return datasource;
  }

  override getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { headerName: 'Account Name', field: 'name', filter: 'agTextColumnFilter',   },
      { headerName: 'Status', field: 'activeStatus.name' }
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }
}
