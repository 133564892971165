// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Invoice } from './invoice';
import { ShipmentBox } from './shipment-box';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Shipment extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String */
  apiLink!: string;
  /** String(255) */
  carrierApiIdentifier?: string;
  /** Decimal */
  freightAmt!: number;
  /** Decimal */
  insuranceAmt!: number;
  /** String(255) */
  labelApiIdentifier?: string;
  /** String(255) */
  serviceApiIdentifier?: string;
  /** DateTimeOffset */
  shipTs!: Date;
  /** String(255) */
  shipmentApiIdentifier?: string;
  /** Inv FK shipmentId */
  invoices!: Invoice[];
  /** Inv FK shipmentId */
  shipmentBoxes!: ShipmentBox[];
}

