import { Subscription } from "rxjs";
import { AnyCatcher } from "rxjs/internal/AnyCatcher";
import { ImportTypeEnum } from "@models";

export interface ITreeNodeDto {
    id: string;
    name: string;
    hierarchy: string;
    children: ITreeNodeDto[];
}

export class FileUploadDto{
    data: File;
    state: string | null;
    inProgress: boolean;
    progress: number;
    canRetry: boolean;
    canCancel: boolean;
    sub?: Subscription;

    constructor(
        data = new File([], ", null"),
        state: string | null = null,
        inProgress: boolean = false,
        progress: number = 0,
        canRetry: boolean = false,
        canCancel: boolean = false,
        sub?: Subscription
    ){
        this.data = data;
        this.state = state;
        this.inProgress = inProgress;
        this.progress = progress;
        this.canRetry = canRetry;
        this.canCancel = canCancel;
        this.sub = sub;
    }
}

// export class ProductImageInfoDto {
//     supplierImageKey: string | null;
//     manufacturerImageKey: string | null;
//     mainSku: string | null;
//     alt1Sku: string | null;
//     alt2Sku: string | null;
//     alt3Sku: string | null;
//     mainFileName: string | null;
//     alt1FileName: string | null;
//     alt2FileName: string | null;
//     alt3FileName: string | null;

//     constructor (
//         SupplierImageKey: string | null = null,
//         ManufacturerImageKey: string | null = null
//     ){
//         this.supplierImageKey = SupplierImageKey;
//         this.manufacturerImageKey = ManufacturerImageKey;
//         this.mainSku = null;
//         this.alt1Sku = null;
//         this.alt2Sku = null;
//         this.alt3Sku = null;
//         this.mainFileName = null;
//         this.alt1FileName = null;
//         this.alt2FileName = null;
//         this.alt3FileName = null;
//     }
// }

export interface IImportHeader {
    id: string;
    importType: ImportTypeEnum;
    accountId: string | null;
    supplierId: string | null;
    manufacturerId: string | null;
}

export interface IUserRecords_v1 {
    firstName: string;
    lastName: string;
    email: string;
    status: string;
}

export class ImportDto {
    importType: ImportTypeEnum;
    accountId: string | null = null;
    supplierId: string | null = null;
    manufacturerId: string | null = null;
    records: any[] | null = null;

    constructor(
        ImportType: ImportTypeEnum,
    ){
        this.importType = ImportType;
    }
}

export class NotificationDto {
    severity: string;
    summary: string ;
    detail: string ;

    constructor(
        Severity: string,
        Summary: string,
        Detail: string
    ){
        this.severity = Severity;
        this.summary = Summary;
        this.detail = Detail;
    }
}

export class ProductImageStatusDto{
    supplierImageKey: string;
    manufacturerImageKey: string;
    productSku: string;
    mainPath: string | null;
    thumbPath: string | null;
    largePath: string | null;
    alt1MainPath: string | null;
    alt1ThumbPath: string | null;
    alt1LargePath: string | null;
    alt2MainPath: string | null;
    alt2ThumbPath: string | null;
    alt2LargePath: string | null;
    alt3MainPath: string | null;
    alt3ThumbPath: string | null;
    alt3LargePath: string | null;

    constructor(
        supplierImageKey: string, 
        manufacturerImageKey: string, 
        productSku: string)
    {
        this.supplierImageKey = supplierImageKey;
        this.manufacturerImageKey = manufacturerImageKey;
        this.productSku = productSku;
        this.mainPath = null;
        this.thumbPath = null;
        this.largePath = null;
        this.alt1MainPath = null;
        this.alt1ThumbPath = null;
        this.alt1LargePath = null;
        this.alt2MainPath = null;
        this.alt2ThumbPath = null;
        this.alt2LargePath = null;
        this.alt3MainPath = null;
        this.alt3ThumbPath = null;
        this.alt3LargePath = null;
    }
}