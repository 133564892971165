// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountAdminGroup } from './account-admin-group';
import { ApprovalTree } from './approval-tree';
import { ApprovalTreeAdminGroupLog } from './approval-tree-admin-group-log';
import { ApprovalTreeUserGroup } from './approval-tree-user-group';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ApprovalTreeAdminGroup extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // assumes all logs for entire tree have been fetched. - recursive
  calcPrevAmtFromLogs() {
    let prevAmt = _.sum(this.approvalTreeAdminGroupLogs.map(x => x.amt));
    this.childrenApprovalTreeAdminGroups.forEach(x => {
      prevAmt += x.calcPrevAmtFromLogs()
    })
    return prevAmt;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK accountAdminGroup */
  accountAdminGroupId!: string;
  /** Guid */
  approvalTreeAdminGroupId?: string;
  /** Guid FK approvalTree */
  approvalTreeId!: string;
  /** Decimal */
  budgetApprovalLimitAmt?: number;
  /** Guid FK parentApprovalTreeAdminGroup */
  parentApprovalTreeAdminGroupId?: string;
  /** FK accountAdminGroupId */
  accountAdminGroup!: AccountAdminGroup;
  /** FK approvalTreeId */
  approvalTree!: ApprovalTree;
  /** Inv FK approvalTreeAdminGroupId */
  approvalTreeAdminGroupLogs!: ApprovalTreeAdminGroupLog[];
  /** Inv FK approvalTreeAdminGroupId */
  approvalTreeAdminGroups!: ApprovalTreeAdminGroup[];
  /** Inv FK approvalTreeAdminGroupId */
  approvalTreeUserGroups!: ApprovalTreeUserGroup[];
  /** Inv FK parentApprovalTreeAdminGroupId */
  childrenApprovalTreeAdminGroups!: ApprovalTreeAdminGroup[];
  /** FK parentApprovalTreeAdminGroupId */
  parentApprovalTreeAdminGroup!: ApprovalTreeAdminGroup;
}

