/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { UtilFns } from '@utils';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';

export interface IGridTooltipParams {
  header?: string,
  value?: string
}

@Component({
  selector: 'prox-grid-tooltip',
  template: `
   <div *ngIf="isPageReady" class="flex">
      <input type="text" #inputField [(ngModel)]="tooltip.value" (keydown.enter)="onOk()" (keydown.escape)="onCancel()" autofocus  />
      <button class="p-button ml-2" (click)="onOk()">Ok</button>
      <button class="p-button ml-2" (click)="onCancel()">Cancel</button>
   </div>
  `
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GridToolTipValueDialog implements OnInit, AfterViewInit {
  @ViewChild("inputField") inputField?: ElementRef;


  defaultParams: Required<IGridTooltipParams> = {
    header: 'Enter something...',
    value: 'test'
  }

  tooltip!: IGridTooltipParams;

  isPageReady = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
  }

  // static async create(dialogService: DialogService, data?: IGridTooltipParams) {
  //   const ref = dialogService.open(this as any, {
  //     closable: false, draggable: true,
      
  //     data: data
  //   });
  //   const r = await firstValueFrom(ref.onClose);
  //   return r;
  // }

  async ngOnInit() {
    this.tooltip = { ...this.defaultParams, ...this.config.data }
    await UtilFns.wait(0);
    this.config.header = this.tooltip.header;

    this.isPageReady = true;
  }

  ngAfterViewInit() {
    setTimeout( () => this.inputField?.nativeElement.focus(), 0);
  }

  onOk() {
    this.ref.close(this.tooltip.value);
    
  }

  onCancel() {
    this.ref.close(null);
  }

}