import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'prox-check-renderer',
  template: `<img width="30" height="30" [src] = "cellImage">`,
  styles: [
  ]
})
export class CheckOnlyRendererComponent implements ICellRendererAngularComp {
  params: any;
  public cellImage = '';
  checkImage = '/assets/proximity/check-sm.png';
  trans = '/assets/proximity/transparent.png';
  agInit(params: never) {
    this.cellImage = this.getValueToDisplay(params) ? this.checkImage : this.trans;
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.value;
  }
}
