// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { Supplier } from './supplier';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class HelpItem extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  /** html with template tokens replaced by values on the server.  Added to metadata via MetadataStoreProvider.patchMetadata() */
  mergedHtml?: string;
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId?: string;
  /** String(50) */
  field?: string;
  /** String */
  html!: string;
  /** String(250) */
  route!: string;
  /** Binary */
  rowVersion!: any;
  /** Guid FK supplier */
  supplierId?: string;
  /** FK accountId */
  account!: Account;
  /** FK supplierId */
  supplier!: Supplier;
}

