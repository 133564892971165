// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { FeatureChoice } from './feature-choice';
import { Product } from './product';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProductFeatureChoice extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK product */
  productId!: string;
  /** Guid key FK featureChoice */
  featureChoiceId!: string;
  /** FK featureChoiceId */
  featureChoice!: FeatureChoice;
  /** FK productId */
  product!: Product;
}

