// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountAddress } from './account-address';
import { Gender } from './gender';
import { JobOrder } from './job-order';
import { ProgramUserGroupExclusion } from './program-user-group-exclusion';
import { ProgramUserGroupMap } from './program-user-group-map';
import { ProximityUser } from './proximity-user';
import { ReturnRequest } from './return-request';
import { ShippingUserGroupMap } from './shipping-user-group-map';
import { GenderEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountUser extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      // new EntityPropertyAnnotation('billingAddress.zipcode', {
      //   validators: [ BaseEntity.zipCodeValidator ]
      // }),
      // new EntityPropertyAnnotation('shippingAddress.zipcode', {
      //   validators: [ BaseEntity.zipCodeValidator ]
      // }),
    ];

    return new EntityTypeAnnotation({
      validators: [  ],
      propertyAnnotations: propAnnotations
    });
  }

  isNewUser() {
    if (this.newHireUntilDate == null) {
      return false;
    } else {
      return (new Date()) < this.newHireUntilDate;
    }
  }

  getStatusParents() {
    return [this.account];
  }

  getStatusId() {
    return this.proximityUser?.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** String */
  apiLink?: string;
  /** Guid FK billingAccountAddress */
  billingAccountAddressId?: string;
  /** DateTime */
  exitDate?: Date;
  /** Enum FK gender */
  genderId!: GenderEnum;
  /** DateTime */
  hireDate?: Date;
  /** DateTime */
  newHireUntilDate?: Date;
  /** String(50) */
  paymentsCustomerId?: string;
  /** DateTime */
  programUserGroupAnniversaryDate!: Date;
  /** Guid FK proximityUser */
  proximityUserId!: string;
  /** Binary */
  rowVersion!: any;
  /** Guid FK shippingAccountAddress */
  shippingAccountAddressId?: string;
  /** Boolean */
  wasPopulatedFromUniformax?: boolean;
  /** FK accountId */
  account!: Account;
  /** FK billingAccountAddressId */
  billingAccountAddress!: AccountAddress;
  /** FK genderId */
  gender!: Gender;
  /** Inv FK accountUserId */
  jobOrders!: JobOrder[];
  /** Inv FK accountUserId */
  programUserGroupExclusions!: ProgramUserGroupExclusion[];
  /** Inv FK accountUserId */
  programUserGroupMaps!: ProgramUserGroupMap[];
  /** FK proximityUserId */
  proximityUser!: ProximityUser;
  /** Inv FK accountUserId */
  returnRequests!: ReturnRequest[];
  /** FK shippingAccountAddressId */
  shippingAccountAddress!: AccountAddress;
  /** Inv FK accountUserId */
  shippingUserGroupMaps!: ShippingUserGroupMap[];
}

