<div *ngIf="isPageReady" class="flex h-full gap-4">
  <div class="flex flex-column h-full w-full">

    <div class="flex gap-3">
      <div *ngIf="events" class="w-6">
        <prox-field label="Notification Event">
          <p-dropdown [options]="events" [(ngModel)]="notificationTemplate.notificationEventId"
            optionLabel="description" optionValue="id" name="event" (onChange)="onEventChange()"></p-dropdown>
        </prox-field>
      </div>
  
      <div *ngIf="types" class="w-4">
        <prox-field label="Notification Type">
          <p-dropdown [options]="types" [(ngModel)]="notificationTemplate.notificationTypeId"
            optionLabel="description" optionValue="id" name="type"></p-dropdown>
        </prox-field>
      </div>

      <div class="w-2">
        <!-- <prox-field label="Description">
          <textarea rows="2" cols="30" pInputTextarea [(ngModel)]="messageTemplate.description" [disabled]="isDisabled()"
            [bzModel]="messageTemplate" name="description"></textarea>
        </prox-field> -->
      </div>
    
    </div>

    <div class="flex flex-grow-1">
      <div class="mt-3 mb-3 flex-grow-1">
        <div class="pl-1"><h6>{{messageType()}} Message</h6></div>
        <prox-html-editor [suggestItems]="contextMenuItems" [(value)]="notificationTemplate.template" [isTextOnly]="isSms()"></prox-html-editor>
      </div>
    </div>

    <div class="flex pt-3 pb-2">
      <button (click)="onOk()" pButton type="button" label="Ok" icon="pi pi-check" class="p-button-raised mr-2"></button>

      <button (click)="onUndo()" [disabled]="!hasChanges()" pButton type="button" label="Undo" icon="pi pi-undo"
        class="p-button-raised mr-2"></button>

      <div class="flex-grow-1"></div>

      <button (click)="onPreview()" [disabled]="!canPreview()" pButton type="button" label="Preview" icon="pi pi-eye"
        class="p-button-raised ml-2"></button>

      <button (click)="onSendNotification()" [disabled]="!canSendTest()" pButton type="button" label="Send Test Notification" icon="pi pi-envelope"
        class="p-button-raised ml-2"></button>
        
<!-- 
      <button *ngIf="isEmail()" (click)="onSendEmail()" [disabled]="!canSendTest()" pButton type="button" label="Send Test Email" icon="pi pi-envelope"
        class="p-button-raised ml-2"></button>

      <button *ngIf="isSms()" (click)="onSendSms()" [disabled]="!canSendTest()" pButton type="button" label="Send Test SMS" icon="pi pi-whatsapp"
        class="p-button-raised ml-2"></button> -->
      
      <!-- <div *ngIf="uow.canShowLog()">
            <p-button icon="pi pi-eye" styleClass="p-button-rounded p-button-text" title="Change Log" (onClick)="onShowLog()"></p-button>
          </div> -->
 
    </div>
  </div>
</div>