// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProximityUser } from './proximity-user';
import { Supplier } from './supplier';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class SupplierAdmin extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get name(): string {
    return this.proximityUser?.name || this.id;
  }

  getStatusParents() {
    return [this.supplier];
  }

  getStatusId() {
    return this.proximityUser?.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** DateTimeOffset */
  initTs!: Date;
  /** Guid FK proximityUser */
  proximityUserId!: string;
  /** Binary */
  rowVersion!: any;
  /** Guid FK supplier */
  supplierId!: string;
  /** FK proximityUserId */
  proximityUser!: ProximityUser;
  /** FK supplierId */
  supplier!: Supplier;
}

