// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountAddress } from './account-address';
import { AccountUser } from './account-user';
import { JobOrder } from './job-order';
import { ReturnRequestDetail } from './return-request-detail';
import { ReturnRequestNote } from './return-request-note';
import { ReturnRequestStatus } from './return-request-status';
import { ReturnRequestStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ReturnRequest extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK accountUser */
  accountUserId?: string;
  /** Int32 */
  displayId!: number;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** DateTimeOffset */
  requestCreatedTs!: Date;
  /** DateTimeOffset */
  requestPlacedTs?: Date;
  /** Enum FK returnRequestStatus */
  returnRequestStatusId!: ReturnRequestStatusEnum;
  /** Binary */
  rowVersion!: any;
  /** Guid FK shippingAccountAddress */
  shippingAccountAddressId?: string;
  /** FK accountUserId */
  accountUser!: AccountUser;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
  /** Inv FK returnRequestId */
  returnRequestDetails!: ReturnRequestDetail[];
  /** Inv FK returnRequestId */
  returnRequestNotes!: ReturnRequestNote[];
  /** FK returnRequestStatusId */
  returnRequestStatus!: ReturnRequestStatus;
  /** FK shippingAccountAddressId */
  shippingAccountAddress!: AccountAddress;
}

