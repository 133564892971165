import { AfterViewInit, Component, ContentChild, Input, Optional } from "@angular/core";
import { NgModel } from "@angular/forms";
import { BreezeValidatorDirective } from "./breeze-validator.directive";
import { Parent } from "./parent";


@Component({
  selector: 'prox-field',
  template: `
    <div class="p-field p-fluid">
      <div class="flex justify-content-between" >
        <label class="pt-1"> {{label}} {{ isRequired ? '*' : ''}} </label>
        <span class="pb-1"><ng-content select="[extendedLabel]"></ng-content></span>
      </div>
      <div>
        <ng-content></ng-content>
        <small id="username-help" *ngIf="hasErrors" class="p-error">{{message}}</small>
<!--         <p-message *ngIf="hasErrors" severity="error" [text]="message"></p-message> -->
    </div>`,
})
export class ProxFieldComponent implements AfterViewInit {
  @Input() label = '';
  @ContentChild(NgModel) ngm?: NgModel;
  @ContentChild(BreezeValidatorDirective) bzModel?: BreezeValidatorDirective;
  private _required?: boolean;

  constructor( @Optional() public parent?: Parent ) { 
    
  }

  ngAfterViewInit() {
    if (!this.ngm) return;
    const ngm = this.ngm;
    setTimeout( () => this.checkReadOnly(ngm), 1)
  }

  checkReadOnly(ngm: NgModel) {
    if (this.parent?.isReadOnly?.()) {
      ngm.control.disable( )
    }
  }

  get hasErrors(): boolean {
    return (this.bzModel?.hasValidationErrors || !!(this.ngm && this.ngm.invalid && this.ngm.dirty));
  }

  get isRequired(): boolean {
    if (this._required === undefined && this.bzModel) {
      this._required = this.bzModel.isRequired;
    }
    return !!this._required;
  }

  get message(): string {
    const ers = this.ngm && this.ngm.errors;
    if (!ers) { return ''; }

    const keys = Object.keys(ers);
    const arr: string[] = [];
    // Errors from BreezeValidatorDirective are like: { maxLength: "'Name' must be a string with 100 characters or less"}
    // Html form validation errors are like: { maxlength: {"requiredLength":10,"actualLength":26}} but we shouldn't have those
    for (const key of keys) {
      const val = ers[key];
      if (typeof (val) === 'string') {
        arr.push(val);
      } else {
        arr.push(key + ': ' + JSON.stringify(val));
      }
    }
    return arr.join('; ');
  }

}