import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'prox-editor',
  templateUrl: './editor.component.html',
})
export class EditorComponent {
  constructor(private toast: ToastrService) {}
  initialValue = '';

  init = () => {
    return  { mergetags_list: [
      {
        title: 'Account Merge Tags',
        menu: [
          {
            value: 'accountName',
            title: 'Account: Name',
          },
          {
            value: 'activeStatus',
            title: 'Account: Status',
          },
          {
            value: 'accountBillingName',
            title: 'Account: Billing Name',
          },
          {
            value: 'accountBillingLine1',
            title: 'Account: Billing Line 1',
          },
          {
            value: 'accountBillingLine2',
            title: 'Account: Billing Line 2',
          },
          {
            value: 'accountBillingLine3',
            title: 'Account: Billing Line 3',
          },
          {
            value: 'accountBillingCity',
            title: 'Account: Billing City',
          },
          {
            value: 'accountBillingState',
            title: 'Account: Billing State',
          },
          {
            value: 'accountBillingZipCode',
            title: 'Account: Billing Zip Code',
          },
          {
            value: 'accountBillingAttn',
            title: 'Account: Billing Attention',
          },
          {
            value: 'accountTelephoneNumber',
            title: 'Account: Telephone Number',
          },
          {
            value: 'accountTelephoneExtension',
            title: 'Account: Telephone Extension',
          },
        ],
      },
      {
        title: "Primary Administrator Merge Tags",
        menu: [
          {
            value: 'adminSalutation',
            title: 'Admin: Salutation',
          },
          {
            value: 'adminFirstName',
            title: 'Admin: First Name',
          },
          {
            value: 'adminLastName',
            title: 'Admin: Last Name',
          },
          {
            value: 'adminTitle',
            title: 'Admin: Title',
          },
          {
            value: 'adminStatus',
            title: 'Admin: Status',
          },
          {
            value: 'adminEmail',
            title: 'Admin: Email',
          },
          {
            value: 'adminCellNumber',
            title: 'Admin: Cell Number',
          },
          {
            value: 'adminAcceptSms',
            title: 'Admin: Accept Sms?',
          },
          {
            value: 'adminTelephone',
            title: 'Admin: Telephone Number',
          },
          {
            value: 'adminTelephoneExtension',
            title: 'Admin: Telephone Extension',
          }
        ]
      }
    ],
    toolbar: 
    'undo redo | blocks | bold italic strikethrough backcolor | mergetags | link image | align bullist numlist | code',
    plugins: 
    'anchor autolink link powerpaste a11ychecker linkchecker lists image code advcode mergetags wordcount table advtable editimage autosave advlist media mediaembed searchreplace visualblocks template'
  }};
}
