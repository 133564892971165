import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { AccountImage, SupplierImage } from '@models';
import { ProxImage, mapObject } from '@utils';
import { Guid } from 'guid-typescript';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(
    private http: HttpClient
  ) { }

  upload<TResponse>(url: string, formData: FormData): Promise<TResponse> {
    return firstValueFrom(this.http.post<TResponse>(url, formData));
  }

  uploadSupplierImages(supplierId: string, images: ProxImage[]): Promise<SupplierImage[]> {
    const url = environment.supplierUrl + '/UploadSupplierImage';
    const promises: Promise<SupplierImage>[] = [];
    for (const image of images) {
      const formData = new FormData();
      formData.append('docFile', image.file as File);
      if (image.width) formData.append('width', image.width.toString());
      if (image.height) formData.append('height', image.height.toString());
      formData.append('isThumbnail', image.isThumbnail.toString());
      formData.append('supplierId', supplierId);
      formData.append('supplierImageId', Guid.create().toString());
      promises.push(this.upload<SupplierImage>(url, formData).then(si => mapObject(si)));
    }
    return Promise.all(promises);
  }

  uploadAccountImages(supplierId: string, images: ProxImage[]): Promise<AccountImage[]> {
    const url = environment.accountUrl + '/UploadAccountImage';
    const promises: Promise<AccountImage>[] = [];
    for (const image of images) {
      const formData = new FormData();
      formData.append('docFile', image.file as File);
      if (image.width) formData.append('width', image.width.toString());
      if (image.height) formData.append('height', image.height.toString());
      formData.append('isThumbnail', image.isThumbnail.toString());
      formData.append('accountId', supplierId);
      formData.append('accountImageId', Guid.create().toString());

      promises.push(this.upload<AccountImage>(url, formData).then(si => mapObject(si)));
    }
    return Promise.all(promises);
  }

  download(url: string, fileName: string): Promise<any> {
    return firstValueFrom(this.http.get(url, { responseType: 'blob' }))
        .then((blob) => this.downloadInternal(blob, fileName));
  }

  private downloadInternal(blob: Blob, filename: string): void {
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_self';

    if (filename) {
      link.download = filename;
    }

    link.click();

    // needs timeout because Firefox removes the object before the actual download
    window.setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 100);
  }  
}
