// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ApprovalTreeAdminGroup } from './approval-tree-admin-group';
import { JobOrder } from './job-order';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ApprovalTreeAdminGroupLog extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Decimal */
  amt!: number;
  /** Guid FK approvalTreeAdminGroup */
  approvalTreeAdminGroupId!: string;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** DateTimeOffset */
  logTs!: Date;
  /** FK approvalTreeAdminGroupId */
  approvalTreeAdminGroup!: ApprovalTreeAdminGroup;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
}

