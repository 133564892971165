import { ICellRendererAngularComp, ITooltipAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageFns, ProxImage } from '@utils';

export interface BlobRendererComponentProps {
  getImage: (item: any) => ProxImage;
  /** Whether the component is being used as a CellRenderer or a TooltipRenderer */
  isTooltip: boolean;
}

@Component({
  selector: 'prox-blob-renderer',
  template: `<div [style]="divStyle"><img [style]="imgStyle" *ngIf="image" [src]="image.safeUrl" /></div>`,
})
export class BlobRendererComponent implements ICellRendererAngularComp, ITooltipAngularComp, AfterViewInit {
  image!: ProxImage;
  isTooltip!: boolean;
  divStyle = '';
  imgStyle = '';

  constructor(private readonly sanitizer: DomSanitizer) {}

  agInit(params: any): void {
    this.image = params.getImage(params.node.data);
    this.isTooltip = params.isTooltip || false;
    if (this.isTooltip) {
      this.divStyle = "position: absolute; top: -30px; left: 10px;";
    } else {
      this.imgStyle = "height: 25px; position: relative; top: 4px";
    }
  }

  ngAfterViewInit() {
    if (this.image) {
      ImageFns.convertFileData([this.image]);
      ImageFns.generateUrls([this.image], this.sanitizer);
    }
  }

  // Instead, clear all urls when the grid component is destroyed
  // Otherwise, there are problems with images disappearing
  // ngOnDestroy(): void {
  //   ImageFns.clearUrls([this.image]);
  // }

  refresh(params?: object): boolean {
    return true;
  }
}
