import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-file-select-dialog',
  template: `
  <div>
    <div class="text-l font-bold mb-3">{{ message}}</div>    
    <div>
      <form #form>
        <div class="p-field">
          <label for="doc_file"></label>
          <input type="file" id="doc_file" #doc_file name="doc_file" (change)="setDocFile($event)"/>
        </div>
      </form>
    </div>
    <div class="text-right mt-3">
      <button [disabled]="!docFile" (click)="ok()" pButton type="button" icon="pi pi-image" label="Ok"
            class="p-button-raised p-button-primary mr-3"></button>
      <button (click)="cancel()" pButton type="button" icon="pi pi-times" label="Cancel"
            class="p-button-raised p-button-primary"></button>
    </div>
  </div>
  `,
})
export class FileSelectDialogComponent {

  static config = { header: 'Choose File', width: '500px', contentStyle: { height: '140px' }};
  message?: string;
  docFile?: File;

  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef) {
    this.message = dialogConfig.data.message;
  }

  setDocFile(event: Event) {
    if (event.target && (event.target as HTMLInputElement).files) {
      this.docFile = (event.target as HTMLInputElement).files?.[0];
    }
  }

  ok() {
    this.ref.close(this.docFile);
  }

  cancel() {
    this.ref.close();
  }

}
