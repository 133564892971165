<div class="flex h-screen">
  <div class="w-full lg:w-4 h-full text-center px-6 py-6 flex flex-column justify-content-between">
      <a routerLink="/"><img [src]="'assets/layout/images/logo-dark.svg'" class="h-4rem mt-4" alt="diamond-layout" /></a>

      <form name="loginForm" #form="ngForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-column align-items-center gap-4">
            <div class="mb-3">
                <h2>Login to your account</h2>
                <p>Forgot password? <a class="text-primary hover:underline cursor-pointer font-medium">Click here</a> to reset.</p>
            </div>
            <input pInputText id="username" placeholder="Username" [(ngModel)]="username" required class="w-20rem" name="username"/>
            <p-password [(ngModel)]="password" [toggleMask]="true" required inputStyleClass="w-20rem" name="password" placeholder="Password" [feedback]="false"></p-password>
            <button pButton label="CONTINUE" type="submit" class="w-20rem" [disabled]="form.invalid" [loading]="working"></button>

            <p class="text-red-500">{{error}}</p>
            <p class="text-green-500">{{success}}</p>
        </div>
      </form>

      <p class="text-color-secondary font-semibold">A problem? <a class="text-primary hover:underline cursor-pointer font-medium">Click here</a> and let us help you.</p>
  </div>
  <div class="w-8 hidden lg:flex flex-column justify-content-between align-items-center px-6 py-6 bg-cover bg-norepeat" style="background-image: url('assets/layout/images/bg-login.jpg')">
      <div class="mt-auto mb-auto">
          <span class="block text-white text-7xl font-semibold">Access to your <br/>Proximity <br/>Account</span>
          <span class="block text-white text-3xl mt-4">Lorem ipsum dolor sit amet, consectetur<br/> adipiscing elit. Donec posuere velit nec enim<br/> sodales, nec placerat erat tincidunt. </span>
      </div>
      <div class="flex align-items-center gap-5">
          <span class="text-white font-semibold">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
          <i class="pi pi-github text-3xl p-1 surface-overlay border-circle cursor-pointer"></i>
          <i class="pi pi-twitter text-3xl p-1 surface-overlay border-circle cursor-pointer"></i>
      </div>
  </div>
</div>
