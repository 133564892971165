import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { ErrorLogger } from '../data/error-logger';
import { AuthService } from '@core';
 
@Injectable()
export class MyInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private logger: ErrorLogger,
    private authService: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add withCredentials to ensure that auth cookies are sent on CORS requests
    const update = {
        withCredentials: true,
    } as any;
    // add header for impersonation
    const user = this.authService.getUser();
    if (user?.actingAsUser) {
        update.headers = request.headers.append('X-Acting-Id', user.actingAsUser.id);
    }
    request = request.clone(update);
    return next.handle(request)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMsg = '';
                if (error.error instanceof ErrorEvent) {
                    errorMsg = `Error: ${error.error.message}`;
                } 
                if (error instanceof HttpErrorResponse) {
                    let errorObj = (typeof error.error === 'string' && error.error[0] === '{') ? JSON.parse(error.error) : error.error || error;
                    errorMsg = errorObj.message || errorObj.Message;
                    if (error.status === 401) { // unauthorized
                        if (request.url.includes("/Login/")) {
                            // don't redirect in this case; AuthService will do it
                            return throwError(() => error);
                        } 
                        this.toastr.error(error.error || 'You need to log in', "Authorization Error");
                        let redirectUrl = this.router.routerState.snapshot.root.queryParams['redirectUrl'] || this.router.url;
                        this.router.navigate(['/login'], { queryParams: { redirectUrl }});
                    } else if (error.status === 0) {
                        this.logger.error('Server Communication Error', error);
                        this.toastr.error(`The Proximity Application Server appears to be off-line`, "Server Communication Error");
                    } else if (error.status === 500) {
                        this.logger.error('This is a server side error', error);
                        this.toastr.error("There was a unexpected server error.  Error code: " + error.status, "Http Error");
                        return throwError(() => error); 
                    } else {
                        // should be handled by component, or global error handler
                        return throwError(() => error);
                    }
                }
                this.logger.error(errorMsg + '\n  ' + error.message);
                return throwError(() => error);
            })
        )
    }
}