import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ImageFns, ProxImage } from '@utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface FileViewDialogData {
  title: string;
  files?: File[];
  images?: ProxImage[];
  delete?: (image: ProxImage) => void;
}

@Component({
  selector: 'prox-file-view-dialog',
  template: `
  <div class="h-full flex flex-column" style="max-height: 90vh">
    <div class="flex-grow-1">
      <div *ngIf="type === 'pdf' || type === 'txt'">
        <iframe [src]="safeUrl" height="620px" width="852px" ></iframe>
      </div>
      <div *ngIf="type === 'img'">
        <img [src]="safeUrl" style="height: 620px; width: 852px; object-fit: contain" />
      </div>
      <div *ngIf="type === 'other'" style="height: 100%; display: flex; justify-content: center; align-items: center;">
        <span>No viewer available for this file type</span>
      </div>
      <div *ngIf="!type" style="height: 100%; display: flex; justify-content: center; align-items: center;">
        <span>No file loaded</span>
      </div>
    </div>
    <div class="flex text-right mt-3 gap-3">
      <button pButton (click)="prev()" [disabled]="!hasPrev()" type="button" icon="pi pi-chevron-left" label="Prev" class="p-button-raised"></button>
      <button pButton (click)="next()" [disabled]="!hasNext()" type="button" icon="pi pi-chevron-right" label="Next" class="p-button-raised"></button>
      <div class="flex-grow-1 text-center pt-2"><span class="font-bold">{{fileName}}</span></div>
      <a pButton [href]="safeUrl" target="_self" [download]="fileName" class="p-button-raised">Download</a>
      <button *ngIf="data.delete" pButton (click)="delete()" type="button" icon="pi pi-trash" label="Delete" class="p-button-raised"></button>
      <button pButton (click)="close()" type="button" icon="pi pi-times" label="Close" class="p-button-raised"></button>
    </div>
  </div>
  `,
})
export class FileViewDialogComponent implements OnInit, OnDestroy {

  static config = { header: 'View Files', width: '900px', contentStyle: { height: '700px' }};
  blobUrl?: string;
  safeUrl?: SafeResourceUrl;
  fileName?: string;
  index = 0;
  type: 'img'|'pdf'|'txt'|'other'|null = null;
  images: ProxImage[] = [];
  data: FileViewDialogData;

  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef, private readonly sanitizer: DomSanitizer) {
    this.data = dialogConfig.data;
  }

  ngOnInit(): void {
    this.index = 0;
    const images = ImageFns.convertFileData(this.data.images)
    this.images = images.concat(ImageFns.convertToImages(this.data.files));
    this.showFile();
  }

  ngOnDestroy(): void {
    this.clearUrl();
  }

  hasNext(): boolean {
    return this.index < this.images.length - 1;
  }

  hasPrev(): boolean {
    return this.index > 0 && this.images.length > 0;
  }

  delete(): void {
    const image = this.images[this.index];
    if (image && this.data.delete) {
      this.data.delete(image);
      this.images.splice(this.index, 1);
      if (this.index > 0) {
        this.index--;
      }
      this.showFile();
    }
  }

  next(): void {
    if (this.hasNext()) {
      this.index++;
      this.showFile();
    }
  }

  prev(): void {
    if (this.hasPrev()) {
      this.index--;
      this.showFile();
    }
  }

  private showFile() {
    this.clearUrl();
    const file = this.images[this.index]?.file;
    if (file) {
      this.setFileType(file);
      this.blobUrl = URL.createObjectURL(file);
      this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.blobUrl);
      this.fileName = file.name;
    }
  }

  private setFileType(file: File) {
    if (file.type.endsWith('pdf')) {
      this.type = 'pdf';
    }
    else if (file.type.startsWith('image')) {
      this.type = 'img';
    }
    else if (file.type == 'text/plain' || file.type == 'text/html') {
      this.type = 'txt';
    }
    else {
      this.type = 'other';
    }
  }

  private clearUrl() {
    this.type = null;
    this.fileName = undefined;
    if (this.blobUrl) {
      this.safeUrl = undefined;
      URL.revokeObjectURL(this.blobUrl);
    }
  }

  close() {
    this.clearUrl();
    this.ref.close();
  }

}
