// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { JobOrder } from './job-order';
import { JobOrderDetailAddon } from './job-order-detail-addon';
import { ProductTypeConfigProduct } from './product-type-config-product';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
import { JobOrderDetailPriceInfo, ProductFns } from '@core';
import * as _ from 'lodash'
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrderDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  __joDetailPriceInfo?: JobOrderDetailPriceInfo;
  getPriceInfo(forceRefresh: boolean = false) {
    if (this.__joDetailPriceInfo==null || forceRefresh) {
      this.__joDetailPriceInfo = ProductFns.getJobOrderDetailPriceInfo(this);
    }
    return this.__joDetailPriceInfo;
  }

  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    
    const propAnnotations = [
      new EntityPropertyAnnotation('qty', {
        validators: [ BaseEntity.greaterThanZeroValidator(true) ]
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [ ],
      propertyAnnotations: propAnnotations
    });
  }

  getShippedQty() {

  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** Guid FK productTypeConfigProduct */
  productTypeConfigProductId!: string;
  /** Int32 */
  qty!: number;
  /** Binary */
  rowVersion!: any;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
  /** Inv FK jobOrderDetailId */
  jobOrderDetailAddons!: JobOrderDetailAddon[];
  /** FK productTypeConfigProductId */
  productTypeConfigProduct!: ProductTypeConfigProduct;
}

