import { ITooltipComp } from "@ag-grid-community/core";

export class AgValidationErrorTooltip implements ITooltipComp {
    eGui!: HTMLElement;
    init(params) {
      const eGui = (this.eGui = document.createElement('div'));
      eGui.classList.add('validation-tooltip');

      // const data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
      const valueToDisplay = params.value;

      eGui.innerHTML = `
      <div class="ag-val-error-tooltip-title">Validation Error:</div>
      <div class="ag-val-error-tooltip-body">${valueToDisplay}</div>
      `;
      
      // eGui.style['background-color'] = 'yellow';

    }
  
    getGui() {
      return this.eGui;
    }
  }