import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AccountUnitOfWork } from '@data';
import { environment } from '@env';
import { NotificationEvent, NotificationEventEnum, NotificationQueryEnum, NotificationTemplate, NotificationType, NotificationTypeEnum } from '@models';
import { UtilFns } from '@utils';
import { Entity, EntityError } from 'breeze-client';
import { sanitize } from 'dompurify';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { BaseService } from '../../services/base.service';
import { TemplatesService } from '../../services/templates.service';
import { TemplatePreviewDialogComponent, TemplatePreviewDialogParams } from './template-preview-dialog.component';

export interface NotificationTemplateDialogParams {
  notificationTemplate: NotificationTemplate;
  allTemplates: NotificationTemplate[];
  contextMenuItems: MenuItem[];
}

@Component({
  selector: 'prox-notification-template-dialog',
  templateUrl: './notification-template-dialog.component.html',
})
export class NotificationTemplateDialogComponent implements OnInit {
  UtilFns = UtilFns;
  notificationTemplate!: NotificationTemplate;
  allTemplates?: NotificationTemplate[];
  //actionItem!: ActionItem;
  events: NotificationEvent[] = [];
  allTypes: NotificationType[] = [];
  types: NotificationType[] = [];
  selectedEvent?: NotificationEvent;
  contextMenuItems: MenuItem[] = [];
  isPageReady = false;
  isBeingAdded!: boolean;
  emailAddress = '';
  phone = '';

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig<NotificationTemplateDialogParams>,
    private uow: AccountUnitOfWork,
    private baseService: BaseService,
    private dialogService: DialogService,
    private httpClient: HttpClient,
    private templatesService: TemplatesService
  ) {}

  public static async openDialog(dialogService: DialogService, data?: NotificationTemplateDialogParams, config?: DynamicDialogConfig<NotificationTemplateDialogParams>) {
    const ref = dialogService.open(NotificationTemplateDialogComponent, {
      closable: false, 
      draggable: true,
      width: '800px',
      height: '80vh',
      ...config,
      data: data,
    });
    return firstValueFrom(ref.onClose);
  }

  async ngOnInit() {
    const params = this.config.data;
    UtilFns.assertNonNull(params);

    this.notificationTemplate = params.notificationTemplate;
    this.contextMenuItems = params.contextMenuItems;
    this.allTemplates = params.allTemplates;

    this.events = await this.uow.getAllOrQuery(NotificationEvent);
    this.allTypes = await this.uow.getAllOrQuery(NotificationType);

    this.onEventChange();
    this.isPageReady = true;
  }

  isDisabled() {
    return this.notificationTemplate.accountId == null;
  }

  onOk() {
    this.notificationTemplate.template = sanitize(this.notificationTemplate.template);
    const errEntities = this.uow.validateEntities([this.notificationTemplate]);
    if (errEntities.length > 0) {
      this.handleBreezeValidationErrors(errEntities);
      return;
    }
    this.ref.close(true);
  }

  onEventChange() {
    // Filter query types so they are unique for the event
    if (!this.notificationTemplate.notificationEvent) { return; }
    const templatesWithSameEvent = this.allTemplates?.filter(x => x.id != this.notificationTemplate.id &&
      x.notificationEventId == this.notificationTemplate.notificationEventId && x.accountId == this.notificationTemplate.accountId);
    const existingTypes = templatesWithSameEvent?.map(x => x.notificationTypeId);
    this.types = this.allTypes.filter(x => !existingTypes?.includes(x.id));

    // Get the template variables for the event's query
    const queryId = this.notificationTemplate.notificationEvent.notificationQueryId;
    this.templatesService.getNotificationMenuItems(queryId).then(x => { this.contextMenuItems = x; });
  }

  handleBreezeValidationErrors(errEntities: Entity[]) {
    this.baseService.dialogService.validationDialog(this, errEntities, this.navigateToValidationError.bind(this), true);
  }

  navigateToValidationError(ee: EntityError, event: MouseEvent) {
    this.baseService.toastr.warning('Navigation to error not implemented', 'Not yet implemented');
  }

  onUndo() {
    this.notificationTemplate.entityAspect.rejectChanges();
    this.ref.close(false);
  }

  hasChanges() {
    return this.notificationTemplate.entityAspect.entityState.isAddedModifiedOrDeleted();
  }

  canPreview() {
    // const user = this.baseService.authService.getUser();
    // return user?.accountId && this.messageTemplate.template && this.messageTemplate.template.includes('data-type="mention"');
    return this.notificationTemplate.template && this.notificationTemplate.template.includes('{{');
  }

  isEmail() {
    return this.notificationTemplate.notificationTypeId == NotificationTypeEnum.Email;
  }

  isSms() {
    return this.notificationTemplate.notificationTypeId == NotificationTypeEnum.SMS;
  }

  messageType() {
    return NotificationTypeEnum[this.notificationTemplate.notificationTypeId];
  }

  canSendTest() {
    return !!(this.notificationTemplate.notificationEventId && this.notificationTemplate.notificationTypeId !== null && this.notificationTemplate.template);
  }

  private async promptForContext(queryId: NotificationQueryEnum) {
    let json = JSON.stringify({ accountId: this.notificationTemplate.accountId, jobOrderId: "ED50DDBB-108A-4225-B025-0001BF6BE80A" });
    if (queryId == NotificationQueryEnum.JobOrder) {
      json = await this.baseService.dialogService.textpromptDialog("Notification Context", { pretext: "Enter the notification context in JSON format", text: json});
    }
    return json;
  }

  async onPreview() {
    const queryId = this.notificationTemplate.notificationEvent.notificationQueryId;
    const json = await this.promptForContext(queryId);

    const params: TemplatePreviewDialogParams = {
      template: this.notificationTemplate.template, 
      isText: this.isSms(),
      queryId: queryId,
      context: json
    };
    TemplatePreviewDialogComponent.openDialog(this.dialogService, params)
  }

  async onSendNotification() {
    if (this.notificationTemplate.notificationTypeId == NotificationTypeEnum.Email) {
      this.emailAddress = await this.baseService.dialogService.textpromptDialog("Enter email address", { text: this.emailAddress ?? '', posttext: 'Sending takes up to 60 seconds.  Check junk folder.' });
    } else if (this.notificationTemplate.notificationTypeId == NotificationTypeEnum.SMS) {
      this.phone = await this.baseService.dialogService.textpromptDialog("Enter phone number", { text: this.phone ?? '', pretext: 'Phone number starting with +1' });
    }
    const queryId = this.notificationTemplate.notificationEvent.notificationQueryId;
    const json = await this.promptForContext(queryId);
    if (!json) { return; }
    // const json = "{}";

    const user = {
      firstName: "Prox",
      lastName: "Tester",
      email: this.emailAddress ?? "",
      cellNumber: this.phone,
      userName: "proxtester"
    }
    const body = {
      notificationEventId: this.notificationTemplate.notificationEventId,
      notificationTypeId: this.notificationTemplate.notificationTypeId,
      user,
      template: this.notificationTemplate.template,
      contextJson: json
    }
    const url = environment.accountUrl + '/SendNotification';

    try {
      const response = await firstValueFrom(this.httpClient.post<{Value: string}>(url, body));

      await this.baseService.dialogService.okDialog('Notification Status', response.Value);
    } catch (ex) {
      await this.baseService.dialogService.okDialog('Notification Status', 'Error sending: ' + UtilFns.getErrorMessage(ex));
    }

  }

  async onSendEmail() {
    this.emailAddress = await this.baseService.dialogService.textpromptDialog("Enter email address", { text: this.emailAddress, posttext: 'Sending takes up to 60 seconds.  Check junk folder.' });
    if (this.emailAddress) {
      const url = environment.accountUrl + '/SendEmail';
      const body = { 
        to: this.emailAddress,
        subject: 'Test - ' + NotificationEventEnum[this.notificationTemplate.notificationEventId],
        htmlBody: this.notificationTemplate.template
      };
      try {
        // send email
        const messageId = await firstValueFrom(this.httpClient.post<{Value: string}>(url, body));

        // check email status
        const pollUrl = environment.accountUrl + '/PollEmailStatus';
        const pollBody = { value: messageId.Value };
        const status = await firstValueFrom(this.httpClient.post<{Value: string}>(pollUrl, pollBody));

        await this.baseService.dialogService.okDialog('Email Status', status.Value);
      } catch (ex) {
        await this.baseService.dialogService.okDialog('Email Status', 'Unable to send email: ' + UtilFns.getErrorMessage(ex));
      }
  
    }
  }

  async onSendSms() {
    this.phone = await this.baseService.dialogService.textpromptDialog("Enter phone number", { text: this.phone, pretext: 'Phone number starting with +1' });
    if (this.phone) {
      const url = environment.accountUrl + '/SendSms';
      const body = { 
        to: this.phone,
        htmlBody: this.notificationTemplate.template
      };
      try {
        // send sms
        const response = await firstValueFrom(this.httpClient.post<{Value: string}>(url, body));

        await this.baseService.dialogService.okDialog('SMS Status', response.Value);
      } catch (ex) {
        await this.baseService.dialogService.okDialog('SMS Status', 'Unable to send sms: ' + UtilFns.getErrorMessage(ex));
      }
  
    }
  }
}
