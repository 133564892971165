// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProductTypeConfig } from './product-type-config';
import { Program } from './program';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramProductTypeConfig extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK program */
  programId!: string;
  /** Guid key FK productTypeConfig */
  productTypeConfigId!: string;
  /** DateTime */
  cancellationDate?: Date;
  /** FK productTypeConfigId */
  productTypeConfig!: ProductTypeConfig;
  /** FK programId */
  program!: Program;
}

