import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UtilFns } from '@utils';
import { Router } from '@angular/router';

@Component({
  selector: 'prox-change-password',
  templateUrl: './change-password.component.html',
  styles: [`
        i {
            opacity: 0.6;
            transition-duration: .12s;
            color: #2196F3;
        }
        i:hover { opacity: 1; }
    `]
})
export class ChangePasswordComponent {
  error = '';
  success = '';
  working = false;
  oldpassword = '';
  newpassword = '';
  conpassword = '';

  constructor(private authService: AuthService, private router: Router) { }

  onSubmit() {
    this.error = '';
    if (this.conpassword != this.newpassword) {
      this.error = "Passwords do not match";
      return;
    }
    if (!this.working) {
      this.working = true;
      this.authService.changePassword(this.oldpassword, this.newpassword).then(() => {
        this.error = '';
        this.success = 'Password has been changed';
        this.working = false;
        setTimeout(() => this.router.navigateByUrl('/'), 3000);

      }, error => {
        if (error.status === 0) {
          this.error = 'Unable to contact server';
        } else if (error.status === 401) {
          this.error = error.error || 'Incorrect password';
        } else {
          //   this._logger.log(error, 'error');
          this.error = UtilFns.getErrorMessage(error);
        }

        this.working = false;

      });

    }
  }
}
