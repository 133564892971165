// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { BudgetProductTypeTag } from './budget-product-type-tag';
import { JobOrder } from './job-order';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class JobOrderBudgetLog extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Decimal */
  amt!: number;
  /** Guid FK budgetProductTypeTag */
  budgetProductTypeTagId?: string;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** DateTimeOffset */
  logTs!: Date;
  /** FK budgetProductTypeTagId */
  budgetProductTypeTag!: BudgetProductTypeTag;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
}

