import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Subject, takeUntil } from 'rxjs';

/** Add auto-focus and keystroke filtering to PrimeNg Menu */
@Directive({
  selector: '[proxKeyMenu]',
})
export class KeyMenuDirective implements OnInit, OnDestroy {
  @Input() model?: MenuItem[];

  /** Use `.pipe(takeUntil(this.destroy$))` on all observables */
  destroy$ = new Subject<boolean>();

  /** Filter menu items when keystrokes are typed */
  filter = '';

  constructor(private menu: Menu) {}

  ngOnInit(): void {
    this.menu.onShow.pipe(takeUntil(this.destroy$)).subscribe((p) => { this.clearFilter(); this.setFocus() });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /** Accumulate keystrokes and use them to match the menu items */
  @HostListener('window:keydown', ['$event']) 
  onKeydown(event: KeyboardEvent) {
    const key = event.key;
    if (!key) { return; }
    const len = this.filter.length;
    if (key == 'Backspace' && this.filter.length) {
      this.filter = this.filter.substring(0, this.filter.length - 1);
    } else if (key == 'Delete' && this.filter.length) {
      this.filter = '';
    } else if (key == 'Escape') {
      this.menu.hide();
      return;
    } else if (key.length == 1) {
      this.filter += key.toLowerCase();
    }
    if (this.filter.length !== len) {
      // redraw the menu if filter has changed
      this.filterItems();
    }
  }

  /** Clear filter and set all menu items visible */
  private clearFilter() {
    this.filter = '';
    if (!this.model) { return };
    for (const item of this.model) {
      item.visible = true;
      item.styleClass = undefined;
    }
  }

  /** Set `visible` flag on menu items based on current filter */
  private filterItems() {
    if (!this.model) { return };
    const filter = this.filter;

    // if filter would hide all items, ignore it and remove last character
    const someVisible = this.model.some(item => !!item.id && item.id.toLowerCase().includes(filter));
    if (!someVisible) {
      this.filter = this.filter.length ? this.filter.substring(0, this.filter.length - 1) : '';
      return;
    }

    let refocus = false;
    for (const item of this.model) {
      const vis = !!item.id && item.id.toLowerCase().includes(filter);
      if (vis == !item.visible) {
        item.visible = vis;
        // need to set this due to bug in PrimeNg Menu - it doesn't skip invisible items, just li.p-disabled
        item.styleClass = vis ? undefined : 'p-disabled';
        refocus = true;
      }
    }
    if (refocus) {
      this.setFocus();
    }
  }

  /** Set focus on first visible menu item */
  private setFocus() {
    setTimeout(() => {
      const firstVisible = this.model?.findIndex(x => x.visible !== false) || 0;
      const firstChild = this.menu.el.nativeElement.firstElementChild;
      if (firstChild) {
        const menuItems = firstChild.getElementsByClassName('p-menuitem-link');
        menuItems[firstVisible] && menuItems[firstVisible].focus();
      }
    }, 1);  

  }
}
