/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, OnInit } from '@angular/core';

import { Frequency, Options, RRule } from 'rrule';
import { GridOptions } from '@ag-grid-community/core';

import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';

import { AgFns } from '../components';
import { DateFns } from '@utils';
import { RRuleFns } from './rrule-fns';

export interface EventScheduleDialogParams  {
  rruleString: string;

}

@Component({
  templateUrl: './event-schedule-dialog.html',
  //  encapsulation: ViewEncapsulation.None
})
export class EventScheduleDialog implements OnInit { 
  RRuleFns = RRuleFns;
  rrule!: RRule;
  rruleOptions!: Partial<Options>;
  rruleString?: string; // RFC string
  rruleText!: string; // natural language string
  rruleDates?: object[] | null;
  rruleGridOptions : GridOptions = {};
  
  hasEnd?: boolean;
  RRule = RRule;
  isMonthDay = true;
  isPageReady = false;

  

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<EventScheduleDialogParams>,
  ) {
  }

  public static async openDialog(dialogService: DialogService, data: EventScheduleDialogParams, config?: DynamicDialogConfig<EventScheduleDialogParams> ) {
    const ref = dialogService.open(EventScheduleDialog, {
      closable: false, 
      draggable: true,
      // baseZIndex: 10000,
      // contentStyle: { overflow: 'auto' },
      ...config,
      data: data,
      width: '60vh',
      height: '65vh',
    });
    const r = await firstValueFrom(ref.onClose);
    return r as string;
  }

  ngOnInit() {
    this.rruleString = this.config.data?.rruleString ?? '';

    if (this.rruleString.length == 0) {
      this.rrule = new RRule({ freq: RRule.DAILY, interval: 1 });
    } else {
      this.rrule = RRule.fromString(this.rruleString);
    }
    this.rruleOptions = this.rrule.origOptions;

    if (this.rruleString.length == 0) {
      this.isMonthDay = true;
    } else {
      this.isMonthDay = this.rruleOptions.bymonthday != null;
    }

    

    this.prepare();
  }

  get byweekday(): number { 
    return this.rruleOptions?.byweekday?.[0] ?? RRule.MO;
  }
  set byweekday(val: number) {
    if (this.rruleOptions?.byweekday?.[0] != null) {
      this.rruleOptions.byweekday[0] = val;
    } else {
      this.rruleOptions.byweekday = [val];
    }
  }

  prepare() {
    this.rruleGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onRruleGridReady,
      getRowId: this.getRruleRowId,
    });
    
    this.scheduleChanged();
    this.isPageReady = true;
  }

  freqChanged(value: Frequency) {
    this.rruleOptions.byweekday = value === RRule.WEEKLY ? [ RRule.MO ] : [];
    if (value === RRule.MONTHLY) {
      this.isMonthDayChanged(null);
    }
    this.scheduleChanged();
  }

  getRruleRowId(params) {
    return params.data?.date;
  }

  isMonthDayChanged(value: any) {
    if (this.isMonthDay) {
      this.rruleOptions.bysetpos = null;
      this.rruleOptions.byweekday = [ ];
      this.rruleOptions.bymonthday = 1;
    } else {
      this.rruleOptions.bysetpos = 1;
      this.rruleOptions.byweekday = [ RRule.MO ];
      this.rruleOptions.bymonthday = null;
    }
    this.scheduleChanged();
  }

  scheduleChanged() {
    const options = this.rruleOptions;
    if (options.interval == null) {
      options.interval = 1;
    }
    if (options.freq === RRule.MONTHLY) {
      options.bymonthday = options.bysetpos == null ? (options.bymonthday || 1) : null;
      options.bysetpos = options.bymonthday == null ? (options.bysetpos || 1) : null;
      if (options.bysetpos != null && (options.byweekday == null || (options.byweekday as []).length === 0)) {
        options.byweekday = [ RRule.MO ];
      }
    } else if (options.freq === RRule.WEEKLY) {
      options.bymonthday = null;
      options.bysetpos = null;
      if (options.byweekday?.[0] == null) {
        options.byweekday = [RRule.MO];
      }
    } else if (options.freq === RRule.DAILY) {
      options.byweekday = [];
      options.bymonthday = null;
      options.bysetpos = null;
    }
    // otherwise dtStart includes the timepart of the date.
    options.dtstart = options.dtstart || this.normalizeToUTC(this.getCurrentDate());
    const rrule = new RRule( options);
    this.rrule = rrule;

    this.rruleDates = null;
    if (rrule != null) {
      try {
        this.rruleText = RRuleFns.toText(rrule);
      } catch (e) {
        this.rruleGridOptions.api?.setRowData([]);
        return;
      }
      this.hasEnd = options.count != null || options.until != null;
      // rrule.all returns an infinite list if no count or untilDate
      const dates = rrule.all( (dt, i) => this.hasEnd ? true : i < 10);
      this.rruleDates = dates.map( dt => {
        // needed because rrule works best with UTC dates so we need to get back to current date
        dt = new Date( dt.getTime() + Math.abs(dt.getTimezoneOffset()*60000) );
        return {
          date: DateFns.fmtDate4(dt),
          dow:  RRuleFns.jsWeekDays[dt.getDay()],
          rawDt: dt
        };
      });
      this.rruleGridOptions.api?.setRowData([]);
      this.rruleGridOptions.api?.setRowData(this.rruleDates);
    } else {
      this.rruleGridOptions.api?.setRowData([]);
    }
  }

  forceUTCToTZDate(dt) {
    return new Date( dt.getTime() + Math.abs(dt.getTimezoneOffset()*60000) );
  }

  normalizeToUTC(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0 , 0));
  }
  
  forceUTCNoOffset(date: Date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0 , 0));
  }

  
  getCurrentDate() {
    const dt = new Date();
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  }

  onRruleGridReady() {
    AgFns.attachGridState(this.rruleGridOptions);
    // this.rruleGridOptions.getRowId = (params) => { return params.data.rawDt?.getTime().toString(); }
    const colDefs = [
      { headerName: 'Date', field: 'date' },
      { headerName: 'Day of Week', field: 'dow' },
      // { headerName: 'Raw Date', field: 'rawDt' },
    ];
    AgFns.updateColDefs(colDefs);
    AgFns.initGrid(this.rruleGridOptions, colDefs);
    
    AgFns.sizeColumnsToFit(this.rruleGridOptions);
    
  }

  async ok() {
    this.ref.close(this.rrule.toString());
  }

  cancel() {
    this.ref.close(null);
  }


}
