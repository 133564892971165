// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { InvoiceDetail } from './invoice-detail';
import { JobOrder } from './job-order';
import { Shipment } from './shipment';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Invoice extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  isReturnOrRepairable(): boolean {
    let ok = false;
    this.invoiceDetails.forEach (p => {
      if (p.isReturnOrRepairable()) {
        ok = true;
      }
    });
    return ok;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String */
  apiLink!: string;
  /** DateTime */
  dueDate!: Date;
  /** Decimal */
  freightAmt!: number;
  /** DateTime */
  invoiceDate!: Date;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** Decimal */
  orderHandlingAmt!: number;
  /** DateTimeOffset */
  shipTs!: Date;
  /** Guid FK shipment */
  shipmentId?: string;
  /** Decimal */
  subtotalAmt!: number;
  /** Decimal */
  taxAmt!: number;
  /** Decimal */
  totalAmt!: number;
  /** Inv FK invoiceId */
  invoiceDetails!: InvoiceDetail[];
  /** FK jobOrderId */
  jobOrder!: JobOrder;
  /** FK shipmentId */
  shipment!: Shipment;
}

