// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { InvoiceDetail } from './invoice-detail';
import { ReturnCreditType } from './return-credit-type';
import { ReturnReason } from './return-reason';
import { ReturnRequest } from './return-request';
import { ReturnRequestDetailStatus } from './return-request-detail-status';
import { ReturnCreditTypeEnum } from './enums';
import { ReturnRequestDetailStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ReturnRequestDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Decimal */
  actualUnitCreditMemoAmt?: number;
  /** Decimal */
  defaultUnitCreditMemoAmt?: number;
  /** Guid FK invoiceDetail */
  invoiceDetailId!: string;
  /** Int32 */
  qty!: number;
  /** Enum FK returnCreditType */
  returnCreditTypeId?: ReturnCreditTypeEnum;
  /** String */
  returnExplanation?: string;
  /** Guid FK returnReason */
  returnReasonId!: string;
  /** Enum FK returnRequestDetailStatus */
  returnRequestDetailStatusId!: ReturnRequestDetailStatusEnum;
  /** Guid FK returnRequest */
  returnRequestId!: string;
  /** String */
  returnStatusExplanation?: string;
  /** DateTimeOffset */
  returnStatusTs!: Date;
  /** Binary */
  rowVersion!: any;
  /** FK invoiceDetailId */
  invoiceDetail!: InvoiceDetail;
  /** FK returnCreditTypeId */
  returnCreditType!: ReturnCreditType;
  /** FK returnReasonId */
  returnReason!: ReturnReason;
  /** FK returnRequestId */
  returnRequest!: ReturnRequest;
  /** FK returnRequestDetailStatusId */
  returnRequestDetailStatus!: ReturnRequestDetailStatus;
}

