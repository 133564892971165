<div>
  <div class="flex flex-column h-full gap-4 text-center">
    <div *ngIf="imps.canImpersonateAccountUser()">
      <button pButton type="button" label="Impersonate Account User" class="p-button-raised"
      (click)="imps.impersonateAccountUser()"></button>
    </div>
    <div *ngIf="imps.canImpersonateAccountAdmin()">
      <button pButton type="button" label="Impersonate Account Admin" class="p-button-raised"
      (click)="imps.impersonateAccountAdmin()"></button>
    </div>
    <div *ngIf="imps.canImpersonateSupplierAdmin()">
      <button pButton type="button" label="Impersonate Supplier Admin" class="p-button-raised"
      (click)="imps.impersonateSupplierAdmin()"></button>
    </div>
    <div>
      <button pButton type="button" label="Stop Impersonation" class="p-button-raised"
      (click)="imps.stopImpersonation()"></button>
    </div>
  </div>
</div>