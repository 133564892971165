import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Editor, Range } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

@Component({
  selector: 'prox-html-editor',
  templateUrl: './html-editor.component.html',
})
export class HtmlEditorComponent implements OnInit {
  /** Template tag items for the {{ menu */
  @Input() suggestItems?: MenuItem[];
  /** Editor content */
  @Input() value?: string;
  /** For SMS templates.  Output is still HTML, but formatting menus are removed except for token menu */
  @Input() isTextOnly = false;
  @Output() valueChange = new EventEmitter<string>();
  @ViewChild('templateMenu') templateMenu!: Menu;

  /** Toggle html code view */
  isSource = false;
  /** Toggle toolbar buttons and editable status */
  isDisabled = false;

  editor = new Editor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Mention.configure({
        renderHTML({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}}}`;
        },
        suggestion: {
          char: '{{',
          render: () => ({
            onStart: (props: SuggestionProps<MenuItem>) => {
              if (this.suggestItems) {
                // update MenuItems with commands. Different from setSuggestMenu() because
                // these commands replace the typed '{{' with the template variable
                this.suggestItems.forEach(
                  (x) =>
                    (x.command = (x) => {
                      if (x?.item != null) this.insertVariableFromMenu(x.item, props.range);
                    })
                );
                this.templateMenu.show({ currentTarget: props.decorationNode });
              }
            },
          }),
        } as SuggestionOptions<MenuItem>,
      }),
    ],
    editorProps: {
      attributes: {
        class: 'p-2 focus:border-none outline-none h-full',
        spellcheck: 'false',
      },
    },
  });

  headingItems = [
    {
      label: '<h1>Heading 1</h1>',
      id: 'Heading 1',
      command: () => this.setHeading(1),
      escape: false,
      visible: false,
    },
    {
      label: '<h2>Heading 2</h2>',
      id: 'Heading 2',
      command: () => this.setHeading(2),
      escape: false,
    },
    {
      label: '<h3>Heading 3</h3>',
      id: 'Heading 3',
      command: () => this.setHeading(3),
      escape: false,
    },
    {
      label: '<h4>Heading 4</h4>',
      id: 'Heading 4',
      command: () => this.setHeading(4),
      escape: false,
    },
    {
      label: '<h5>Heading 5</h5>',
      id: 'Heading 5',
      command: () => this.setHeading(5),
      escape: false,
    },
    {
      label: '<h6>Heading 6</h6>',
      id: 'Heading 6',
      command: () => this.setHeading(6),
      escape: false,
    },
    {
      label: '<p>Paragraph</p>',
      id: 'Paragraph',
      command: () => this.editor.chain().focus().clearNodes().run(),
      escape: false,
    },
  ];

  alignItems = [
    {
      label: '<i class="fa fa-align-left mr-2"></i>Left',
      id: 'Left',
      command: () => this.setAlign('left'),
      escape: false,
    },
    {
      label: '<i class="fa fa-align-center mr-2"></i>Center',
      id: 'Center',
      command: () => this.setAlign('center'),
      escape: false,
    },
    {
      label: '<i class="fa fa-align-right mr-2"></i>Right',
      id: 'Right',
      command: () => this.setAlign('right'),
      escape: false,
    },
    {
      label: '<i class="fa fa-align-justify mr-2"></i>Justify',
      id: 'Justify',
      command: () => this.setAlign('justify'),
      escape: false,
    },
  ];

  ngOnInit() {
    // this.setSuggestMenu();
    this.editor.on('transaction', () => {
      this.valueChange.emit(this.value);
    });
    this.editor.on('update', () => {
      this.valueChange.emit(this.value);
    });
  }

  /** Update MenuItems with commands */
  setSuggestMenu() {
    if (this.suggestItems) {
      this.suggestItems.forEach(
        (x) =>
          (x.command = (x) => {
            if (x?.item != null) this.insertVariableFromMenu(x.item);
          })
      );
    }
  }

  public setEnable(enable: boolean) {
    this.editor.setEditable(enable);
    this.isDisabled = !enable;
  }

  get foc() {
    return this.editor.chain().focus();
  }

  toggleSource() {
    this.isSource = !this.isSource;
  }

  private setHeading(level: any) {
    this.editor.chain().focus().toggleHeading({ level: level }).run();
  }

  private setAlign(where: string) {
    this.editor.chain().focus().setTextAlign(where).run();
  }

  /** Insert template variable at current cursor position */
  private insertVariableFromMenu(x: MenuItem, range?: Range) {
    let content: any;
    let id = x.automationId;

    if (id.endsWith('+')) {
      // loop
      id = id.substring(0, id.length - 1);
      content = [{ type: 'mention', attrs: { id, label: '#'+id }}, { type: 'hardBreak'}, { type: 'mention', attrs: { id, label: '/'+id }}];
    } else if (id.endsWith('?')) {
      // if block
      id = id.substring(0, id.length - 1);
      content = [{ type: 'mention', attrs: { id, label: '#if '+id }}, {type: 'hardBreak'}, { type: 'mention', attrs: { id, label: '/if' }}];
    } else {
      content = { type: 'mention', attrs: { id }};
    }

    if (range) {
      this.editor.chain().focus().insertContentAt(range, content).run();
    } else {
      this.editor.chain().focus().insertContent(content).run();
    }
  }
}
