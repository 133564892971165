// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { BudgetProductTypeTagMap } from './budget-product-type-tag-map';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class BudgetProductTypeTag extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** String(60) */
  name!: string;
  /** Binary */
  rowVersion!: any;
  /** FK accountId */
  account!: Account;
  /** Inv FK budgetProductTypeTagId */
  budgetProductTypeTagMaps!: BudgetProductTypeTagMap[];
}

