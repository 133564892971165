// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountAddress } from './account-address';
import { AccountAdminGroup } from './account-admin-group';
import { AccountBlanketPurchaseOrder } from './account-blanket-purchase-order';
import { ActiveStatus } from './active-status';
import { AllowanceProrationRule } from './allowance-proration-rule';
import { ApprovalTree } from './approval-tree';
import { JobOrder } from './job-order';
import { Manifest } from './manifest';
import { NextProgramAllowanceRule } from './next-program-allowance-rule';
import { OrderPaidByType } from './order-paid-by-type';
import { ProgramAccountIssuanceMap } from './program-account-issuance-map';
import { ProgramAllowance } from './program-allowance';
import { ProgramApplicabilityType } from './program-applicability-type';
import { ProgramBudget } from './program-budget';
import { ProgramIssuance } from './program-issuance';
import { ProgramProductCategoryTag } from './program-product-category-tag';
import { ProgramProductTypeConfig } from './program-product-type-config';
import { ProgramRapidTemplate } from './program-rapid-template';
import { ProgramStartDateType } from './program-start-date-type';
import { ProgramUserGroupExclusion } from './program-user-group-exclusion';
import { ProgramView } from './program-view';
import { ProgramViolationRule } from './program-violation-rule';
import { ShipFrequency } from './ship-frequency';
import { ActiveStatusEnum } from './enums';
import { AllowanceProrationRuleEnum } from './enums';
import { ShipFrequencyEnum } from './enums';
import { NextProgramAllowanceRuleEnum } from './enums';
import { OrderPaidByTypeEnum } from './enums';
import { ProgramViolationRuleEnum } from './enums';
import { ProgramApplicabilityTypeEnum } from './enums';
import { ProgramStartDateTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
import { DateFns, UtilFns } from '@utils';
import { AccountUser } from './account-user';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Program extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // static getEntityTypeAnnotation(): EntityTypeAnnotation {
    
  //   const propAnnotations = [
  //     // TODO: need a validator that requires a start and end date based on the programStartDateType
  //   ];

  //   return new EntityTypeAnnotation({
  //     validators: [  ],
  //     propertyAnnotations: propAnnotations
  //   });
        
  // }

  hasStatusChanged() {
    const ea = this.entityAspect;
    if (!ea) return false
    if (ea.entityState.isUnchanged() ) return false;
    return this.activeStatusId !=  this.getOriginalStatusId();
  }

  getOriginalStatusId() {
    return this.entityAspect?.originalValues[this.activeStatusId] ?? this.activeStatusId;
  }

  getExpirationDate() {
    // TODO: need to handle AnniversaryDates - i.e. when start date is null.  User anniversary date needs to be passed into this function.
    if (this.startDate !=null) {
      return this.endDate;
    } else {
      return null;
    }
  }

  isExpired() {
    const expirationDate = this.getExpirationDate();
    if (expirationDate == null) {
      // TODO: this shouldn't happen once we start handling Anniversary date calcs in getExpirationDate
      return false;
    } else {
      return (new Date()) >= expirationDate;
    }

  }

  isUserApplicable(au: AccountUser) {
    if (this.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.Both) {
      return true;
    } else if (this.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.NewUser) {
      return au.isNewUser();
    } else if (this.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.ExistingUser) {
      return !au.isNewUser();
    } else {
      UtilFns.assert(false, 'Invalid Program Applicability Type');
    }
  }

  getStatusParents() {
    return [this.account];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK accountAdminGroup */
  accountAdminGroupId?: string;
  /** Guid FK accountBlanketPurchaseOrder */
  accountBlanketPurchaseOrderId?: string;
  /** Guid FK account */
  accountId!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** Enum FK allowanceProgramViolationRule */
  allowanceProgramViolationRuleId!: ProgramViolationRuleEnum;
  /** Enum FK allowanceProrationRule */
  allowanceProrationRuleId!: AllowanceProrationRuleEnum;
  /** Int32 */
  anniversaryTermDays?: number;
  /** Guid FK approvalTree */
  approvalTreeId?: string;
  /** Guid FK billingAccountAddress */
  billingAccountAddressId?: string;
  /** Boolean */
  canShipHome!: boolean;
  /** DateTime */
  cancellationDate?: Date;
  /** String */
  description?: string;
  /** Boolean */
  doAllOrdersRequireApproval!: boolean;
  /** Boolean */
  doesAccountPayFreight!: boolean;
  /** DateTime */
  endDate?: Date;
  /** Boolean */
  isPurchaseOrderRequired!: boolean;
  /** Enum FK issuanceProgramViolationRule */
  issuanceProgramViolationRuleId!: ProgramViolationRuleEnum;
  /** Int32 */
  leadTimeToShipNumDays!: number;
  /** String */
  name!: string;
  /** Enum FK nextProgramAllowanceRule */
  nextProgramAllowanceRuleId!: NextProgramAllowanceRuleEnum;
  /** Enum FK orderPaidByType */
  orderPaidByTypeId!: OrderPaidByTypeEnum;
  /** Enum FK programApplicabilityType */
  programApplicabilityTypeId!: ProgramApplicabilityTypeEnum;
  /** Enum FK programStartDateType */
  programStartDateTypeId!: ProgramStartDateTypeEnum;
  /** Binary */
  rowVersion!: any;
  /** Enum FK shipFrequency */
  shipFrequencyId!: ShipFrequencyEnum;
  /** DateTime */
  startDate?: Date;
  /** FK accountId */
  account!: Account;
  /** FK accountAdminGroupId */
  accountAdminGroup!: AccountAdminGroup;
  /** FK accountBlanketPurchaseOrderId */
  accountBlanketPurchaseOrder!: AccountBlanketPurchaseOrder;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK allowanceProgramViolationRuleId */
  allowanceProgramViolationRule!: ProgramViolationRule;
  /** FK allowanceProrationRuleId */
  allowanceProrationRule!: AllowanceProrationRule;
  /** FK approvalTreeId */
  approvalTree!: ApprovalTree;
  /** FK billingAccountAddressId */
  billingAccountAddress!: AccountAddress;
  /** FK issuanceProgramViolationRuleId */
  issuanceProgramViolationRule!: ProgramViolationRule;
  /** Inv FK programId */
  jobOrders!: JobOrder[];
  /** Inv FK programId */
  manifests!: Manifest[];
  /** FK nextProgramAllowanceRuleId */
  nextProgramAllowanceRule!: NextProgramAllowanceRule;
  /** FK orderPaidByTypeId */
  orderPaidByType!: OrderPaidByType;
  /** Inv FK programId */
  programAccountIssuanceMaps!: ProgramAccountIssuanceMap[];
  /** Inv FK programId */
  programAllowances!: ProgramAllowance[];
  /** FK programApplicabilityTypeId */
  programApplicabilityType!: ProgramApplicabilityType;
  /** Inv FK programId */
  programBudgets!: ProgramBudget[];
  /** Inv FK programId */
  programIssuances!: ProgramIssuance[];
  /** Inv FK programId */
  programProductCategoryTags!: ProgramProductCategoryTag[];
  /** Inv FK programId */
  programProductTypeConfigs!: ProgramProductTypeConfig[];
  /** Inv FK programId */
  programRapidTemplates!: ProgramRapidTemplate[];
  /** FK programStartDateTypeId */
  programStartDateType!: ProgramStartDateType;
  /** Inv FK programId */
  programUserGroupExclusions!: ProgramUserGroupExclusion[];
  /** Inv FK programId */
  programViews!: ProgramView[];
  /** FK shipFrequencyId */
  shipFrequency!: ShipFrequency;
}

