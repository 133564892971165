// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountImport extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** DateTimeOffset */
  completeTs?: Date;
  /** String */
  jsonData!: string;
  /** String(60) */
  name!: string;
  /** DateTimeOffset */
  postTs?: Date;
  /** String(60) */
  result?: string;
  /** Binary */
  rowVersion!: any;
  /** DateTimeOffset */
  runTs?: Date;
  /** FK accountId */
  account!: Account;
}

