// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { BudgetProductTypeTag } from './budget-product-type-tag';
import { Program } from './program';

/// <code-import> Place custom imports between <code-import> tags
import { EntityTypeAnnotation } from '../entity-type-annotations';
import { BudgetFns } from './budget-fns';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramBudget extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    return BudgetFns.getBudgetEntityTypeAnnotation();
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** Decimal */
  budgetAmt!: number;
  /** Guid FK budgetProductTypeTag */
  budgetProductTypeTagId?: string;
  /** Guid FK program */
  programId!: string;
  /** Binary */
  rowVersion!: any;
  /** Decimal */
  warnIfWithinRate?: number;
  /** FK accountId */
  account!: Account;
  /** FK budgetProductTypeTagId */
  budgetProductTypeTag!: BudgetProductTypeTag;
  /** FK programId */
  program!: Program;
}

