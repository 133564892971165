// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { FeatureChoice } from './feature-choice';
import { PricedProductType } from './priced-product-type';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class PricedProductTypeFeatureChoice extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      new EntityPropertyAnnotation('price', {
        validators: [ BaseEntity.greaterThanZeroValidator() ]
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [  ],
      propertyAnnotations: propAnnotations
    });

  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK pricedProductType */
  pricedProductTypeId!: string;
  /** Guid key FK featureChoice */
  featureChoiceId!: string;
  /** Decimal */
  price!: number;
  /** Binary */
  rowVersion!: any;
  /** FK featureChoiceId */
  featureChoice!: FeatureChoice;
  /** FK pricedProductTypeId */
  pricedProductType!: PricedProductType;
}

