import { Component, OnDestroy } from '@angular/core';
import { TitleService } from '@services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-title',
  templateUrl: './app.title.component.html',
})
export class AppTitleComponent implements OnDestroy{
  subscription!: Subscription;
  public title = "Proximity System";

  constructor(public titleService: TitleService) {
    this.subscription = titleService.titleHandler.subscribe(response => {
      this.title = response;
    })
   }

   ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
   }
}
