import { Account, AccountUser } from "@models";

export class ValidateAccountUserDto {
    accountUser!: AccountUser;
    email!: string;
    apiLink!: string;
    emailExists: boolean | null = null;
    apiLinkExists: boolean | null = null;
    validationMessage: string = "";

    constructor(accountUser: AccountUser){
        this.accountUser = accountUser;
    }
}