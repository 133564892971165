// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProductType } from './product-type';
import { ProductTypeTag } from './product-type-tag';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProductTypeTagMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK productType */
  productTypeId!: string;
  /** Guid key FK productTypeTag */
  productTypeTagId!: string;
  /** FK productTypeId */
  productType!: ProductType;
  /** FK productTypeTagId */
  productTypeTag!: ProductTypeTag;
}

