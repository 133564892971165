import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { Entity } from 'breeze-client';

@Component({
  selector: 'prox-entity-state-renderer',
  template: `
    <p-tag *ngIf="isEntityAdded()" severity="success" value="New"></p-tag>
    <p-tag *ngIf="isEntityModified()" severity="info" value="Mod"></p-tag>
    <p-tag *ngIf="isEntityDeleted()" severity="info" value="Del"></p-tag>
    `
})
export class EntityStateRendererComponent implements ICellRendererAngularComp {

  params;
  
  agInit(params: object): void {
    // this.params = { ...this.defaultParams, ...params };
    this.params = params;

  }

  refresh(params?: object): boolean {
    return true;
  }

  getEntity() {
    const item = this.params.node.data;
    if (this.params.getEntityFn != null) {
      return this.params.getEntityFn(item) as Entity;
    } else {
      return item as Entity;
    }
  }

  isEntityAdded() {
    const entity = this.getEntity();
    if (!entity) return;
    return entity.entityAspect.entityState.isAdded();
  }
  isEntityModified() {
    const entity = this.getEntity();
    if (!entity) return;
    return entity.entityAspect.entityState.isModified();
  }
  isEntityDeleted() {
    const entity = this.getEntity();
    if (!entity) return;
    return entity.entityAspect.entityState.isDeleted();
  }
}