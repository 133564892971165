import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface TextPromptDialogData {
  pretext?: string;
  posttext?: string;
  text: string;
}

@Component({
  selector: 'prox-textprompt-dialog',
  template: `
  <div>
    <div class="text-l mb-3">{{ data.pretext }}</div>
    <input type="text" pInputText [(ngModel)]="value" class="w-full" />
    <div class="text-l mb-3">{{ data.posttext}}</div>
    <div class="text-right mt-3">
      <button (click)="ok()" pButton type="button" icon="pi pi-check" label="Ok"
        class="p-button-raised p-button-primary"></button>
      <button (click)="cancel()" pButton type="button" icon="pi pi-times" label="Cancel"
        class="p-button-raised p-button-primary ml-2"></button>
    </div>

  </div>
  `,
})
export class TextPromptDialogComponent {

  data: TextPromptDialogData;
  value: string;

  constructor(dialogConfig: DynamicDialogConfig<TextPromptDialogData>, private ref: DynamicDialogRef) {
    this.data = dialogConfig.data || { text: '' };
    this.value = dialogConfig.data?.text || '';
  }

  ok() {
    this.ref.close(this.value);
  }
  cancel() {
    this.ref.close();
  }

}
