/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EntityPropertyAnnotation,
  EntityTypeAnnotation,
} from './entity-type-annotations'
import {
  Entity,
  EntityAspect,
  EntityType,
  core,
  Validator,
} from 'breeze-client';

export class BaseEntity implements Entity {
  entityAspect!: EntityAspect;
  entityType!: EntityType;
  // snapshot?: {};

  toString() {
    // this is rendered into the bz-model of the html, and used to set focus on fields
    return this.entityType.shortName + ': ' + this['id'];
  }

  get entityTypeName(): string {
    return this.entityType['_typeDisplayName'] || this.entityType.shortName;
  }

  get entityDisplayName(): string {
    return this.entityTypeName + (this['name'] ? ': ' + this['name'] : '');
  }

  // Snapshotting - removed for now and replaced with setUnchanged -> setAdded pattern for add forms. - may use later for other purposes.

  // TODO: consider ComplexProperties
  // takeSnapshot() {
  //   // the setTimeout is here to allow time for a form to set any default values for lookups which we need in the snapshot.
  //   setTimeout(() => {
  //     this.snapshot = {};
  //     this.entityType.getProperties().filter(p => p.isDataProperty).forEach(dp => {
  //       this.snapshot![dp.name] = this[dp.name]
  //     });
  //   }, 0);
  //   return this;
  // }

  // hasChangedSinceSnapshot() {
  //   if (this.snapshot == null) {
  //     return true;
  //   }
  //   if (this.entityAspect.entityState.isDetached()) {
  //     return false;
  //   }
  //   const dps = this.entityType.getProperties().filter(p => p.isDataProperty).filter(dp => {
  //     return this.snapshot![dp.name] !== this[dp.name]
  //   });
  //   return dps.length > 0;
  // }

  /** return an annotation containing 'required' propertyAnnotations for the given property names */
  static getRequiredTypeAnnotation(propNames: string[]): EntityTypeAnnotation {
    return new EntityTypeAnnotation({
      validators: [],
      propertyAnnotations: BaseEntity.getRequiredPropertyAnnotations(propNames),
    });
  }

  /** return annotations containing a 'required' validator for each of the given property names */
  static getRequiredPropertyAnnotations(
    propNames: string[]
  ): EntityPropertyAnnotation[] {
    const required = Validator.required();
    const anno = propNames.map((name) => {
      return new EntityPropertyAnnotation(name, {
        displayName: name,
        validators: [required],
      });
    });
    return anno;
  }

  // tslint:disable-next-line:member-ordering
  static notNegativeValidator = new Validator(
    'notNegative',
    (value: any, context) => {
      if (!Number.isFinite(value) || value < 0) {
        return false;
      }
      return true;
    },
    { messageTemplate: '%displayName% must be greater or equal than zero' }
  );

  static dateRangeValidator(startDate: Date | null, endDate: Date | null, allowNull: boolean = false) {
    let template = '';
    if (startDate) {
      if (endDate) {
        template = `%displayName% must be between ${startDate.toLocaleDateString()} and ${endDate.toLocaleDateString()}`;
      } else {
        template = `%displayName% must be greater than or equal to ${startDate.toLocaleDateString()}`;
      }
    } else {
      template = `%displayName% must be less than or equal to ${endDate?.toLocaleDateString()}`;
    }
    template = template + (allowNull ? ' or empty.' : '.');
    return new Validator(
      'dateRange',
      (value: any, context) => {
        if (allowNull && value == null) {
          return true;
        }
        const isDate = value instanceof Date && !isNaN(value.valueOf());
        if (!isDate)   return false;
        if (startDate) {
          if (value.getTime() < startDate.getTime()) {
            return false;
          }
        }
        if (endDate) {
          if (value.getTime() > endDate.getTime()) {
            return false;
          }
        }
        return true;
      },
      { messageTemplate: template }
    );
  };

  // tslint:disable-next-line:member-ordering
  static greaterThanZeroValidator(allowNull: boolean = false) {
    return new Validator(
      'greaterThanZero',
      (value: any, context) => {
        if (allowNull && value == null) {
          return true;
        }
        if (!Number.isFinite(value) || value <= 0) {
          return false;
        }
        return true;
      },
      { messageTemplate: '%displayName% must be greater than zero' + (allowNull ? ' or empty.' : '.') }
    );
  };

  static createLessThanValidator(maxVal: number) {
    return new Validator(
      'lessThan:' + maxVal.toString(),
      (value: any, context: any) => {
        if (!Number.isFinite(value) || value > context.maxVal) {
          return false;
        }
        return true;
      },
      { messageTemplate: '%displayName% must <= %maxVal%', maxVal: maxVal }
    );
  }

  static zipCodeValidator = Validator.makeRegExpValidator(
    "zipVal",
    /^\d{5}([\-]\d{4})?$/,
    "The %displayName% '%value%' is not a valid U.S. zipcode");


  getValidationErrorsAsHtml() {
    const ea = this.entityAspect;
    if (ea == null) {
      return;
    }
    this.entityAspect.validateEntity();
    const errors = ea.getValidationErrors();
    if (errors.length === 0) {
      return;
    }
    const msg = errors.map((e) => e.errorMessage).join('<br>');
    return msg;
  }

  getErrorFor(prop: string, delim: string = '. '): string {
    if (prop && prop.length) {
      return this.entityAspect
        .getValidationErrors(prop)
        .map((ve) => ve.errorMessage)
        .join(delim);
    } else {
      return this.entityAspect
        .getValidationErrors()
        .map((ve) => ve.errorMessage)
        .join(delim);
    }
  }

  getEntityDisplayName() {
    return (
      this.entityType.shortName +
      ' (' +
      this[this.entityType.keyProperties[0].name] +
      ')'
    );
    // return this.entityType.shortName + ' - '
    // + this.entityType.keyProperties[0].name + ': '
    // + this[this.entityType.keyProperties[0].name];
  }

  /** return an object containing the data properties of the entity, for making a clone. */
  getConfig() {
    const keyname = this.entityType.keyProperties[0].name;
    const jobj = core.toJSONSafe(this, function (prop, val) {
      if (
        prop === keyname ||
        !val ||
        !!val.entityAspect ||
        Array.isArray(val)
      ) {
        return;
      }
      if (prop === 'updateDate' || prop === 'updatingUserName') {
        return;
      }
      return core.toJSONSafeReplacer(prop, val);
    });
    return jobj;
  }
}
