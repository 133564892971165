import { Component, Input, OnInit } from '@angular/core';
import { GridOptions } from '@ag-grid-community/core';
import * as _ from 'lodash';
import { UserPrefsService } from '../../services/user-prefs.service';


@Component({
  selector: 'prox-ag-grid',
  templateUrl: './ag-grid-wrapper.component.html'
})
export class AgGridWrapperComponent implements OnInit {
  @Input() gridOptions: GridOptions = {};
  @Input() rowData?: unknown[];
  @Input() hasFooter = true;
  @Input() hasColSizeButtons = true;
  @Input() style?: string;
  @Input() rowClassRules? = {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(public userPrefsService: UserPrefsService) {
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  ngOnInit() {

  }

  hasFilters() {
    const fm = this.gridOptions?.api?.getFilterModel();
    return !_.isEmpty(fm);
  }

  // // type guard
  // isColDef(toBeDetermined: ColDef | ColGroupDef): toBeDetermined is ColDef {
  //   return ((toBeDetermined as ColGroupDef).children) ? false : true;
  // }

  clearAllFilters() {
    this.gridOptions?.api?.setFilterModel(null);
    this.gridOptions?.api?.deselectAll();
  }


}
