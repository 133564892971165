import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { stringify } from 'flatted';

@Component({
  //providers: [DialogService, MessageService],
  template: `
    {{ message }}
    <div class="flex justify-content-end mt-4">
      <div class="flex mr-2">
        <button (click)="onProceed()" pButton type="button" label="Proceed"
          icon="pi pi-check" class="p-button-raised"></button>
      </div>

      <div class="flex pr-2">
        <button (click)="onCancel()" pButton type="button" icon='pi pi-times' label="Cancel"
          class="p-button-raised">
        </button>
      </div>
    </div>
  ` 
})
export class StatusExpFormComponent {
  message: string = '';
  data?: {
    parent: any | null,
  };

  constructor( 
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig)
  {
    this.data = config.data;
    this.message = config.data['status'];
  }

  async onProceed(){
    const parent = this.data?.parent;
    if (parent && 'proceedDialog' in parent) {
      await parent.proceedDialog();
    }
    this.ref.close();
  }

  onCancel(){
    const parent = this.data?.parent;
    if (parent && 'cancelDialog' in parent) {
      parent.cancelDialog();
    }
    this.ref.close();
  }
}
