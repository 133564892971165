<div>
  <image-cropper style="height: 660px; width: 860px;"
  [imageFile]="cropInput"
  [maintainAspectRatio]="false" 
  [aspectRatio]="4 / 3" 
  [canvasRotation]="canvasRotation"
  [transform]="transform"
  output="blob" 
  format="jpeg"
  (imageCropped)="imageCropped($event)"></image-cropper>

  <div class="flex mt-2">
    <div class="flex-grow-1">
      <button pButton (click)="rotateLeft()" type="button" icon="pi pi-replay" class="p-button-rounded mr-2" title="Rotate Left"></button>
      <button pButton (click)="rotateRight()" type="button" icon="pi pi-refresh" class="p-button-rounded mr-2" title="Rotate Right"></button>
      <button pButton (click)="flipHorizontal()" type="button" icon="pi pi-arrows-h" class="p-button-rounded mr-2" title="Flip Horizontal"></button>
      <button pButton (click)="flipVertical()" type="button" icon="pi pi-arrows-v" class="p-button-rounded mr-2" title="Filp Vertical"></button>
    </div>
    <div>
      <button pButton (click)="cropOk()" type="button" class="p-button-rounded mr-2" label="Ok"></button>
      <button pButton (click)="cropCancel()" type="button" class="p-button-rounded mr-2" label="Cancel"></button>
    </div>
  </div>
</div>