// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { ApprovalTreeAdminGroup } from './approval-tree-admin-group';
import { ApprovalTreeUserGroup } from './approval-tree-user-group';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ApprovalTree extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** String(250) */
  description!: string;
  /** String(60) */
  name!: string;
  /** Binary */
  rowVersion!: any;
  /** FK accountId */
  account!: Account;
  /** Inv FK approvalTreeId */
  approvalTreeAdminGroups!: ApprovalTreeAdminGroup[];
  /** Inv FK approvalTreeId */
  approvalTreeUserGroups!: ApprovalTreeUserGroup[];
}

