import { Component } from '@angular/core';
import { EntityFns } from '@data';
import { Entity, EntityError } from 'breeze-client';
import * as _ from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'prox-validation-dialog',
  template: `
  <div class="h-full flex flex-column">
    <div class="text-l font-bold mb-3">{{ message}}</div>
    <div class="flex-grow-1" style="overflow: auto">
      <ul *ngIf="!!entityErrors && !data.showEntityTypes">
        <li class="flex" class="my-2" *ngFor="let e of entityErrors">
            <a (click)="onClick(e, $event)">{{ e.errorMessage }}</a>
        </li>
      </ul>
      <ul *ngIf="!!entityErrors && data.showEntityTypes">
        <li class="my-2" *ngFor="let e of entityErrors">
          <div class="flex">
            <span class="w-4">{{ e.entity['entityDisplayName'] }}</span>
            <a class="w-8" (click)="onClick(e, $event)">{{ e.errorMessage }}</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="text-right mt-3">
      <button (click)="revalidate()" pButton type="button" icon="pi pi-refresh" label="Revalidate"
            class="p-button-raised mr-2"></button>
      <button (click)="close()" pButton type="button" icon="pi pi-times" label="Close"
            class="p-button-raised"></button>
    </div>
  </div>
  `,
})
export class ValidationDialogComponent {

  message?: string;
  entityErrors?: EntityError[];
  data: {
    parent: any | null,
    errEntities: Entity[],
    callback: (ee: EntityError, event: MouseEvent) => void,
    showEntityTypes: boolean,

  };
  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef) {
    this.data = dialogConfig.data;
    this.entityErrors = EntityFns.makeEntityErrors(this.data.errEntities);
    this.message = "Please correct the following validation errors.  Click on each error to go to the correct part of the form."
  }

  onClick(ee: EntityError, event: MouseEvent) {
    this.data.callback(ee, event);
  }

  async revalidate() {
    const parent = this.data.parent;
    let errEntities: Entity[];
    if (parent && 'validateEntities' in parent) {
      errEntities = await parent.validateEntities();
    } else {
      errEntities = this.data.errEntities.filter(e => !e.entityAspect.validateEntity());
    }
    if (errEntities.length == 0) {
      this.close();
    }
    this.entityErrors = EntityFns.makeEntityErrors(errEntities);
  }

  close() {
    this.ref.close();
  }

}
