import { Injectable, Type } from '@angular/core';
import { UnitOfWork } from '@data';
import { Entity, EntityError } from 'breeze-client';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import {
  ChangeLogDialogComponent,
  TextPromptDialogComponent,
  TextPromptDialogData,
  ValidationDialogComponent,
} from '../components';
import {
  EntityFinderDialog,
  EntityFinderParams,
} from '../components/base/entity-finder.dialog';
import { ErrorDialogComponent } from '../components/error/error-dialog.component';
import {
  HelpDialogComponent,
  HelpDialogData,
} from '../components/help/help-dialog.component';
import { StatusExpFormComponent } from './status-exp-form.component';
import { TextboxDialogComponent, TextboxDialogData } from '../components/textbox-dialog.component';

@Injectable({ providedIn: 'root' })
export class UtilDialogService {
  constructor(
    private confirmationService: ConfirmationService,
    public dialogService: DialogService
  ) {}

  // used to create dialogs
  async create<TData>(
    componentType: Type<any>,
    data?: TData,
    config?: DynamicDialogConfig<TData>
  ) {
    const ref = this.dialogService.open(componentType, {
      closable: false,
      draggable: true,
      // baseZIndex: 10000,
      // contentStyle: { overflow: 'auto' },
      ...config,
      data: data,
    });
    return lastValueFrom(ref.onClose);
  }

  async createFinder<T extends Entity, TParams extends EntityFinderParams>(
    componentType: Type<EntityFinderDialog<T, TParams>>,
    data?: TParams,
    config?: DynamicDialogConfig<TParams>
  ) {
    const ref = this.dialogService.open(componentType, {
      closable: false,
      draggable: true,
      // baseZIndex: 10000,
      // contentStyle: { overflow: 'auto' },
      ...config,
      data: data,
    });
    const r = await lastValueFrom(ref.onClose);
    return r as T[];
  }

  /*   async createStatusChange<T extends Entity, TParams extends StatusChangeParams >(componentType: Type<StatusChangeDialog<T, TParams>>, data?: TParams, config?: DynamicDialogConfig<TParams>) {
    const ref = this.dialogService.open(componentType, {
      closable: false, draggable: true,
      // baseZIndex: 10000,
      // contentStyle: { overflow: 'auto' },
      ...config,
      data: data
    });
    const r = await firstValueFrom(ref.onClose);
    return r as T;
  }
 */

  async confirmUndo(): Promise<boolean> {
    return new Promise((resolve) => {
      this.confirmationService.confirm({
        header: 'Undo Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message:
          'Are you sure you want to undo changes - all of your work since your last save will be lost.  Do you still want to continue?',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }

  async confirmCancel(): Promise<boolean> {
    return new Promise((resolve) => {
      this.confirmationService.confirm({
        header: 'Cancel Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'You have unsaved changes.  Do you still want to cancel?',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }

  async askIfOk(header: string, message: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.confirmationService.confirm({
        header: header,
        icon: 'pi pi-exclamation-triangle',
        message: message,
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }

  async okDialog(headerMsg: string, message: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.confirmationService.confirm({
        header: headerMsg,
        icon: 'pi pi-exclamation-triangle',
        message: message,
        acceptLabel: 'Ok',
        rejectVisible: false,
        accept: () => {
          resolve(true);
        },
      });
    });
  }

  async textboxDialog(headerMsg: string, config: TextboxDialogData): Promise<boolean> {
    const ref = this.dialogService.open(TextboxDialogComponent, {
      data: config,
      header: headerMsg,
      // style: { 'width': '600px'},
      contentStyle: { overflow: 'auto' },
      // baseZIndex: 10000,
      // maximizable: true
    });
    return lastValueFrom(ref.onClose);
  }

  async textpromptDialog(headerMsg: string, config: TextPromptDialogData): Promise<string> {
    const ref = this.dialogService.open(TextPromptDialogComponent, {
        data: config,
        header: headerMsg,
        contentStyle: { overflow: 'auto' },
        width: '500px'
      });
    return lastValueFrom(ref.onClose);
  }



  async errorDialog(err: Error): Promise<boolean> {
    let ref: DynamicDialogRef;
    return new Promise((resolve) => {
      ref = this.dialogService.open(ErrorDialogComponent, {
        data: {
          error: err,
        },
        header: 'Save Error',
        styleClass: 'red-header',
        style: { 'width': '600px'},
        contentStyle: { overflow: 'auto' },
        // baseZIndex: 10000,
        // maximizable: true
      });

      ref.onClose.subscribe(() => {
        resolve(true);
      });
    });
  }

  async statusErrorDialog(errs: string[]): Promise<boolean> {
    let errString = "";
    errs.forEach(s => {
      errString += s + '<br/>'
    })
    return new Promise((resolve) => {
      this.confirmationService.confirm({
        header: 'Status Change Error',
        icon: 'pi pi-exclamation-triangle',
        message: errString,
        acceptLabel: 'Ok',
        rejectVisible: false,
        accept: () => {
          resolve(true);
        },
      });
    });
  }

  async validationDialog(
    parent: any,
    errEntities: Entity[],
    callback: (ee: EntityError, event: MouseEvent) => void,
    showEntityTypes: boolean = false
  ): Promise<boolean> {
    let ref: DynamicDialogRef;
    const ht = errEntities.length > 10 ? '500px' : '100%';
    return new Promise((resolve) => {
      ref = this.dialogService.open(ValidationDialogComponent, {
        data: {
          parent: parent,
          errEntities,
          callback,
          showEntityTypes,
        },
        header: 'Validation Errors',
        styleClass: 'yellow-header',
        width: '800px',
        contentStyle: { height: ht },
        draggable: true,
        modal: false,
        resizable: true,
      });
      // ref is null if called while previous dialog is still up.
      ref?.onClose.subscribe(() => {
        resolve(true);
      });
    });
  }

  async verifyProceedDialog(
    parent: any,
    str: string,
    header: string
    ): Promise<boolean> {
    let ref: DynamicDialogRef;
    return new Promise((resolve) => {
      ref = this.dialogService.open(StatusExpFormComponent, {
        data: {
          parent: parent,
          status: str
        },
        header: header,
        width: '800px',
        closable: false,
        draggable: false,
        modal: true,
        resizable: false,
      });
      // ref is null if called while previous dialog is still up.
      ref?.onClose.subscribe((data) => {
        resolve(data);
      });
    });
  }

  async helpDialog(
    title: string,
    parent: any,
    field: string | undefined,
    callback?: (ee: EntityError, event: MouseEvent) => void,
    uow?: UnitOfWork
  ): Promise<boolean> {
    let ref: DynamicDialogRef;
    title = 'Help: ' + (title || this.getTitle(parent));
    return new Promise((resolve) => {
      ref = this.dialogService.open(HelpDialogComponent, {
        data: {
          parent,
          field,
          callback,
          uow,
        } as HelpDialogData,
        header: title,
        width: '800px',
        styleClass: 'goldenrod',
        contentStyle: { height: '70vh' },
        draggable: true,
        modal: false,
        resizable: true,
      });
      // ref is null if called while previous dialog is still up.
      ref?.onClose.subscribe(() => {
        resolve(true);
      });
    });
  }

  private getTitle(component: any) {
    return (
      component.title ||
      _.startCase(component.constructor.name.replace(/(Frm)?Component$/, ''))
    );
  }

  async changeLogDialog(
    uow: UnitOfWork,
    filterModel: any,
    entity?: Entity,
    entityId?: string
  ): Promise<boolean> {
    let ref: DynamicDialogRef;
    return new Promise((resolve) => {
      ref = this.dialogService.open(ChangeLogDialogComponent, {
        data: {
          uow,
          filterModel,
          entity,
          entityId,
        },
        header: 'Change Log',
        styleClass: 'yellow-header',
        width: '70vw',
        contentStyle: { height: '70vh' },
        draggable: true,
        modal: false,
        resizable: true,
      });
      // ref is null if called while previous dialog is still up.
      ref?.onClose.subscribe(() => {
        resolve(true);
      });
    });
  }
}
