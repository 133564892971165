// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Program } from './program';
import { ProgramProductCategoryTagMap } from './program-product-category-tag-map';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramProductCategoryTag extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String(60) */
  name!: string;
  /** Guid FK program */
  programId!: string;
  /** Binary */
  rowVersion!: any;
  /** FK programId */
  program!: Program;
  /** Inv FK programProductCategoryTagId */
  programProductCategoryTagMaps!: ProgramProductCategoryTagMap[];
}

