import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class FileImportService {
  constructor(
    private toast: ToastrService,
    private http: HttpClient) {}

    apiUrl = environment. apiUrl;

  private isEmpty = (str: string) => !str || !str.trim();
  
  public getMetaData(rules: any, jsonDataRow: any) {
    let errString = ""
    for(const k1 in jsonDataRow) {
      let key = k1;
      let value = jsonDataRow[k1];
      var reqErr = this.isRequiredErr(rules, key, value);
      if (reqErr) {
        jsonDataRow.Error = reqErr;
      }

      var mmlErr = this.minMaxLengthErr(rules, key, value);
      if (mmlErr) {
        jsonDataRow.Error = mmlErr;
      }

      var fErr = this.formatErr(rules, key, value);
      if (fErr) {
        jsonDataRow.Error = fErr;
      }
    }
  }

  private isRequiredErr(rules: any, key: any, value: any): string {
    var rule = _.filter(rules, { isRequired: true, fieldName: key, fieldType: "STRING" });
    if (rule.length > 0) {
      if (this.isEmpty(value)) return rule[0].columnName + " is a required field";
    }
    return "";
  }

  private minMaxLengthErr(rules: any, key: any, value: any): string {
    var rule = _.filter(rules, { fieldName: key, fieldType: "STRING" });
    if (rule.length == 0) return "";
    var len = value.trim().length;
    var minLength = rule[0].minLength;
    var maxLength = rule[0].maxLength;
    if ((len > 0) && (minLength > 0) && (len < minLength)) return rule[0].columnName + " requires minimum length of " + minLength + " characters";
    if ((len > 0) && (maxLength > 0) && (len > maxLength)) return rule[0].columnName + " exceeds maximum length of " + maxLength + " characters";
    return "";
  }

  private formatErr(rules: any, key: any, value: any): string {
    var rule = _.filter(rules, { fieldName: key });
    if (rule.length == 0) return "";
    var f = rule[0].expFormat;
    if ((f == "email") && (!(this.isEmpty(value))) && (!(this.isEmail(value)))) {
      return rule[0].columnName + " is not properly formatted";
    }
    return "";
  }

  private isEmail(search:string):boolean {
    let searchfind = false;
    // eslint-disable-next-line no-useless-escape
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    searchfind = regexp.test(search);
    return searchfind
  }

  public submitImport(jsonData: string): Observable<any> {
    var formData: any = new FormData();
    formData.append("jsonData", jsonData);
    return this.http.post(this.apiUrl + 'azure/import', formData);
  }
}

