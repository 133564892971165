// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Shipment } from './shipment';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ShipmentBox extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String */
  packageApiIdentifier?: string;
  /** Guid FK shipment */
  shipmentId!: string;
  /** String */
  shippingLabel!: string;
  /** String(255) */
  trackingLabelApiIdentifier?: string;
  /** Decimal */
  weight?: number;
  /** String */
  weightUoMId?: string;
  /** FK shipmentId */
  shipment!: Shipment;
}

