// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ActiveStatus } from './active-status';
import { Feature } from './feature';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class FeatureChoice extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      new EntityPropertyAnnotation('validValue', {
        displayName: 'Choice value',
      }),

    ];

    return new EntityTypeAnnotation({
      validators: [  ],
      propertyAnnotations: propAnnotations
    });

  }

  get name(): string { 
    return this.validValue;
  }

  getStatusParents() {
    return [this.feature];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String */
  apiLink?: string;
  /** Int32 */
  displayOrder!: number;
  /** Guid FK feature */
  featureId!: string;
  /** Binary */
  rowVersion!: any;
  /** String(100) */
  validValue!: string;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK featureId */
  feature!: Feature;
}

