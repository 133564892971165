/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridOptions } from '@ag-grid-community/core';
/* eslint-disable @typescript-eslint/no-this-alias */
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'prox-quick-search',
  templateUrl: './quick-search.component.html',
})
export class QuickSearchComponent {
  @Input() gridOptions!: GridOptions;
  
  form = new FormGroup({
    filter: new FormControl('')
  });
  get filter(): any { return this.form.get('filter'); }

  timeout: any = "";

  onFilterInputChanged(e: any) {
    clearTimeout(this.timeout);
    const this_ = this;
    this.timeout = setTimeout(function () {
      if (e.keyCode != 13) {
        this_.gridOptions.api?.setQuickFilter(e.target.value);
      }
    }, 500);
  }

  onClearSearch(e: any) {
    this.gridOptions.api?.setQuickFilter("");
    this.filter.reset()
  }

}
