<div *ngIf="isPageReady" class="flex h-full gap-2" id="topLevelDialog">


  <div class="card-body flex flex-grow-1 flex-column gap-3">
    <label class="ml-12 mr-8">Recurrence Frequency: </label>
    <div class="flex gap-4">
      <p-radioButton [value]=RRule.DAILY [(ngModel)]="rruleOptions.freq" inputId="recurD" (ngModelChange)="scheduleChanged()" />
      <label for="recurD" class="ml-2">Daily</label>
      <p-radioButton [value]=RRule.WEEKLY [(ngModel)]="rruleOptions.freq" inputId="recurW" (ngModelChange)="scheduleChanged()"/>
      <label for="recurW" class="ml-2">Weekly</label>
      <p-radioButton [value]=RRule.MONTHLY [(ngModel)]="rruleOptions.freq" inputId="recurM" (ngModelChange)="scheduleChanged()" />
      <label for="recurM" class="ml-2">Monthly</label>
    </div>

    <div *ngIf="rruleOptions.freq === RRule.DAILY">
      <span>Every</span>
      <input class="mx-2" type="number" min="0" max="365" [(ngModel)]="rruleOptions.interval"
        (ngModelChange)="scheduleChanged()" />
      <span>Days(s)</span>
    </div>

    <div class="flex" *ngIf="rruleOptions.freq === RRule.WEEKLY">
      <div class="w-3">
        <span>Every</span>
        <input class="mx-2" type="number" min="0" max="52" [(ngModel)]="rruleOptions.interval"
          (ngModelChange)="scheduleChanged()" />
        <span>Week(s)</span>
      </div>
      <div class="w-3">
        <div class="flex flex-column gap-3">
          <div>
            <p-radioButton [value]=RRule.MO [(ngModel)]="byweekday" inputId="bwdMo" (ngModelChange)="scheduleChanged()" />
            <label for="bwdMo" class="ml-2">Monday</label>
          </div>
          <div>
            <p-radioButton [value]=RRule.TU [(ngModel)]="byweekday" inputId="bwdTu" (ngModelChange)="scheduleChanged()"/>
            <label for="bwdTu" class="ml-2">Tuesday</label>
          </div>
          <div>
            <p-radioButton [value]=RRule.WE [(ngModel)]="byweekday" inputId="bwdWe" (ngModelChange)="scheduleChanged()"/>
            <label for="bwdWe" class="ml-2">Wednesday</label>
          </div>
          <div>
            <p-radioButton [value]=RRule.TH [(ngModel)]="byweekday" inputId="bwdTh" (ngModelChange)="scheduleChanged()"/>
            <label for="bwdTh" class="ml-2">Thursday</label>
          </div>
          <div>
            <p-radioButton [value]=RRule.FR [(ngModel)]="byweekday" inputId="bwdFr" (ngModelChange)="scheduleChanged()"/>
            <label for="bwdFr" class="ml-2">Friday</label>
          </div>
          <div>
            <p-radioButton [value]=RRule.SA [(ngModel)]="byweekday" inputId="bwdSa" (ngModelChange)="scheduleChanged()"/>
            <label for="bwdSa" class="ml-2">Saturday</label>
          </div>
          <div>
            <p-radioButton [value]=RRule.SU [(ngModel)]="byweekday" inputId="bwdSu" (ngModelChange)="scheduleChanged()"/>
            <label for="bwdSu" class="ml-2">Sunday</label>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="rruleOptions.freq === RRule.MONTHLY">
      <label class="ml-12 mr-8">Select a mode: </label>
      <div class="flex gap-4 mt-3">
        <p-radioButton [value]=true [(ngModel)]="isMonthDay" inputId="imdT" (ngModelChange)="isMonthDayChanged($event)" />
        <label for="imdT" class="ml-2">By Day of Month</label>
        <p-radioButton [value]=false [(ngModel)]="isMonthDay" inputId="imdF" (ngModelChange)="isMonthDayChanged($event)" />
        <label for="imdF" class="ml-2">By Day of Week of Month</label>
      </div>

      <div class="flex mt-3" style="align-items: flex-start;" >
        <span>Every</span>
        <input class="mx-2" type="number" min="0" max="100" [(ngModel)]="rruleOptions.interval"
          (ngModelChange)="scheduleChanged()" />
        <ng-container *ngIf="isMonthDay">
          <span>Month(s) on Day: </span>
          <input class="mx-2" matInput type="number" min="0" max="30" [(ngModel)]="rruleOptions.bymonthday"
            (ngModelChange)="scheduleChanged()" />
        </ng-container>

        <ng-container *ngIf="!isMonthDay">
          <span>Month(s) on the: </span>
          <p-dropdown class="-mt-2 mx-2" [options]="RRuleFns.weekInMonthList" [(ngModel)]="rruleOptions.bysetpos"  (ngModelChange)="scheduleChanged()"
            optionLabel="name" optionValue="value" placeholder="Select a value" />
        </ng-container>

        <ng-container *ngIf="!isMonthDay">
            <div class="flex flex-column gap-3 ml-3">
              <div>
                <p-radioButton name="x_1" [value]=RRule.MO [(ngModel)]="byweekday" inputId="bwdMo" (ngModelChange)="scheduleChanged()" />
                <label for="bwdMo" class="ml-2">Monday</label>
              </div>
              <div>
                <p-radioButton name="x_2" [value]=RRule.TU [(ngModel)]="byweekday" inputId="bwdTu" (ngModelChange)="scheduleChanged()" />
                <label for="bwdTu" class="ml-2">Tuesday</label>
              </div>
              <div>
                <p-radioButton name="x_3" [value]=RRule.WE [(ngModel)]="byweekday" inputId="bwdWe" (ngModelChange)="scheduleChanged()" />
                <label for="bwdWe" class="ml-2">Wednesday</label>
              </div>
              <div>
                <p-radioButton name="x_4" [value]=RRule.TH [(ngModel)]="byweekday" inputId="bwdTh" (ngModelChange)="scheduleChanged()" />
                <label for="bwdTh" class="ml-2">Thursday</label>
              </div>
              <div>
                <p-radioButton name="x" [value]=RRule.FR [(ngModel)]="byweekday" inputId="bwdFr" (ngModelChange)="scheduleChanged()"/>
                <label for="bwdFr" class="ml-2">Friday</label>
              </div>
              <div>
                <p-radioButton name="x" [value]=RRule.SA [(ngModel)]="byweekday" inputId="bwdSa" (ngModelChange)="scheduleChanged()"/>
                <label for="bwdSa" class="ml-2">Saturday</label>
              </div>
              <div>
                <p-radioButton name="x" [value]=RRule.SU [(ngModel)]="byweekday" inputId="bwdSu" (ngModelChange)="scheduleChanged()"/>
                <label for="bwdSu" class="ml-2">Sunday</label>
              </div>

          </div>
        </ng-container>
        
      </div>
    </div>

    <div>
      <span>Start Date: </span>
      <p-calendar class="mx-2" [(ngModel)]="rruleOptions.dtstart" (ngModelChange)="scheduleChanged()" />
      <span>Until Date: </span>
      <p-calendar class="mx-2" [(ngModel)]="rruleOptions.until" (ngModelChange)="scheduleChanged()" />
      
    </div>
    <div>
      <span>Count: </span>
      <input class="mx-2" type="number" min="0" max="100" [(ngModel)]="rruleOptions.count"
          (ngModelChange)="scheduleChanged()" />
    </div>

    <div *ngIf="rruleDates != null && rruleDates.length > 0"  >
      <div class="h3 mt-20 ml-12 mb-4">Event Dates: {{rruleText}} {{ hasEnd ? '' : ' ( only first 10 shown ) ' }} </div>
      <prox-ag-grid style="height: 200px;" [gridOptions]="rruleGridOptions" [rowData]="rruleDates" [hasFooter]=false>
      </prox-ag-grid>
    </div>
  
    <div >
      <span>RRule: {{ rrule.toString() }}</span>
    </div>
    
    <div class="flex-grow-1"></div>

    <div class="flex mt-6">
      <div class="mr-2">
        <button (click)="ok()" pButton type="button" label="Ok" icon="pi pi-check" class="p-button-raised"></button>
      </div>

      <div class="pr-2">
        <button (click)="cancel()" pButton type="button" icon="pi pi-times" label="Cancel"
          class="p-button-raised"></button>
      </div>
    </div>
  </div>

  <p-confirmDialog [closable]="false" [baseZIndex]="1000"></p-confirmDialog>
  <!-- <app-uni-dialog title="Schedule an Event" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>

  <div fxLayout="column" fxLayoutAlign="left center" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="left center" >
    <label class="ml-12 mr-8">Recurrence Frequency: </label>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="rruleOptions.freq"
      (ngModelChange)="freqChanged($event)">
      <mat-radio-button class="ml-8" [value]=RRule.DAILY>Daily</mat-radio-button>
      <mat-radio-button class="ml-12" [value]=RRule.WEEKLY>Weekly</mat-radio-button>
      <mat-radio-button class="ml-12" [value]=RRule.MONTHLY>Monthly</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="rruleOptions.freq == RRule.DAILY">
    <div fxLayout="row wrap" class="mt-8 ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
      <span>Every</span>
      <mat-form-field style="width: 50px">
        <input matInput type="number" min="0" max="365" [(ngModel)]="rruleOptions.interval"
          (ngModelChange)="scheduleChanged()" />
      </mat-form-field>
      <span>Day(s)</span>
    </div>
  </div>

  <div *ngIf="rruleOptions.freq == RRule.WEEKLY">
    <div fxLayout="row wrap" class="mt-8 ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
      <span>Every</span>
      <mat-form-field style="width: 50px">
        <input matInput type="number" min="0" max="52" [(ngModel)]="rruleOptions.interval"
          (ngModelChange)="scheduleChanged()" />
      </mat-form-field>
      <span>Week(s)</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="left center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="left center" class="mt-4">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="rruleOptions.byweekday[0]"
        (ngModelChange)="scheduleChanged()">

        <mat-radio-button class="ml-12" [value]=RRule.MO>Monday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.TU>Tuesday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.WE>Wednesday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.TH>Thursday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.FR>Friday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.SA>Saturday</mat-radio-button>
        <mat-radio-button class="ml-8" [value]=RRule.SU>Sunday</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div *ngIf="rruleOptions.freq == RRule.MONTHLY">
    <div fxLayout="row wrap" class="mt-8 ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
      <span>Mode: </span>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="isMonthDay"
        (ngModelChange)="isMonthDayChanged($event)">
        <mat-radio-button class="ml-8" [value]=true>By Day of Month</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=false>by Day of Week of Month</mat-radio-button>
      </mat-radio-group>
    </div>
    <div fxLayout="row wrap" class="mt-8 ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
      <span>Every</span>
      <mat-form-field style="width: 50px">
        <input matInput type="number" min="0" max="100" [(ngModel)]="rruleOptions.interval"
          (ngModelChange)="scheduleChanged()" />
      </mat-form-field>
      <ng-container *ngIf="isMonthDay">
        <span>Month(s) on Day: </span>
        <mat-form-field class="ml-8" style="width: 50px">
          <input matInput type="number" min="0" max="30" [(ngModel)]="rruleOptions.bymonthday"
            (ngModelChange)="scheduleChanged()" />
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!isMonthDay">
        <span>Month(s) on the: </span>
        <mat-form-field class="ml-8" style="width: 90px">
          <mat-select [(ngModel)]="rruleOptions.bysetpos" (ngModelChange)="scheduleChanged()">
            <mat-option [value]=1>First</mat-option>
            <mat-option [value]=2>Second</mat-option>
            <mat-option [value]=3>Third</mat-option>
            <mat-option [value]=4>Fourth</mat-option>
            <mat-option [value]=-1>Last</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>

    <div *ngIf="!isMonthDay" fxLayout="column" fxLayoutAlign="left center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="left center" class="mt-4">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="rruleOptions.byweekday[0]"
        (ngModelChange)="scheduleChanged()">
        <mat-radio-button class="ml-12" [value]=RRule.MO>Monday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.TU>Tuesday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.WE>Wednesday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.TH>Thursday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.FR>Friday</mat-radio-button>
        <mat-radio-button class="ml-12" [value]=RRule.SA>Saturday</mat-radio-button>
        <mat-radio-button class="ml-8" [value]=RRule.SU>Sunday</mat-radio-button>
      </mat-radio-group>
    </div>

  </div>

  <div fxLayout="row wrap" class="mt-12 ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
    <mat-form-field>
      <input matInput [(ngModel)]="rruleOptions.dtstart" [matDatepicker]="startDatePicker" placeholder="Start Date"
        (ngModelChange)="scheduleChanged()">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput [(ngModel)]="rruleOptions.until" [matDatepicker]="untilDatePicker" placeholder="Until Date"
        (ngModelChange)="scheduleChanged()">
      <mat-datepicker-toggle matSuffix [for]="untilDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #untilDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 150px">
      <input matInput type="number" min="0" max="100" placeholder="Count" [(ngModel)]="rruleOptions.count"
        (ngModelChange)="scheduleChanged()" />
    </mat-form-field>
  </div>
  


  <div *ngIf="rruleDates != null && rruleDates.length > 0" class="content-card" >
    <div class="h3 mt-20 ml-12 mb-4">Event Dates: {{rruleText}} {{ hasEnd ? '' : ' ( only first 10 shown ) ' }} </div>
    <app-ag-grid style="height: 200px;" [gridOptions]="rruleGridOptions" [rowData]="rruleDates" [hasFooter]=false>
    </app-ag-grid>
  </div>

  <div fxLayout="row wrap" class="mt-8 ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
    <span>RRule: {{ rrule.toString() }}</span>
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template> -->