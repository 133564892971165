import { Injectable } from '@angular/core';
import { MetadataStoreProvider } from '@data';
import { environment } from '@env';
import {
  DataService, EntityManager
} from 'breeze-client';


export abstract class EntityManagerProvider {
  protected _isPrepared?: boolean;
  protected _masterManager?: EntityManager;
  
  constructor(private metadataStoreProvider: MetadataStoreProvider) {}

  abstract getDataService(): DataService;

  prepare(): boolean {
    if (!this._isPrepared) {
      const dataService = this.getDataService();
      const metadataStore = this.metadataStoreProvider.metadataStore;
      const serviceNames = metadataStore.dataServices.map(ds => ds.serviceName);
      if (!serviceNames.includes(dataService.serviceName)) {
        metadataStore.addDataService(dataService);
      }
      const master = new EntityManager({
        dataService: dataService,
        metadataStore: metadataStore,
      });
      this._masterManager = master;

      this._isPrepared = true;
    }

    return this._isPrepared;
  }

  get masterManager(): EntityManager {
    this.prepare();
    return this._masterManager!;
  }

  newManager(): EntityManager {
    const manager = this.masterManager.createEmptyCopy();
    return manager;
  }
}

@Injectable({ providedIn: 'root' })
export class AdaptEntityManagerProvider extends EntityManagerProvider {
  constructor(metadataStoreProvider: MetadataStoreProvider) {
    super(metadataStoreProvider);
  }
  getDataService(): DataService {
    return new DataService({
      serviceName: environment.adaptUrl,
      hasServerMetadata: false,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class AdminEntityManagerProvider extends EntityManagerProvider {
  constructor(metadataStoreProvider: MetadataStoreProvider) {
    super(metadataStoreProvider);
  }

  getDataService(): DataService {
    return new DataService({
      serviceName: environment.adminUrl,
      hasServerMetadata: false,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class AccountEntityManagerProvider extends EntityManagerProvider {
  constructor(metadataStoreProvider: MetadataStoreProvider) {
    super(metadataStoreProvider);
  }

  getDataService(): DataService {
    return new DataService({
      serviceName: environment.accountUrl,
      hasServerMetadata: false,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class SupplierEntityManagerProvider extends EntityManagerProvider {
  constructor(metadataStoreProvider: MetadataStoreProvider) {
    super(metadataStoreProvider);
  }

  getDataService(): DataService {
    return new DataService({
      serviceName: environment.supplierUrl,
      hasServerMetadata: false,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class TransactionEntityManagerProvider extends EntityManagerProvider {
  constructor(metadataStoreProvider: MetadataStoreProvider) {
    super(metadataStoreProvider);
  }

  getDataService(): DataService {
    return new DataService({
      // serviceName: environment.transactionUrl,
      serviceName: environment.accountUrl,
      hasServerMetadata: false,
    });
  }
}
