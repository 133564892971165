/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnDestroy, reflectComponentType } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router, UrlSegment } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { BaseService } from '../services/base.service';
import { UtilDialogService } from '../services/dialog.service';
import { AuthUser } from '../services/auth.service';
import { IHasSuspendables, ISuspendable } from './component-interfaces';
import { ProximityRightEnum } from '@models';
import { AgFns } from './ag-grid';
import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';

/** Base class for most components */
@Component({
  selector: 'prox-base',
  template: ''
})
export abstract class BaseComponent implements OnDestroy, IHasSuspendables {
  /** Use `.pipe(takeUntil(this.destroy$))` on all observables */
  destroy$ = new Subject<boolean>();
  dialogService: UtilDialogService;
  
  router: Router;
  toastr: ToastrService;
  urlSegments: UrlSegment[] = [];
  isPageReady = false;
  //isFormDisabled = false;
  authUser?: AuthUser;
  suspendables =  new Set<ISuspendable>();
  ProximityRightEnum = ProximityRightEnum;
  /** Id of currently active entity or entity in selected grid row */
  entityId?: string;
  selectedRow?: any;
  
  constructor(
    public baseService: BaseService,
    public route: ActivatedRoute,

  ) {
    this.dialogService = baseService.dialogService;
    this.router = baseService.router;
    this.toastr = baseService.toastr;
    this.authUser = baseService.authService.getUser();
    this.setTitle("");
    
    // TODO: think about when we use queryParamMap instead of paramMap...

    // setTimeout is critical here because injection of the derived class values does NOT occur until after the base class ctor completes. 
    // so any refs to injected values in the 'updateFromParams' call below will be null without this. 
    setTimeout(() => this.init(), 0);

  }

  protected init() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(() => {

      const params = this.collectRouteParams(this.router);
      // console.log("  unified params:  " + JSON.stringify(params));

      this.updateFromParams(params);
    });
  }

  addSuspendable(suspendable: ISuspendable) {
    this.suspendables.add(suspendable);
  }

  /** Return true if user has any of the rights */
  hasRight(...rights: ProximityRightEnum[]) {
    return this.authUser && this.authUser.hasRight(...rights);
  }

  /** Collect the route params from all active routes and url segments */
  protected collectRouteParams(router: Router) {
    let params = {};
    const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
    while (stack.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const route = stack.pop()!;
      const urlParams = this.collectUrlParams(route.url);
      params = { ...params, ...urlParams, ...route.params };
      stack.push(...route.children);
    } 
    return <Params>params;
  }

  /** Get all parameters defined on the url segments.  In case of name collision, child route value wins over parent. 
   * Note: For optional parameters, use this instead of route.params because route.params get removed and replaced 
   * when child routes also have optional parameters.  
  */
  private collectUrlParams(urlSegments: UrlSegment[]) {
    let params = {};
    for (const seg of urlSegments) {
      params = { ...params, ...seg.parameters }
    }
    return params;
  }

  /** Initalize the component with current route params.  Generally used in place of ngOnInit */
  abstract updateFromParams(params?: object): void | Promise<void>;

  onRowSelectedDefault(e: RowSelectedEvent) {
    if (!e.node.isSelected()) return;
    const gridOptions = e.context.gridOptions;
    const keyField = e.context.gridState.keyField || 'id';
    this.updateGridRouteParams(gridOptions, e.data[keyField] );
    this.selectedRow = e.data;
  }

  updateGridRouteParams(gridOptions: GridOptions, key?: string) {
    const urlNoQp= this.router.url.split('?')[0] 
    const urlTree = this.router.createUrlTree([urlNoQp], {  });
    const url = AgFns.buildGridRouteParamsUrl(urlTree, gridOptions, key);
    this.entityId = key;
    this.baseService.location.replaceState(url);
  }

  /** Stop navigation if there are unsaved changes */
  public async canDeactivate()  {
    // will be overloaded later 
    return true;
  }

  /** Stop window closing if there are unsaved changes - cannot be async */
  @HostListener('window:beforeunload')
  public canUnload()  {
    // will be overloaded later 
    return true;
  }

  /** Discard unsaved changes and return to previous page */
  // TODO: Later we should consider making this abstract
  public deactivate() {
    this.isPageReady = false;
    // should be overriden
  }

  hasMethod(name: string) {
    return name in this;
  }

  /** Navigate back to previous or parent page */
  navigateBack() {
    this.baseService.location.back();
    // this.router.navigate(['..'], { relativeTo: this.route });
  }

  /** Terminate observables.  Be sure to call super.ngOnDestroy() if overridden. */
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /** Set the page title */
  setTitle(title: string) {
    var ttl = "Proximity System";
    if (title.length > 0) {
      ttl += ' - ' + title;
    }
    this.baseService.titleService.setTitle(ttl);
  }

  /** Return the component's selector, e.g. 'prox-account-info' */
  get selector(): string {
    const metadata = reflectComponentType(this.constructor as any);
    return metadata?.selector || this.constructor.name;
  }

  private _isBusy = false;
  get isBusy() { 
    return this._isBusy || this['dbSaveService']?.isBusy 
  }
  set isBusy(x: boolean) {
    this._isBusy = x;
  }

  // handle nesting...
  // handle putting up wait indicator...
  async wrapBusy<T>(op: Promise<T> | (() => Promise<T>) ) {
    try {
      this.isBusy = true;
      if (typeof op === 'function') {
        return await op();
      }
      return await op;
    } finally {
      this.isBusy = false;
    }
  }
}
