<div class="card-body" *ngIf="isPageReady && address">
  <div class="flex flex-column">
    <div class="w-12 pb-3 pr-3">
      <prox-field label="Address Name">
        <input type="text" pInputText autocomplete="off"  [(ngModel)]="address.name"
          [bzModel]="parentEntity" [name]="pathify('name')" [disabled]="!!disabled"/>
      </prox-field>
    </div>
    <div class="w-12 pb-3 pr-3">
      <prox-field label="Address Line 1">
        <input type="text" pInputText autocomplete="off"  [(ngModel)]="address.line1"
          [bzModel]="parentEntity" [name]="pathify('line1')" [disabled]="!!disabled"/>
      </prox-field>
    </div>
    <div class="w-12 pb-3 pr-3">
      <prox-field label="Address Line 2">
        <input type="text" pInputText autocomplete="off"  [(ngModel)]="address.line2"
          [bzModel]="parentEntity"[name]="pathify('line2')" [disabled]="!!disabled"/>
      </prox-field>
    </div>
    <div class="w-12 pb-3 pr-3">
      <prox-field label="Address Line 3">
        <input type="text" pInputText autocomplete="off"  [(ngModel)]="address.line3"
          [bzModel]="parentEntity" [name]="pathify('line3')"  [disabled]="!!disabled" />
      </prox-field>
    </div>
    <div class="flex">
      <div class="w-5 pb-3 pr-3">
        <prox-field label="City">
          <input type="text" pInputText autocomplete="off"  [(ngModel)]="address.city"
            [bzModel]="parentEntity" [name]="pathify('city')"  [disabled]="!!disabled" />
        </prox-field>
      </div>
      <div class="w-4 pb-3 pr-3">
        <prox-field label="State">
          <p-dropdown [options]="usStates" optionLabel="name" optionValue="abbr"
            [(ngModel)]="address.state" [bzModel]="parentEntity" [name]="pathify('state')"  [disabled]="!!disabled">
          </p-dropdown>
        </prox-field>
      </div>
      <div class="w-3 pb-3 pr-3">
        <prox-field label="Zip">
          <input type="text" pInputText autocomplete="off"  [(ngModel)]="address.zipcode"
            [bzModel]="parentEntity" [name]="pathify('zipcode')"  [disabled]="!!disabled" />
        </prox-field>
      </div>
      
    </div>
  </div>
</div>
