import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { NotificationQueryEnum } from '@models';
import { startCase } from 'lodash';
import { MenuItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs';

export interface TemplateItem {
  key: string;
  title: string;
}

/*
  templateIndex(0) = Account Primary Admin Template
  templateIndex(1) = Account Admin Template
  templateIndex(2) = Account Admin Group Template
  templateIndex(3) = Account User Template
  templateIndex(4) = Account Order Template
*/

@Injectable({
  providedIn: 'root',
})

// templateIndex(0)
export class TemplatesService {
/*
  private _accountMergeListObj = {
    title: 'Account Merge Tags',
    menu: [
      {
        key: 'accountName',
        title: 'Account: Name',
      },
      {
        key: 'activeStatus',
        title: 'Account: Status',
      },
      {
        key: 'accountBillingName',
        title: 'Account: Billing Name',
      },
      {
        key: 'accountBillingLine1',
        title: 'Account: Billing Line 1',
      },
      {
        key: 'accountBillingLine2',
        title: 'Account: Billing Line 2',
      },
      {
        key: 'accountBillingLine3',
        title: 'Account: Billing Line 3',
      },
      {
        key: 'accountBillingCity',
        title: 'Account: Billing City',
      },
      {
        key: 'accountBillingState',
        title: 'Account: Billing State',
      },
      {
        key: 'accountBillingZipCode',
        title: 'Account: Billing Zip Code',
      },
      {
        key: 'accountBillingAttn',
        title: 'Account: Billing Attention',
      },
      {
        key: 'accountTelephoneNumber',
        title: 'Account: Telephone Number',
      },
      {
        key: 'accountTelephoneExtension',
        title: 'Account: Telephone Extension',
      },
    ],
  };

  // templateIndex(1)
  private _accountPrimaryAdminMergeListObj = {
    title: 'Account Primary Admin Merge Tags',
    menu: [
      {
        key: 'adminSalutation',
        title: 'Admin: Salutation',
      },
      {
        key: 'adminFirstName',
        title: 'Admin: First Name',
      },
      {
        key: 'adminLastName',
        title: 'Admin: Last Name',
      },
      {
        key: 'adminTitle',
        title: 'Admin: Title',
      },
      {
        key: 'adminStatus',
        title: 'Admin: Status',
      },
      {
        key: 'adminEmail',
        title: 'Admin: Email',
      },
      {
        key: 'adminCellNumber',
        title: 'Admin: Cell Number',
      },
      {
        key: 'adminAcceptSms',
        title: 'Admin: Accept Sms?',
      },
      {
        key: 'adminTelephone',
        title: 'Admin: Telephone Number',
      },
      {
        key: 'adminTelephoneExtension',
        title: 'Admin: Telephone Extension',
      },
    ],
  };

  // templateIndex(2)
  private _accountAdminMergeListObj = {
    title: 'Account Admin Merge Tags',
    menu: [
      {
        key: 'adminSalutation',
        title: 'Admin: Salutation',
      },
      {
        key: 'adminFirstName',
        title: 'Admin: First Name',
      },
      {
        key: 'adminLastName',
        title: 'Admin: Last Name',
      },
      {
        key: 'adminTitle',
        title: 'Admin: Title',
      },
      {
        key: 'adminStatus',
        title: 'Admin: Status',
      },
      {
        key: 'adminEmail',
        title: 'Admin: Email',
      },
      {
        key: 'adminCellNumber',
        title: 'Admin: Cell Number',
      },
      {
        key: 'adminAcceptSms',
        title: 'Admin: Accept Sms?',
      },
      {
        key: 'adminTelephone',
        title: 'Admin: Telephone Number',
      },
      {
        key: 'adminTelephoneExtension',
        title: 'Admin: Telephone Extension',
      },
    ],
  };

  // templateIndex(3)
  private _accountAdminGroupMergeListObj = {
    title: 'Account Admin Group Merge Tags',
    menu: [
      {
        key: 'adminGroupName',
        title: 'Admin Group: Name',
      },
    ],
  };

  // templateIndex(4)
  private _accountUserMergeListObj = {
    title: 'Account User Merge Tags',
    menu: [
      {
        key: 'userFirstName',
        title: 'User: First Name',
      },
      {
        key: 'userLastName',
        title: 'User: Last Name',
      },
      {
        key: 'userFirstName',
        title: 'User: First Name',
      },
      {
        key: 'userLastName',
        title: 'User: Last Name',
      },
      {
        key: 'userMiddleName',
        title: 'User: Middle Name',
      },
      {
        key: 'userTitle',
        title: 'User: Title',
      },
      {
        key: 'userSalutation',
        title: 'User: Salutation',
      },
      {
        key: 'userHireDate',
        title: 'User: Hire Date',
      },
      {
        key: 'userExitDate',
        title: 'User: Exit Date',
      },
    ],
  };

  // templateIndex(5)
  private _orderMergeListObj = {
    title: 'Order Merge Tags',
    menu: [
      {
        key: 'orderId',
        title: 'Order: Id',
      },
      {
        key: 'orderCreateDate',
        title: 'Order: Create Date',
      },
      {
        key: 'orderSubmitDate',
        title: 'Order: Submit Date',
      },
      {
        key: 'orderPurchaseOrder',
        title: 'Order: Date',
      },
      {
        key: 'orderOrderInfo',
        title: 'Order: [Table] Order Info',
      },
    ],
  };
*/
  private varLookup: { [queryId: string]: string[] } = {};

  constructor(private httpClient: HttpClient) {
  }

  /*
    templateIndex(0) = Account Primary Admin Template
    templateIndex(1) = Account Admin Template
    templateIndex(2) = Account Admin Group Template
    templateIndex(3) = Account User Template
    templateIndex(4) = Account Order Template
  */
/* TODO apply similar grouping to templates received from the server
  public getAccountMergeList(templateIndex: number) {
    if (templateIndex == 0)
      return [this._accountMergeListObj, this._accountPrimaryAdminMergeListObj];
    if (templateIndex == 1)
      return [this._accountMergeListObj, this._accountAdminMergeListObj];
    if (templateIndex == 2)
      return [
        this._accountMergeListObj,
        this._accountAdminGroupMergeListObj,
        this._accountAdminMergeListObj,
      ];
    if (templateIndex == 3)
      return [
        this._accountMergeListObj,
        this._accountUserMergeListObj
      ];
    if (templateIndex == 4)
      return [
        this._accountMergeListObj,
        this._accountUserMergeListObj,
        this._orderMergeListObj
      ];
    return [];
  }
*/
  /** Convert TemplateItem arrays to MenuItem array, for use in editor suggestions */
  makeMenuItems(...mergeLists: { menu: TemplateItem[] }[]): MenuItem[] {
    const templateItems = ([] as TemplateItem[]).concat(
      ...mergeLists.map((x) => x.menu)
    );
    // need to use automationId here because id gets stripped out during menu display
    return templateItems.map((x) => ({ automationId: x.key, label: x.title }));
  }

  /** Get the template vars from the server and make menu items from them */
  async getTemplateMenuItems() {
    const vars = await this.getTemplateVars();

    // TODO - make tiered menu of choices
    // const titleGroups = vars.map(x => x.Title.split(':')[0]).filter((value, index, array) => array.indexOf(value) === index);
    // const menus = titleGroups.map(tg => {
    //   const items = vars.filter(x => x.Title.startsWith(tg + ':')).map(x => ({ automationId: x.Key, label: x.Title }));
    //   return { label: tg, items: items, expanded: true };
    // });
    // return menus;
    return vars.map((x) => ({ automationId: x.Key, label: x.Title }));
  }

  /** Get the template vars from the server and make menu items from them */
  async getTemplateVars() {
    // TODO apply grouping to templates similar to the above
    const url = environment.accountUrl + '/GetTemplateVars';
    const vars = await firstValueFrom(this.httpClient.get<{ Key: string, Title: string }[]>(url));
    return vars;
  }

  /** Get the template vars from the server and make menu items from them */
  async getNotificationMenuItems(queryId: NotificationQueryEnum) {
    const vars = await this.getNotificationVars(queryId);
    return vars.map((x) => ({ automationId: x, label: x.endsWith('+') ? startCase(x) + ' (list)' : startCase(x) }));
  }

  /** Get the template vars for the given query type */
  async getNotificationVars(queryId: NotificationQueryEnum) {
    const key = '' + queryId;
    let vars = this.varLookup[key];
    if (!vars) {
      const url = environment.accountUrl + '/GetNotificationVars?{}&queryId=' + queryId;
      vars = await firstValueFrom(this.httpClient.get<string[]>(url));
      this.varLookup[key] = vars;
    }
    return vars;
  }
}