import { CellClickedEvent } from '@ag-grid-community/core';
import { AgFns } from './ag-fns';
import { Router } from '@angular/router';
import { LinkHelpTooltipComponent } from './tooltip.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

export interface NavContext {
  router: Router;
  navPath: string;
}

export class NavFns {

  static createCellClickedNavProps(router: Router, navCommands: any[])  {
    
    return {
      cellStyle: { color: 'blue', cursor: 'pointer' },
      onCellClicked: (event) => NavFns.navigateWithClickEvent(event, router, navCommands, event.value)
    };
  }

  static createCellClickedCalcNavProps(router: Router, navPathFn: (event) => { navCommands: any[], queryParams?: any })   {
    return {
      cellStyle: { color: 'blue', cursor: 'pointer' },
      onCellClicked: (event) => { 
        const { navCommands, queryParams } = navPathFn(event);
        NavFns.navigateWithClickEvent(event, router, navCommands, queryParams)
      }
    };
  }

  static createIdCellClickedNavProps(field: string, router: Router, navCommands: any[], dialogRef?: DynamicDialogRef)  {
    return {
      field: field,
      cellStyle: { color: 'blue', cursor: 'pointer' },
      onCellClicked: (event) => {
        const params = NavFns.buildNavIdParams(event.value);
        NavFns.navigateWithClickEvent(event, router, navCommands, params);
        if (dialogRef != null) {
          dialogRef.close(null);
        }
      },
      tooltipComponent: LinkHelpTooltipComponent,
      tooltipField: field
    };
  }

  static navigateWithClickEvent(event: CellClickedEvent, router: Router, navCommands: any[], queryParams: any) {
    if ((event.event as PointerEvent).ctrlKey) {
      const url = router.serializeUrl(router.createUrlTree(navCommands, queryParams));
      window.open(url, '_blank');
    } else {
      router.navigate(navCommands, queryParams);
      // this.router.navigate(['../../product', product.id], { relativeTo: this.route });
    }
  }

  static buildNavIdParams(id: any, fieldName?: string, fieldType?: 'number' | 'text') {
    fieldName = fieldName ?? 'id';
    const fm = AgFns.addFilterClause({}, fieldName, 'number', id);
    const urlSuffix = encodeURIComponent(JSON.stringify(fm));
    const params = {
      queryParams: {
        fm: urlSuffix,
        key: id.toString()
      }
    };
    return params;
  }

}
