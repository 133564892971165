import { Component, OnInit } from '@angular/core';
import { SupplierUnitOfWork } from '@data';
import { Supplier } from '@models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntityFinderDialog, EntityFinderParams } from '../base/entity-finder.dialog';
import { AgFns, ISortModel } from '../ag-grid';
import { ColDef } from '@ag-grid-community/core';

@Component({
  selector: 'prox-supplier-finder',
  template: EntityFinderDialog.HTML,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CoreSupplierFinderDialog extends EntityFinderDialog<Supplier, EntityFinderParams> implements OnInit {
  
  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig,
    public uow: SupplierUnitOfWork,
  ) {
    super(ref, config  );
  }

  override async initialize(): Promise<EntityFinderParams> {
    return {
      header: 'Select a Proximity Supplier'
    }
  }

  async initDatasource() {
    const datasource = AgFns.buildDatasource(() => this.uow.createQuery(Supplier));
    return datasource;
  }

  override getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { headerName: 'Supplier Name', field: 'name', filter: 'agTextColumnFilter',   },
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

}
