import { ActivatedRoute } from "@angular/router";
import { BaseService } from "../services/base.service";
import { BaseComponent } from "./base.component";
import { DbQueryService, DbSaveService } from "@data";
import { saveComponentMixin } from "./mixins/save-component-mixin";
import { queryComponentMixin } from "./mixins/query-component-mixin";

abstract class SaveComponentShell extends BaseComponent {
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    public dbSaveService: DbSaveService,
  ) {
    super(baseService, route);
  }
}

export const SaveComponent = saveComponentMixin(SaveComponentShell);

// TODO: remove later - Shim for now
export const BaseEditComponent = SaveComponent;

abstract class QueryComponentShell extends BaseComponent {
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    public dbQueryService: DbQueryService,
  ) {
    super(baseService, route);
  }
}

export const QueryComponent = queryComponentMixin(QueryComponentShell);

abstract class SaveAndQueryComponentShell extends BaseComponent {
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    public dbSaveService: DbSaveService,
    public dbQueryService: DbQueryService,
  ) {
    super(baseService, route);
  }
}


export const SaveAndQueryComponent = saveComponentMixin(queryComponentMixin(SaveAndQueryComponentShell));