import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env';
import { LicenseManager } from '@ag-grid-enterprise/core';

/* LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-043913 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Unitec Distribution Systems )_is_granted_a_( Single Application )_Developer_License_for_the_application_( prox )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( prox )_need_to_be_licensed___( prox )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 3 August 2024 )____[v2]_MTcyMjYzOTYwMDAwMA==741f42928929fe4e09131e7eea8106da'
);

LicenseManager.setLicenseKey(
  'Using_this_{AG_Charts}_Enterprise_key_{AG-059150}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Unitec_Distribution_Systems}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Proximity}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Proximity}_need_to_be_licensed___{Proximity}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[02]_MTc0NTUzNTYwMDAwMA==b1412f40a0f7be70b98bc4739c449c53'
); */

LicenseManager.setLicenseKey(
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059273}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Unitec_Distribution_Systems}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{prox}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{prox}_need_to_be_licensed___{prox}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{3_August_2025}____[v3]_[0102]_MTc1NDE3NTYwMDAwMA==924731dbbd8c57db06615d6c1b04397a'
)

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
