// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Feature } from './feature';
import { ProductType } from './product-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProductTypeFeature extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK productType */
  productTypeId!: string;
  /** Guid key FK feature */
  featureId!: string;
  /** Int32 */
  displayOrder!: number;
  /** Binary */
  rowVersion!: any;
  /** FK featureId */
  feature!: Feature;
  /** FK productTypeId */
  productType!: ProductType;
}

