/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
    selector: 'prox-template-renderer',
    template: `
        <ng-container
            *ngTemplateOutlet="template; context: templateContext"
        ></ng-container>
    `,
})
export class TemplateRendererComponent implements ICellRendererAngularComp {
    template!: TemplateRef<any>;
    templateContext!: { $implicit: any; params: any };

    refresh(params: any): boolean {
        this.templateContext = {
            $implicit: params.data,
            params: params,
        };
        return true;
    }

    agInit(params: any): void {
        let isHidden = false;
        if (params.data?.hidden) {
          isHidden = params.data.hidden;
        }
        if (!isHidden) {
            this.template = params['ngTemplate'];
        }
        this.refresh(params);
    }
}
