/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'prox-badge-renderer',
  template: ` 
    <p-badge [value]="params.value" severity="success"></p-badge>
    `
})
export class BadgeRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  public value!: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setBadge(params);
    return true;
    
  }

  private setBadge(params: ICellRendererParams) {
    this.value = params.value;
  }
}