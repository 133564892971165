// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ReturnCreditType } from './return-credit-type';
import { Supplier } from './supplier';
import { ReturnCreditTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ReturnPolicy extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Boolean */
  allowRepair!: boolean;
  /** Boolean */
  allowReturn!: boolean;
  /** String */
  description!: string;
  /** Int32 */
  maxWaitForReturnDays?: number;
  /** String(60) */
  name!: string;
  /** String */
  policy!: string;
  /** Int32 */
  repairDays?: number;
  /** Enum FK returnCreditType */
  returnCreditTypeId!: ReturnCreditTypeEnum;
  /** Int32 */
  returnDays?: number;
  /** Binary */
  rowVersion!: any;
  /** Guid FK supplier */
  supplierId!: string;
  /** FK returnCreditTypeId */
  returnCreditType!: ReturnCreditType;
  /** FK supplierId */
  supplier!: Supplier;
}

