// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ActiveStatus } from './active-status';
import { Manufacturer } from './manufacturer';
import { PricedProductType } from './priced-product-type';
import { Product } from './product';
import { ProductTypeFeature } from './product-type-feature';
import { ProductTypeImage } from './product-type-image';
import { ProductTypeTagMap } from './product-type-tag-map';
import { StockCondition } from './stock-condition';
import { Supplier } from './supplier';
import { ActiveStatusEnum } from './enums';
import { StockConditionEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProductType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getStatusParents() {
    return [this.manufacturer];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String(200) */
  apiLink?: string;
  /** String */
  description!: string;
  /** Int32 */
  leadTimeDays!: number;
  /** Guid FK manufacturer */
  manufacturerId!: string;
  /** String(60) */
  name!: string;
  /** String */
  productKey?: string;
  /** Binary */
  rowVersion!: any;
  /** Enum FK stockCondition */
  stockConditionId!: StockConditionEnum;
  /** Guid FK supplier */
  supplierId!: string;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK manufacturerId */
  manufacturer!: Manufacturer;
  /** Inv FK productTypeId */
  pricedProductTypes!: PricedProductType[];
  /** Inv FK productTypeId */
  productTypeFeatures!: ProductTypeFeature[];
  /** Inv FK productTypeId */
  productTypeImages!: ProductTypeImage[];
  /** Inv FK productTypeId */
  productTypeTagMaps!: ProductTypeTagMap[];
  /** Inv FK productTypeId */
  products!: Product[];
  /** FK stockConditionId */
  stockCondition!: StockCondition;
  /** FK supplierId */
  supplier!: Supplier;
}

