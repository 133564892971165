import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMessage {
  from?: string;
  message: string;
}

/** Simple message bus using an observable */
@Injectable({providedIn: 'root'})
export class MessageBusService {
  private messageSubject = new BehaviorSubject<IMessage>({ message: '' });

  public notify(message: IMessage) {
    this.messageSubject.next(message);
  }

  public get messages() {
    return this.messageSubject.asObservable();
  }
}
