// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { PricedAddon } from './priced-addon';
import { ProductTypeConfig } from './product-type-config';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProductTypeConfigAddon extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK productTypeConfig */
  productTypeConfigId!: string;
  /** Guid key FK pricedAddon */
  pricedAddonId!: string;
  /** Boolean */
  isOptional!: boolean;
  /** Binary */
  rowVersion!: any;
  /** FK pricedAddonId */
  pricedAddon!: PricedAddon;
  /** FK productTypeConfigId */
  productTypeConfig!: ProductTypeConfig;
}

