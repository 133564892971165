// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Supplier } from './supplier';
import { SupplierCategory } from './supplier-category';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class SupplierCategoryMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK supplier */
  supplierId!: string;
  /** Guid key FK supplierCategory */
  supplierCategoryId!: string;
  /** FK supplierId */
  supplier!: Supplier;
  /** FK supplierCategoryId */
  supplierCategory!: SupplierCategory;
}

