// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProductTypeConfig } from './product-type-config';
import { ProgramRapidTemplate } from './program-rapid-template';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramRapidTemplateProductTypeConfig extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key FK programRapidTemplate */
  programRapidTemplateId!: string;
  /** Guid key FK productTypeConfig */
  productTypeConfigId!: string;
  /** Int32 */
  qty!: number;
  /** FK productTypeConfigId */
  productTypeConfig!: ProductTypeConfig;
  /** FK programRapidTemplateId */
  programRapidTemplate!: ProgramRapidTemplate;
}

