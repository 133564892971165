import { CommonModule, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { DEFAULT_CURRENCY_CODE, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MyInterceptor, TitleService } from "@services";
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
import { LayoutModule } from "./layout/layout.module";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    LayoutModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    NgIdleKeepaliveModule.forRoot(),
    //RouterModule.forRoot(appRoutes, { initialNavigation: "enabledBlocking" }),
    HttpClientModule,
        ToastrModule.forRoot({
            progressBar: true,
            progressAnimation: 'increasing',
            enableHtml: true,
        }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    //AppBreadcrumbService,
    TitleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
