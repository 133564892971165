import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'prox-save-cancel-delete',
  template: `
    <div class="flex mt-3 ml-3">
      <div *ngIf="!isReadOnly" class="mr-2">
        <button
          (click)="onSave()"
          [disabled]="!uow.hasChanges()"
          pButton
          type="button"
          label="Save"
          icon="pi pi-check"
          class="p-button-raised"
        ></button>
      </div>

      <div class="pr-2" *ngIf="!isHideUndo() && !isReadOnly">
        <button
          (click)="onUndo()"
          [disabled]="!uow.hasChanges()"
          pButton
          type="button"
          icon="pi pi-undo"
          label="Undo"
          class="p-button-raised"
        ></button>
      </div>

      <div class="pr-2" *ngIf="hasCancel">
        <button
          (click)="onCancel()"
          pButton
          type="button"
          icon="{{ setCancelIcon() }}"
          label="{{ setCancelLabel() }}"
          class="p-button-raised"
        ></button>
      </div>

      <div class="flex-grow-1"></div>
      <div *ngIf="this.canDelete" class="pr-2">
        <button
          pButton
          pRipple
          [disabled]="uow.hasChanges()"
          class="p-button-raised p-button-danger"
          label={{deleteLabel}}
          (click)="onDelete()"
        ></button>
      </div>
      <div *ngIf="uow.canShowLog()">
        <p-button
          icon="pi pi-eye"
          styleClass="p-button-rounded p-button-text"
          title="Change Log"
          (onClick)="onShowLog()"
        ></p-button>
      </div>

      <p-confirmDialog [closable]="false" [baseZIndex]="1000"></p-confirmDialog>
    </div>
    <!-- <div class="mt-2">
        dirty: {{ form.dirty }}
        disabled: {{ form.disabled }}
        touched: {{ form.touched }}
        valid: {{ form.valid }}
        errors: {{ form.errors | json }}
    </div> -->
  `,
})
export class ProxSaveCancelDeleteComponent implements OnInit {
  @Input() uow!: {
    hasChanges: () => boolean;
    canShowLog: () => boolean;
    showChangeLog: () => Promise<boolean>;
  };
  @Input() hideUndo: boolean = false;
  @Input() canDelete!: boolean;
  @Input() deleteLabel = "Delete";
  @Input() isReadOnly: boolean = false;
  @Output() cancel = new EventEmitter<string>();
  @Output() save = new EventEmitter<string>();
  @Output() undo = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();
  // @Output() showLog = new EventEmitter<string>();
  hasCancel = false;

  ngOnInit(): void {
    this.hasCancel = this.cancel.observed;
  }

  onCancel() {
    this.cancel.emit('cancel');
  }
  onSave() {
    this.save.emit('save');
  }
  onUndo() {
    this.undo.emit('undo');
  }
  onShowLog() {
    this.uow.showChangeLog();
  }

  isHideUndo() {
    return this.hideUndo;
  }

  setCancelIcon() {
    if (!this.uow.hasChanges()) {
      return 'pi pi-backward';
    }
    return 'pi pi-times';
  }

  setCancelLabel(): string {
    if (!this.uow.hasChanges()) {
      return ' Go Back';
    }
    return ' Cancel and Go Back';
  }

  onDelete() {
    this.delete.emit('delete');
  }
}
