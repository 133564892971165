import { Component, Input, OnInit } from '@angular/core';
import { UnitOfWork } from '@data';
import { ProximityAreaEnum, ProximityRight, ProximityUser, ProximityUserRight } from '@models';
import { EntityState } from 'breeze-client';
import * as _ from 'lodash';

interface RightVm {
  right: ProximityRight
  hasRight: boolean,
  entity?: ProximityUserRight
}

/** Interface implemented by AccountDbQueryService and SupplierDbQueryService */
interface RightQueryService {
  getProximityRights: () => Promise<ProximityRight[]>;
  getProximityUserRights: (userId: string) => Promise<ProximityUserRight[]>;
  uow: UnitOfWork;
}

@Component({
  selector: 'prox-user-rights-frm',
  templateUrl: './user-rights-frm.component.html',
})
export class UserRightsFrmComponent implements OnInit {
  @Input() proximityUser!: ProximityUser;
  @Input() areas!: ProximityAreaEnum[];
  @Input() dbQueryService!: RightQueryService;
  //@Input() canEdit!: boolean;
  @Input() isReadOnly!: boolean;

  isPageReady = false;
  areaNames: string[] = [];
  areaRights: { [key: string]: RightVm[] } = {};

  async ngOnInit(): Promise<void> {
    const [rights, userRights] = await Promise.all([
      this.dbQueryService.getProximityRights(),
      this.dbQueryService.getProximityUserRights(this.proximityUser.id)
    ]);

    this.areas.forEach(a => {
      const ar = rights.filter(r => r.areaId == a).map(r => {
        const entity = userRights.find(u => u.proximityRightId === r.id);
        return {
          right: r,
          hasRight: !!entity,
          entity
        }
      });
      if (ar.length) {
        const areaName = ProximityAreaEnum[a];
        this.areaNames.push(areaName);
        this.areaRights[areaName] = ar;
      }
    });

    this.isPageReady = true;

  }

  formatName(name: string) {
    return _.startCase(name);
  }

  isDisabled() {
    return this.isReadOnly;
  }

  toggleRight(vm: RightVm) {
    if (vm.hasRight) {
      if (vm.entity && vm.entity.entityAspect.entityState.isDeleted()) {
        // user previously had right; was removed and re-added
        vm.entity.entityAspect.setEntityState(EntityState.Unchanged);
      } else {
        vm.entity = this.dbQueryService.uow.createEntity(ProximityUserRight, { proximityRightId: vm.right.id, proximityUserId: this.proximityUser.id });
      }
    } else {
      if (vm.entity) {
        vm.entity.entityAspect.setDeleted();
      }
    }
  }
}
