import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UtilFns } from '@utils';

@Component({
  selector: 'prox-login',
  templateUrl: './login.component.html',
  styles: [`
        i {
            opacity: 0.6;
            transition-duration: .12s;
            color: #2196F3;
        }
        i:hover { opacity: 1; }
    `]
})
export class LoginComponent {
  error = '';
  success = '';
  working = false;
  username = '';
  password = '';

  constructor(private authService: AuthService) { }

  onSubmit() {
    this.error = '';
    if (!this.working) {
      this.working = true;
      this.authService.login(this.username, this.password, false).then(() => {
        this.error = '';
        // const user = this._authService.getUser();
        this.success = 'Logged in';
        this.working = false;

      }, error => {
        if (error.status === 0) {
          this.error = 'Unable to contact server';
        } else if (error.status === 401) {
          this.error = error.error || 'Invalid login name or password';
        } else {
          //   this._logger.log(error, 'error');
          this.error = UtilFns.getErrorMessage(error);
        }

        this.working = false;

      });

    }
  }
}
