// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { ProximityUser } from './proximity-user';
import { LogTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ChangeLog extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Int32 key */
  id!: number;
  /** String */
  data?: string;
  /** Guid */
  entityId?: string;
  /** Guid */
  entityId2?: string;
  /** String(50) */
  entityName!: string;
  /** Enum */
  logType!: LogTypeEnum;
  /** DateTimeOffset */
  ts!: Date;
  /** Guid FK user */
  userId!: string;
  /** FK userId */
  user!: ProximityUser;
}

