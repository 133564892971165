<a style="cursor: pointer" class="layout-config-button" (click)="onConfigButtonClick()">
    <i class="pi pi-cog"></i>
</a>

<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="w-full sm:w-18rem">
    <div class="px-2">
        <h5>Color Scheme</h5>
        <div class="flex">
            <div class="field-radiobutton flex-auto">
                <p-radioButton name="colorScheme" value="light" [(ngModel)]="colorScheme" inputId="mode-light"></p-radioButton>
                <label for="mode-light">Light</label>
            </div>
            <div class="field-radiobutton flex-auto">
                <p-radioButton name="colorScheme" value="dim" [(ngModel)]="colorScheme" inputId="mode-dim"></p-radioButton>
                <label for="mode-dim">Dim</label>
            </div>
            <div class="field-radiobutton flex-auto">
                <p-radioButton name="colorScheme" value="dark" [(ngModel)]="colorScheme" inputId="mode-dark"></p-radioButton>
                <label for="mode-dark">Dark</label>
            </div>
        </div>
    
        <hr />
        
        <ng-container *ngIf="!minimal">
            <h5>Menu Type</h5>
            <div class="flex flex-wrap">
                <div class="field-radiobutton w-6">
                    <p-radioButton name="menuMode" value="static" [(ngModel)]="menuMode" inputId="mode1"></p-radioButton>
                    <label for="mode1">Static</label>
                </div>
                <div class="field-radiobutton w-6">
                    <p-radioButton name="menuMode" value="overlay" [(ngModel)]="menuMode" inputId="mode2"></p-radioButton>
                    <label for="mode2">Overlay</label>
                </div>
                <div class="field-radiobutton w-6">
                    <p-radioButton name="menuMode" value="slim" [(ngModel)]="menuMode" inputId="mode3"></p-radioButton>
                    <label for="mode3">Slim</label>
                </div>
                <div class="field-radiobutton w-6">
                    <p-radioButton name="menuMode" value="compact" [(ngModel)]="menuMode" inputId="mode4"></p-radioButton>
                    <label for="mode3">Compact</label>
                </div>
                <div class="field-radiobutton w-6">
                    <p-radioButton name="menuMode" value="horizontal" [(ngModel)]="menuMode" inputId="mode5"></p-radioButton>
                    <label for="mode4">Horizontal</label>
                </div>
            </div>
            <hr />
        </ng-container>
    
        <ng-container *ngIf="!minimal">
            <h5>Menu Theme</h5>
            <div class="flex flex-wrap gap-3" *ngIf="layoutService.config.colorScheme === 'light'">
                <a *ngFor="let theme of menuThemes" role="button" type="button" pRipple  (click)="changeMenuTheme(theme)"  [ngStyle]="{'background-color': theme.color}" class="w-2rem h-2rem cursor-pointer hover:shadow-4 border-round transition-duration-150"></a>
            </div>
            <div *ngIf="layoutService.config.colorScheme !== 'light'">
                <p>Menu themes are available in light mode and static, slim, overlay menu modes by design as large surfaces can emit too much brightness in dark mode.</p>
            </div>
            <hr />
        </ng-container>
    
        
        <h5>Component Themes</h5>
        <div class="flex flex-wrap gap-3">
            <a *ngFor="let theme of componentThemes" role="button" type="button" pRipple (click)="changeComponentTheme(theme.name)" [ngStyle]="{'background-color': theme.color}" class="w-2rem h-2rem cursor-pointer hover:shadow-4 border-round transition-duration-150"></a>
        </div>
    
        <hr />
    
        <h5>Scale</h5>
        <div class="flex align-items-center">
            <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem mr-2" [disabled]="scale === scales[0]"></button>
            <div class="flex gap-3 align-items-center">
                <i class="pi pi-circle-fill text-300" *ngFor="let s of scales" [ngClass]="{'text-primary-500': s === scale}"></i>
            </div>
            <button icon="pi pi-plus"  type="button" pButton (click)="incrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem ml-2" [disabled]="scale === scales[scales.length - 1]"></button>
        </div>
    
        <hr />
    
        <ng-container *ngIf="!minimal">
            <h5>Input Style</h5>
            <div class="flex">
                <div class="field-radiobutton flex-1">
                    <p-radioButton name="inputStyle" value="outlined" [(ngModel)]="inputStyle" inputId="outlined_input"></p-radioButton>
                    <label for="outlined_input">Outlined</label>
                </div>
                <div class="field-radiobutton flex-1">
                    <p-radioButton name="inputStyle" value="filled" [(ngModel)]="inputStyle" inputId="filled_input"></p-radioButton>
                    <label for="filled_input">Filled</label>
                </div>
            </div>
        <hr />
        </ng-container>
        
        <h5>Ripple Effect</h5>
        <p-inputSwitch [(ngModel)]="ripple"></p-inputSwitch>
    </div>

</p-sidebar>