// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { MessageContext } from './message-context';
import { MessageType } from './message-type';
import { MessageContextEnum } from './enums';
import { MessageTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class MessageTemplate extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid */
  actionItemId!: string;
  /** String */
  description!: string;
  /** String */
  html!: string;
  /** Enum FK messageContext */
  messageContextId!: MessageContextEnum;
  /** Enum FK messageType */
  messageTypeId!: MessageTypeEnum;
  /** String(60) */
  name!: string;
  /** Binary */
  rowVersion!: any;
  /** FK messageContextId */
  messageContext!: MessageContext;
  /** FK messageTypeId */
  messageType!: MessageType;
}

