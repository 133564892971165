import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';



@Component({
  selector: 'prox-date-cell-editor-png',
  template: `
    <p-calendar 
      #container
      [(ngModel)]="value"
      [appendTo]="'body'"
      [monthNavigator]="true"
      yearRange="2020:2099"
      dateFormat="mm/dd/yy"
      (onSelect)="onSelect($event)"
      [panelStyleClass]="'ag-custom-component-popup'"
      
    ></p-calendar>
  `,
  styles: [``],
})
export class DateCellEditorPngComponent
  // implements AgEditorComponent, AfterViewInit {
  implements ICellEditorAngularComp, AfterViewInit {
  params: any;
  value!: Date;

  @ViewChild('container', { static: true }) public container;

  agInit(params: any): void {
    this.params = params;
    this.value = params.value || new Date();
    
  }

  // open the calendar when grid enters edit mode, i.e. the datepicker is rendered
  ngAfterViewInit() {
    // this.container.toggle();
    // Dropdown doesn't appear without the setTimeout.
    setTimeout( () => {
      this.container.toggle();
    }, 0);
  }

    // Gets called once after initialised. If you return true, the editor will
  // appear in a popup, so is not constrained to the boundaries of the cell.
  // This is great if you want to, for example, provide you own custom dropdown list
  // for selection. Default is false (ie if you don't provide the method).
  isPopup?(): boolean {
    return true;
  }

  // ensures that once a date is selected, the grid will exit edit mode and take the new date
  // otherwise, to exit edit mode after a selecting a date, click on another cell or press enter
  onSelect(event) {
    this.value = event;
    this.params.api.stopEditing(false);
  }

  getValue() {
    return this.value;
  }
}