// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { ActiveStatus } from './active-status';
import { DocSubmitType } from './doc-submit-type';
import { ReturnPolicy } from './return-policy';
import { Supplier } from './supplier';
import { SupplierSubmissionStatus } from './supplier-submission-status';
import { ActiveStatusEnum } from './enums';
import { DocSubmitTypeEnum } from './enums';
import { SupplierSubmissionStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class SupplierAccount extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get estimatedFreightChargePct() {
    return this.estimatedFreightChargeRate * 100;
  }
  set estimatedFreightChargePct(x: number) {
    this.estimatedFreightChargeRate = x / 100;
  }

  getStatusParents() {
    return [this.account, this.supplier];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** Boolean */
  allowPartialShipments!: boolean;
  /** Boolean */
  allowReissuanceOrders!: boolean;
  /** Guid FK defaultReturnPolicy */
  defaultReturnPolicyId?: string;
  /** Boolean */
  doesAccountPayFreight!: boolean;
  /** Int32 */
  dueDateNumDays?: number;
  /** Decimal */
  estimatedFreightChargeRate!: number;
  /** Enum FK invoiceDocSubmitType */
  invoiceDocSubmitTypeId!: DocSubmitTypeEnum;
  /** String */
  invoiceEmail?: string;
  /** Int32 */
  leadTimeToShipNumDays?: number;
  /** Binary */
  rowVersion!: any;
  /** Guid FK supplier */
  supplierId!: string;
  /** Enum FK supplierSubmissionStatus */
  supplierSubmissionStatusId!: SupplierSubmissionStatusEnum;
  /** FK accountId */
  account!: Account;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK defaultReturnPolicyId */
  defaultReturnPolicy!: ReturnPolicy;
  /** FK invoiceDocSubmitTypeId */
  invoiceDocSubmitType!: DocSubmitType;
  /** FK supplierId */
  supplier!: Supplier;
  /** FK supplierSubmissionStatusId */
  supplierSubmissionStatus!: SupplierSubmissionStatus;
}

