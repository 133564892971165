import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { DataProperty, Entity, EntityAspect, NavigationProperty, ValidationError } from 'breeze-client';

/**
 * BreezeValidator - Uses breeze entity validation to validate an Angular FormControl
 * @example
 * <prox-field label="Name *">
 *   <input type="text" [bzModel]="productType" name="name" [(ngModel)]="productType.name">
 * </prox-field>
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[bzModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: BreezeValidatorDirective, multi: true }
  ]
})
export class BreezeValidatorDirective implements Validator {
  /** The breeze entity.  Required. */
  @Input() bzModel!: Entity;
  /** The breeze property, if not the same as the name. */
  @Input() bzProperty?: string;
  /** The name of the form element. Used if bzProperty is not specified. */
  @Input() name!: string;
  /** Placeholder element of the form; used for displayName if specified. */
  @Input() placeholder?: string;

  /** Return true if the underlying Breeze property is required */
  get isRequired(): boolean {
    const property = this.property;
    if (!property) { return false; }
    if (property.isDataProperty) {
      return !(property as DataProperty).isNullable;
    } else if (property.isNavigationProperty) {
      return !(property as NavigationProperty).relatedDataProperties[0].isNullable;
    }
    return false;
  }

  /** Return the EntityProperty to which this control is bound */
  get property() {
    this.assertNonNull(this.name,'The "name" property of every control must be set');

    const aspect = this.bzModel?.entityAspect as EntityAspect;
    if (!aspect || !aspect.entityGroup) { return null; }
    return aspect.entityGroup.entityType.getProperty(this.bzProperty ?? this.name);
  }

  /** @returns true if the underlying breeze property has validation errors */
  get hasValidationErrors(): boolean {
    return !!this.getValidationErrors().length;
  }

  /** @returns current validation errors for the underlying breeze property */
  getValidationErrors(): ValidationError[] {
    
    const aspect = this.bzModel?.entityAspect as EntityAspect;
    if (!aspect || !aspect.entityGroup) { return []; }
    const propName = this.bzProperty ?? this.name;
    const prop = aspect.entityGroup.entityType.getProperty(propName);
    this.assertNonNull(prop, `The following property: '${propName}' does not exist on type: '${ this.bzModel.entityType.shortName}'. `)
    const errors = aspect.getValidationErrors().filter(e => e.propertyName === propName);
    return errors;
  }

  /** @returns null if no errors; otherwise, an object with { [key]: message } containing the messages */
  validate(c: AbstractControl): ValidationErrors | null {
    const property = this.property;
    if (!property) { return null; }

    // we need to call the property validator manually, 
    // because the entity is not updated until after the validator runs
    const displayName = this.placeholder || property.displayName || this.name;
    const errors = property.validators.map(v => v.validate(c.value, { displayName: displayName })).filter(r => !!r);
    const propname = this.bzProperty ?? this.name;
    this.assertNonNull(propname,'bzProperty & name');
    // convert errors into { key1: message1, key2: message2 }
    if (errors && errors.length) {
      const eobj = errors.reduce((p, v) => { p[propname +'_' + v.key] = v.errorMessage; return p }, {});
      return eobj;
    } else {
      return null;
    }
  }

  assertNonNull<TValue>(value: TValue, name: string) : asserts value is NonNullable<TValue> {
    if (value === null || value === undefined) {
      throw Error(`'${name}' cannot be null`);
    }
  }
}
