<div class="h-full w-full flex flex-column layout-wrapper p-input-filled" id="topLevel">
  <div style="position: absolute; right: 40px;">
    <button *ngIf="hasHelp" pButton (click)="showHelp()" class="p-button-rounded p-button-text" icon="pi pi-question-circle"></button>
  </div>
  <div *ngIf="parent?.isPageReady" class="flex flex-column flex-grow-1">
    
    <fieldset class="flex flex-column flex-grow-1" style="border-width: 0px;" [disabled] = "isDisabled">
      <ng-container *ngTemplateOutlet="bodyTemplate ? bodyTemplate : defaultBody"></ng-container>
    </fieldset>
    
    <ng-container *ngTemplateOutlet="footerTemplate ? footerTemplate : defaultFooter"></ng-container>
    
  </div>
</div>

<ng-template #defaultBody>
  
</ng-template>

<ng-template #defaultFooter>
  <div *ngIf="showBack || !parent?.hasMethod('hasChanges')" class="mt-3 ml-3" >
    <button (click)="parent?.navigateBack()" pButton type="button" icon="pi pi-backward" label="Go Back"
      class="p-button-raised"></button>
  </div>  
</ng-template>
