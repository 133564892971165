// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountIssuanceUserGroupMap } from './account-issuance-user-group-map';
import { AccountIssuanceUserLog } from './account-issuance-user-log';
import { ProgramAccountIssuanceMap } from './program-account-issuance-map';
import { ProgramProductTypeTag } from './program-product-type-tag';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountIssuance extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    
    const propAnnotations = [
      new EntityPropertyAnnotation('maximumQty', {
        validators: [ BaseEntity.greaterThanZeroValidator(true) ]
      }),
      new EntityPropertyAnnotation('timeframeDays', {
        validators: [ BaseEntity.greaterThanZeroValidator(true) ]
      }),
    ];

    return new EntityTypeAnnotation({
      // validators: [  ],
      propertyAnnotations: propAnnotations
    });
    
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** Int32 */
  maximumQty!: number;
  /** String(60) */
  name!: string;
  /** Guid FK programProductTypeTag */
  programProductTypeTagId!: string;
  /** Binary */
  rowVersion!: any;
  /** Int32 */
  timeframeDays!: number;
  /** FK accountId */
  account!: Account;
  /** Inv FK accountIssuanceId */
  accountIssuanceUserGroupMaps!: AccountIssuanceUserGroupMap[];
  /** Inv FK accountIssuanceId */
  accountIssuanceUserLogs!: AccountIssuanceUserLog[];
  /** Inv FK accountIssuanceId */
  programAccountIssuanceMaps!: ProgramAccountIssuanceMap[];
  /** FK programProductTypeTagId */
  programProductTypeTag!: ProgramProductTypeTag;
}

