import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImpersonationService } from './impersonation.service';

@Component({
  selector: 'prox-error-dialog',
  templateUrl: './impersonate-dialog.component.html',
})
export class ImpersonateDialogComponent {

  public imps: ImpersonationService;

  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef) {
    this.imps = dialogConfig.data.impersonationService;
  }

  close() {
    this.ref.close();
  }

}
