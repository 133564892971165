import { ITooltipComp } from "@ag-grid-community/core";

export class AgInfoTooltip implements ITooltipComp {
    eGui!: HTMLElement;
    init(params) {
      const eGui = (this.eGui = document.createElement('div'));
      eGui.classList.add('validation-tooltip');

      // const data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
      const valueToDisplay = params.value;
      const colDef = params.column.colDef;
      const title = colDef.headerName;

      eGui.innerHTML = `
      <div class="ag-info-tooltip-title p-button">${ title } Info</div>
      <div class="ag-info-tooltip-body">${valueToDisplay}</div>
      `;

    }
  
    getGui() {
      return this.eGui;
    }
  }