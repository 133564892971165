// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Program } from './program';
import { SupplierManifestMap } from './supplier-manifest-map';
import { SupplierSubmissionStatus } from './supplier-submission-status';
import { SupplierSubmissionStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Manifest extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** String(100) */
  name!: string;
  /** Guid FK program */
  programId!: string;
  /** String(200) */
  rRule!: string;
  /** Binary */
  rowVersion!: any;
  /** Boolean */
  shouldIncludeInvoices!: boolean;
  /** Enum FK supplierSubmissionStatus */
  supplierSubmissionStatusId!: SupplierSubmissionStatusEnum;
  /** FK programId */
  program!: Program;
  /** Inv FK manifestId */
  supplierManifestMaps!: SupplierManifestMap[];
  /** FK supplierSubmissionStatusId */
  supplierSubmissionStatus!: SupplierSubmissionStatus;
}

