<div class="card-body" *ngIf="isPageReady">

  <form #form="ngForm">
    <ng-container *ngFor="let area of areaNames">
      <p-divider type="solid" align="left">
        <div>
          <b> {{area}} User Rights </b>
        </div>
      </p-divider>
      <div class="flex flex-wrap pb-3">
        <div class="w-4 pb-3" *ngFor="let vm of areaRights[area]">
          <p-checkbox [disabled]="isDisabled()" [(ngModel)]="vm.hasRight" [binary]="true" [name]="vm.right.name"
          [label]="formatName(vm.right.name)" [title]="vm.right.description || vm.right.name" (onChange)="toggleRight(vm)"></p-checkbox>
        </div>
      </div>
    </ng-container>
  </form>
</div>